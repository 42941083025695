interface ICurrency {
  code: string;
  label: string;
  symbol?: string;
}

export const admittedCurrencies = [
  "EUR",
  "USD",
  "CAD",
  "CHF",
  "PLN",
  "NOK",
  "SEK",
];

const currencies: ICurrency[] = [
  { code: "usd", label: "US dollar", symbol: "$" },
  { code: "eur", label: "Euro", symbol: "€" },
  { code: "cad", label: "Canadian dollar", symbol: "$" },
  { code: "chf", label: "Swiss franc", symbol: "Fr" },
  { code: "nok", label: "Norwegian krone", symbol: "kr" },
  { code: "sek", label: "Swedish krona", symbol: "kr" },
  { code: "pln", label: "Polish złoty", symbol: "zł" },
];

export default currencies;
