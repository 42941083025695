import { useAppStore } from "@store";
import { useCallback } from "react";

export const useUserSettings = () => {
  const { user, exchangeRates } = useAppStore();

  const getExchangeRate = useCallback(() => {
    if (!user?.settings?.currency) return undefined;

    const currency = user.settings.currency.toLowerCase();
    const foundRate = exchangeRates.find(
      (item) => item.code.toLowerCase() === currency
    );

    return foundRate || undefined;
  }, [exchangeRates, user?.settings?.currency]);

  return {
    exchangeRate: getExchangeRate(),
    currency: user?.settings?.currency,
    temperature: user?.settings?.temperature,
  };
};
