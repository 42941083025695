import routes from "@constants/routes";

export const getLink = (route: keyof typeof routes, locale?: string) => {
  const localeString = locale ? `/${locale}` : "";
  return `${localeString}${routes[route]}`;
};

export const getCityLink = (id: string) => {
  return `${routes.city}/${id}`;
};

export const getCountryLink = (code: string) => {
  return `${routes.country}/${code.toLowerCase()}`;
};

export const getUserLink = (id: string) => {
  return `${routes.user}/${id}`;
};

export const getTripLink = (id: string) => {
  return `${routes.trip}/${id}`;
};

export const getCityToursLink = (id: string) => {
  return `${routes.city}/${id}/tours`;
};

export const getCityWfpLink = (id: string) => {
  return `${routes.city}/${id}/wfp`;
};
