import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, IAdvancedStats } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";
import { Key } from "swr";

export const useAdvancedStats = (
  cityId?: string,
  config?: ApiSwrConfig<IAdvancedStats>,
  overrideRoute?: Key
) => {
  const route =
    overrideRoute ?? cityId
      ? getApiUrl(apiRoutes.cityIdAdvancedStats, cityId)
      : null;
  return useApiSWR<IAdvancedStats>(route, config);
};
