const languages: Record<string, string> = {
  en: "English",
  es: "Spanish",
  it: "Italian",
  nl: "Dutch",
  jp: "Japanese",
  no: "Norwegian",
};

export default languages;
