import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, ICity } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";
import { Key } from "swr";

export const useCities = (
  params?: Record<string, any>,
  config?: ApiSwrConfig<ICity[]>,
  overrideRoute?: Key
) => {
  const route = overrideRoute ?? getApiUrl(apiRoutes.city, null, params);
  return useApiSWR<ICity[]>(route, config);
};
