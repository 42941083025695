import { IconSparkles, type Icon as TablerIcon } from "@tabler/icons-react";
import routes from "./routes";

interface MenuItem {
  name: string;
  path: string;
  isAuth?: boolean;
  Icon?: TablerIcon;
}

const menu: MenuItem[] = [
  {
    name: "menu.explore",
    path: routes.explore,
  },
  {
    name: "menu.dashboard",
    path: routes.dashboard,
    isAuth: true,
  },
  {
    name: "menu.tripPlanner",
    path: routes.tripPlanner,
    Icon: IconSparkles,
  },
  {
    name: "menu.blog",
    path: routes.blog,
  },
];

export default menu;
