import { Tooltip } from "@components/ui";
import Image, { ImageProps } from "next/image";

type Props = {
  code?: string;
  label?: string;
  isLang?: boolean;
} & Partial<ImageProps>;

export const CountryIcon = ({
  code,
  label,
  isLang = false,
  ...rest
}: Props) => {
  if (!code) return null;

  const formattedCode = code.toLowerCase().trim();
  const imgFolder = isLang ? "languages" : "countries";

  return (
    <Tooltip tooltip={label} asChild>
      <Image
        src={`/images/${imgFolder}/${formattedCode}.svg`}
        height={4}
        width={4}
        alt="Country flag"
        className="w-4 h-4"
        {...rest}
      />
    </Tooltip>
  );
};
