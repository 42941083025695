import { useGeolocation, useMap } from "@hooks";
import { IconCurrentLocation } from "@tabler/icons-react";
import { IMap } from "@types";
import type { Map } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useTheme } from "next-themes";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import ReactMapGL, { Marker } from "react-map-gl";

const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_API_KEY;

export const Mapbox = ({
  lat,
  lon,
  children,
  zoom = 6,
  hasCurrentLocation = true,
  ...rest
}: IMap) => {
  const [_, setMap] = useMap();
  const geolocation = useGeolocation();
  const viewState = { latitude: lat, longitude: lon, zoom };
  const { theme } = useTheme();
  const mapStyle = useMemo(
    () =>
      theme === "light"
        ? "cll9q491100p801qs2ubc3711"
        : "cll9pzslk00p701qsg6flbraf",
    [theme]
  );

  return (
    <div className="w-full h-full">
      <ReactMapGL
        ref={(ref) => ref && setMap(ref.getMap())}
        initialViewState={viewState}
        mapboxAccessToken={mapboxToken}
        mapStyle={`mapbox://styles/beento/${mapStyle}`}
        maxZoom={20}
        minZoom={2}
        projection={{ name: "mercator" }}
        style={{
          height: "100%",
          width: "100%",
        }}
        {...rest}
      >
        {hasCurrentLocation && geolocation.lat && geolocation.lon && (
          <Marker latitude={geolocation.lat} longitude={geolocation.lon}>
            <IconCurrentLocation size={20} />
          </Marker>
        )}
        {children}
      </ReactMapGL>
    </div>
  );
};

export const MapContext = createContext<
  [Map, Dispatch<SetStateAction<Map>>] | undefined
>(undefined);

export const MapProvider = (props: any) => {
  const [map, setMap] = useState<Map>();
  return <MapContext.Provider value={[map, setMap]} {...props} />;
};
