import apiRoutes from "@constants/api-routes";
import { useMeUser, useUserFavs } from "@services";
import { useAppStore } from "@store";
import { IExchangeRate, ISocial } from "@types";
import { fetcher } from "@utils/api";
import { useEffect } from "react";
import useSWR, { SWRResponse } from "swr";
import { useAuth } from "./use-auth";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshInterval: 1000 * 60 * 10,
};

export const useInitialLoad = () => {
  const { isLogged } = useAuth();
  const store = useAppStore();
  const rates = useSWR<IExchangeRate[]>(
    isLogged && apiRoutes.exchangeRates,
    fetcher,
    options
  );
  const favorites = useUserFavs(options, isLogged ? undefined : false);
  const user = useMeUser(options, isLogged ? undefined : false);
  const social = useSWR<ISocial>(
    isLogged && apiRoutes.userSocial,
    fetcher,
    options
  );

  useEffect(() => {
    if (!favorites?.data) return;
    const favCities = favorites.data.cities?.map((item) => item._id);
    store.setFavoriteCities(favCities || []);
  }, [favorites.data]);

  useStoreEffect(social, store.setSocial);
  useStoreEffect(user, store.editUser);
  useStoreEffect(rates, store.setExchangeRates);
};

const useStoreEffect = (swrData: SWRResponse<any>, callback: Function) => {
  useEffect(() => {
    if (swrData?.data) callback(swrData.data);
  }, [swrData.data]);
};
