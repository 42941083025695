"use client";

import apiRoutes from "@constants/api-routes";
import { useAppStore } from "@store";
import { ITripPlan, tripPlanSchema } from "@types";
import { parseLanguageName } from "@utils/parse";
import { ObjectStreamFromResponse } from "modelfusion";
import { useTranslation } from "next-i18next";
import { useState } from "react";

export const useTripPlanner = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { i18n } = useTranslation();
  const {
    destinations,
    length,
    activities,
    transport,
    accommodation,
    budget,
    generatedTripPlan,
    setGeneratedTripPlan,
  } = useAppStore().tripPlanner;
  const [tripPlan, setTripPlan] = useState<ITripPlan | undefined>(
    generatedTripPlan
  );

  const generate = async () => {
    setTripPlan(undefined);
    setIsGenerating(true);

    try {
      const response = await fetch(apiRoutes.tripPlanner, {
        method: "POST",
        body: JSON.stringify({
          destinations,
          length,
          activities,
          transport,
          accommodation,
          budget,
          lang: parseLanguageName(i18n.language ?? "en"),
        }),
      });

      const stream = ObjectStreamFromResponse({
        schema: tripPlanSchema,
        response,
      });

      for await (const { partialObject } of stream) {
        setTripPlan(partialObject);
        setGeneratedTripPlan(partialObject);
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const clear = () => {
    setIsGenerating(false);
    setTripPlan(undefined);
  };

  return { tripPlan, generate, clear, isGenerating };
};
