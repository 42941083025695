"use client";

import { cn } from "@/lib/utils";
import { Button } from "@components/ui";
import { IconMoon, IconSun } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { useTheme } from "next-themes";

export const ThemeSwitcher = ({ isExtended = false }) => {
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();

  const onChange = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <Button
      aria-label="Toggle theme"
      variant="ghost"
      size="icon"
      onClick={onChange}
      className={cn(
        "button-header text-base items-center",
        isExtended ? "w-full" : "p-0 min-w-10"
      )}
    >
      <IconMoon className="hidden dark:flex" size={24} />
      <IconSun className="flex dark:hidden" size={24} />

      {isExtended && t("actions.toggleTheme", "Toggle theme")}
    </Button>
  );
};
