"use client";

import tailwindConfig from "@/../tailwind.config";
import type { ScreenSize } from "@types";
import { useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";

export const useScreenSize = (): ScreenSize => {
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenSize;
};

const getScreenSize = (): ScreenSize => {
  if (typeof window === "undefined") {
    return "xs"; // Default to "xs" or another appropriate value during SSR
  }

  const config = resolveConfig(tailwindConfig);
  const { screens } = config.theme;
  const width = window.innerWidth;
  if (width < parseInt(screens.sm)) return "xs";
  else if (width < parseInt(screens.md)) return "sm";
  else if (width < parseInt(screens.lg)) return "md";
  else if (width < parseInt(screens.xl)) return "lg";
  else if (width < parseInt(screens["2xl"])) return "xl";
  else return "2xl";
};
