import { IExchangeRate, IFilter } from "@types";
import { create } from "zustand";

interface State {
  exchangeRates: IExchangeRate[];
  filters: IFilter[];
  isFiltersOn: boolean;
  isFiltersMobileOn: boolean;
  sort: string;
  sortOrder: "asc" | "desc";
}

interface Actions {
  setExchangeRates: (rates: IExchangeRate[]) => void;
  setFilters: (filters: IFilter[]) => void;
  toggleFilters: () => void;
  setFiltersMobile: (value: boolean) => void;
  setSort: (sort: string) => void;
  setSortOrder: (sortOrder: "asc" | "desc") => void;
}

type Store = State & Actions;

const useAppSlice = create<Store>((set) => ({
  exchangeRates: [],
  filters: [],
  isFiltersOn: true,
  isFiltersMobileOn: false,
  sort: "",
  sortOrder: "asc",
  setExchangeRates: (exchangeRates) =>
    set((state) => ({ ...state, exchangeRates })),
  setFilters: (filters) => set((state) => ({ ...state, filters })),
  toggleFilters: () =>
    set((state) => ({ ...state, isFiltersOn: !state.isFiltersOn })),
  setFiltersMobile: (value: boolean) =>
    set((state) => ({ ...state, isFiltersMobileOn: value })),
  setSort: (sort) => set((state) => ({ ...state, sort })),
  setSortOrder: (sortOrder) => set((state) => ({ ...state, sortOrder })),
}));

export default useAppSlice;
