import useAppSlice from "./app";
import useFavsSlice from "./favs";
import useSocialSlice from "./social";
import useTripPlannerSlice from "./trip-planner";
import useUserStore from "./user";

export const useAppStore = () => ({
  ...useAppSlice(),
  ...useFavsSlice(),
  ...useSocialSlice(),
  ...useUserStore(),
  ...{ tripPlanner: useTripPlannerSlice() },
});
