"use client";

import { cn } from "@/lib/utils";
import { ISelectOption } from "@/types";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import { getLabelByValue } from "@utils/parse";
import { useTranslation } from "next-i18next";
import * as React from "react";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandItemProps,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

interface Props {
  id?: string;
  className?: string;
  options: ISelectOption[];
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  noResultMessage?: string;
  disableSearch?: boolean;
  disableSorting?: boolean;
  disableTranslate?: boolean;
}

const Combobox = React.forwardRef<HTMLButtonElement, Props>(
  ({ id, className, options, placeholder, value, ...props }: Props, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [_value, _setValue] = React.useState("");

    // Sort options on translated label
    const sortedOptions = React.useMemo(() => {
      return props.disableSorting
        ? options.map((item) => ({ ...item, label: t(item.label) }))
        : options.sort((a, b) => t(a.label).localeCompare(t(b.label)));
    }, [props.disableSorting, options, t]);

    React.useEffect(() => {
      _setValue(value ?? "");
    }, [value]);

    const handleSelectedValue = () => {
      const selected = getLabelByValue(options, _value);
      if (!selected) return "";

      return props.disableTranslate ? selected : t(selected);
    };

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            id={id}
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "justify-between border border-border w-full",
              className
            )}
          >
            {_value ? (
              handleSelectedValue()
            ) : (
              <span className="font-normal truncate text-muted-foreground">
                {placeholder ?? t("actions.selectOrSearch")}
              </span>
            )}
            <IconChevronDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-auto p-0 min-w-40">
          <Command className="max-h-[13.5rem]">
            {!props.disableSearch && <CommandInput placeholder="Search..." />}
            <CommandEmpty>{props.noResultMessage}</CommandEmpty>
            <CommandGroup className="overflow-y-scroll">
              {sortedOptions.map((item) => (
                <ComboboxItem
                  key={item.value}
                  value={item.label}
                  item={item}
                  checked={_value === item.value}
                  onSelect={(currentValue) => {
                    props.onChange(currentValue === _value ? "" : item.value);
                    setOpen(false);
                  }}
                >
                  {t(item.label)}
                </ComboboxItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
Combobox.displayName = "Combobox";

interface ComboboxItemProps extends CommandItemProps {
  item: ISelectOption;
  checked?: boolean;
}

const ComboboxItem = ({
  item,
  checked = false,
  children,
  ...rest
}: ComboboxItemProps) => (
  <CommandItem {...rest}>
    <IconCheck
      size={18}
      className={cn("mr-1", checked ? "opacity-100" : "opacity-0")}
    />
    <div className="flex gap-2">{children}</div>
  </CommandItem>
);
ComboboxItem.displayName = "ComboboxItem";

interface ComboboxSelectedItemProps {
  value?: string;
  placeholder: string;
  selectedItem?: ISelectOption;
}

const ComboboxSelectedItem = ({
  value,
  selectedItem,
  placeholder,
}: ComboboxSelectedItemProps) => {
  if (selectedItem)
    return (
      <div className="flex items-center gap-2">
        {selectedItem.icon}
        {selectedItem.label}
      </div>
    );

  if (value) return <p>{value}</p>;

  return (
    <span className="font-normal truncate text-muted-foreground">
      {placeholder}
    </span>
  );
};
ComboboxSelectedItem.displayName = "ComboboxSelectedItem";

export { Combobox, ComboboxItem, ComboboxSelectedItem };
