"use client";

import { PropsWithChildren, createContext, useContext, useState } from "react";
import { ModalManager } from "./modal-manager";

export type ModalId =
  | "alert"
  | "auth"
  | "currentCity"
  | "displayInfo"
  | "editAdvancedStats"
  | "editCity"
  | "editCountry"
  | "editSuggestion"
  | "editUser"
  | "feedback"
  | "ftu"
  | "image"
  | "prompt"
  | "scripts"
  | "wfp"
  | null;
export type ModalOptions =
  | {
      data?: any;
      mode?: string;
      title?: string;
      onCloseModal?: () => void;
      onSave?: (param?: string) => void | Promise<void>;
      onSubmit?: () => void | Promise<void>;
      nonDismissable?: boolean;
    }
  | undefined;

interface ModalContextType {
  currentModal: { id: ModalId; options?: ModalOptions };
  openModal: (modalId: ModalId, options?: ModalOptions) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export function useModal(): ModalContextType {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
}

export function ModalProvider({ children }: PropsWithChildren) {
  const [id, setId] = useState<ModalId>(null);
  const [options, setOptions] = useState<ModalOptions>();

  const closeModal = () => {
    setId(null);
    options?.onCloseModal && options.onCloseModal();
  };

  const openModal = (modalId: ModalId, options: ModalOptions) => {
    setId(modalId);
    setOptions(options);
  };

  return (
    <ModalContext.Provider
      value={{ currentModal: { id, options }, openModal, closeModal }}
    >
      {children}
      <ModalManager />
    </ModalContext.Provider>
  );
}
