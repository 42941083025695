"use client";

import { OptionalLink } from "@components/common";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  AvatarProps,
} from "@components/ui";
import { IUser } from "@types";
import { getUserLink } from "@utils/routes";

type Props = { data: IUser; isLinkDisabled?: boolean } & AvatarProps;

export const UserAvatar = ({ data, isLinkDisabled, ...props }: Props) => {
  const href = isLinkDisabled ? undefined : getUserLink(data?._id);

  return (
    <OptionalLink href={href}>
      <Avatar {...props}>
        {data.image && <AvatarImage src={data.image} />}
        <AvatarFallback>{getUserInitials(data)}</AvatarFallback>
      </Avatar>
    </OptionalLink>
  );
};

// Extract the first character of the firstName and lastName
const getUserInitials = ({ firstName, lastName }: IUser): string => {
  const firstInitial = (firstName && firstName[0].toUpperCase()) || "";
  const lastInitial = (lastName && lastName[0].toUpperCase()) || "";

  // Combine the initials and return them
  return `${firstInitial}${lastInitial}`;
};
