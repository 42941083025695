const routes = {
  admin: "/admin",
  adminCities: "/admin/city",
  adminCostsFeedback: "/admin/costs-feedback",
  adminCountries: "/admin/country",
  adminLogs: "/admin/logs",
  adminScripts: "/admin/scripts",
  adminScriptsAdvancedStats: "/admin/scripts/script-advanced-stats",
  adminScriptsFreestyle: "/admin/scripts/script-freestyle",
  adminScriptsNumbeoCities: "/admin/scripts/script-numbeo-cities",
  adminScriptsNumbeoCountries: "/admin/scripts/script-numbeo-countries",
  adminScriptsRestCountries: "/admin/scripts/script-rest-countries",
  adminScriptsWorldbank: "/admin/scripts/script-worldbank",
  adminSuggestion: "/admin/suggestion",
  adminUsers: "/admin/user",
  adminWfp: "/admin/wfp",
  blog: "/blog",
  city: "/city",
  explore: "/explore",
  compare: "/compare",
  country: "/country",
  home: "/explore",
  dashboard: "/dashboard",
  landing: "/",
  me: "/user/me",
  meInfo: "/user/me/information",
  meSettings: "/user/me/settings",
  meTrips: "/user/trips",
  trip: "/trip",
  tripPlanner: "/trip-planner",
  user: "/user",
  userBalance: "/user/balance",
};

export default routes;
