import { IAdvancedStats, ICity } from "@types";

// Gets the array of coordinates from a city
export const getCoordsFromCity = (data?: Partial<ICity>) => {
  if (!data) return {};
  const lon = data?.location?.coordinates[0] ?? undefined;
  const lat = data?.location?.coordinates[1] ?? undefined;

  return { lon, lat };
};

//
// Advanced stats
//

// Gets the sort order for a given key of ICityAdvancedStat
export const getAdvStatsSortOrder = (
  key: keyof IAdvancedStats
): "asc" | "desc" => {
  return ["foodPrices", "housingCost", "transportCost"].includes(key)
    ? "desc"
    : "asc";
};

export const getHighestAdvancedStats = (
  stats: IAdvancedStats,
  itemLimit = 3
) => {
  const allStats: { name: string; score: number }[] = [];

  // Single loop to collect all stats
  Object.entries(stats).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null && "score" in value) {
      allStats.push({ name: key, score: value.score });
    }
  });

  // Sort by score and get top highest
  const highest = allStats
    .sort((a, b) => b.score - a.score)
    .slice(0, itemLimit);

  return highest;
};

export const getNormalizedCityName = (name: string) => {
  // Replace all characters that are not basic Latin letters or numbers
  const normalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return normalized.trim();
};
