import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, IUser } from "@types";
import { useApiSWR } from "@utils/api";
import { Key } from "swr";

export const useUsers = (
  config?: ApiSwrConfig<IUser[]>,
  overrideRoute?: Key
) => {
  const route = overrideRoute ?? apiRoutes.user;
  return useApiSWR<IUser[]>(route, config);
};
