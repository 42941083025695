"use client";

import { cn } from "@/lib/utils";
import { IconCalendar } from "@tabler/icons-react";
import { format } from "date-fns";
import { useMemo } from "react";
import { Button } from "./button";
import { Calendar, CalendarProps } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

interface Props {
  value: Date | undefined;
  onChange: (...event: any[]) => void;
  className?: string;
  calendar?: Partial<CalendarProps>;
}

// TODO: Refactor
const DatePicker = ({ value, className, onChange, calendar }: Props) => {
  const ageLimits = useMemo(() => {
    const currentDate = new Date();
    const formatDate = (date: Date) => date.toISOString().split("T")[0];
    const maxDate = new Date(currentDate);
    const minDate = new Date(currentDate);
    maxDate.setFullYear(maxDate.getFullYear() - 16);
    minDate.setFullYear(minDate.getFullYear() - 100);

    return { max: formatDate(maxDate), min: formatDate(minDate) };
  }, []);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "w-full justify-start border border-border bg-secondary text-left font-normal",
            !value && "text-muted-foreground",
            className
          )}
        >
          <IconCalendar size={18} className="mr-2" />
          {value ? format(value, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          captionLayout="dropdown-buttons"
          fromDate={new Date(ageLimits.min)}
          toDate={new Date(ageLimits.max)}
          {...calendar}
          mode="single"
          initialFocus
          selected={value}
          onSelect={onChange}
        />
      </PopoverContent>
    </Popover>
  );
};

export { DatePicker };
