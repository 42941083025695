"use client";

import { IconArrowUp } from "@tabler/icons-react";
import { motion, useAnimationControls } from "framer-motion";
import { useCallback, useEffect } from "react";

export const ScrollToTopButton = () => {
  const controls = useAnimationControls();

  const handleScroll = useCallback(() => {
    const shouldShow = window.scrollY > window.innerHeight * 0.4;
    shouldShow ? controls.start("animate") : controls.start("initial");
  }, [controls]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const scrollToTop = useCallback(() => {
    if (typeof window === "undefined") return;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <motion.button
      aria-label="scroll to top"
      className="fixed flex items-center justify-center rounded-full shadow-md w-14 h-14 bottom-12 right-2 lg:right-12 bg-accent/50 hover:bg-secondary hover:outline hover:outline-primary"
      onClick={scrollToTop}
      animate={controls}
      initial="initial"
      variants={{
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.1 } },
      }}
    >
      <IconArrowUp />
    </motion.button>
  );
};
