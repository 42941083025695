import { motion } from "framer-motion";

interface Props {
  isMapView: boolean;
  mapView: React.ReactNode;
  gridView: React.ReactNode;
}

export const GridMapView = ({ isMapView, mapView, gridView }: Props) => (
  <div>
    <motion.div
      key={String(isMapView)}
      initial={{ opacity: 0, x: isMapView ? -200 : 200 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      {isMapView ? mapView : gridView}
    </motion.div>
  </div>
);
