import { ISocial, ISocialItem } from "@types";
import { create } from "zustand";

interface State {
  social: ISocial;
}

interface Actions {
  setSocial: (socialObj: ISocial) => void;
  addFollow: (follow: ISocialItem) => void;
  removeFollow: (followId: string) => void;
}

type Store = State & Actions;

const useSocialSlice = create<Store>((set) => ({
  social: {
    followers: [],
    followersCount: 0,
    following: [],
    followingCount: 0,
  },
  setSocial: (payload) => {
    return set((state) => ({ ...state, social: payload }));
  },
  addFollow: (payload) => {
    return set((state) => ({
      ...state,
      social: {
        ...state.social,
        following: [...state.social.following, payload],
        followingCount: ++state.social.followingCount,
      },
    }));
  },
  removeFollow: (payload) => {
    return set((state) => {
      const following = state.social.following.filter(
        ({ _id }) => _id !== payload
      );

      return {
        ...state,
        social: {
          ...state.social,
          following,
          followingCount: --state.social.followingCount,
        },
      };
    });
  },
}));

export default useSocialSlice;
