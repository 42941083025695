import { ICountry } from "@types";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { CountryIcon } from "./country-icon";

export const CountryInformation = ({ data }: { data: ICountry }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full gap-1">
      <CountryIcon code={data?.code} className="w-16 h-16 mb-4" />

      <p className="text-2xl font-semibold tracking-tight text-center">
        {data?.name}
      </p>

      <div className="flex items-center gap-2">
        <Image
          alt="continent icon"
          className="mb-2"
          src={`/images/continents/${data.continent.toLowerCase()}.webp`}
          height={24}
          width={24}
        />
        <p className="text-xl">
          {data?.continent && t(`continents.${data?.continent}`)}
        </p>
      </div>
      <p className="text-muted-foreground">{data?.subregion}</p>
      <p className="italic text-muted-foreground">{data?.officialName}</p>
    </div>
  );
};
