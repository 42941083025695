import { IconMapPin } from "@tabler/icons-react";
import { IMap } from "@types";
import { Marker } from "react-map-gl";
import { Mapbox } from "./mapbox";

interface Props extends IMap {
  hasMarker?: boolean;
}

export const SimpleMap = ({ lat, lon, hasMarker = true, ...rest }: Props) => (
  <Mapbox lat={lat} lon={lon} {...rest}>
    {hasMarker && lat && lon && (
      <Marker latitude={lat} longitude={lon}>
        <IconMapPin size={20} />
      </Marker>
    )}
  </Mapbox>
);
