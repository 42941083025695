import routes from "@constants/routes";
import { Magic } from "magic-sdk";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { toast } from "sonner";

const magic =
  typeof window !== "undefined" &&
  new Magic(process.env.NEXT_PUBLIC_MAGIC_PK || "a");

export const useMagicLink = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const callbackUrl =
    router.pathname === routes.landing ? routes.home : undefined;

  const onSubmit = async (email: string) => {
    if (!magic) throw new Error("Magic not defined");

    try {
      setIsLoading(true);
      const didToken = await magic.auth.loginWithMagicLink({ email });
      await signIn("credentials", { didToken, callbackUrl });
    } catch (error) {
      toast.error(t("auth.emailError"));
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, onSubmit };
};
