import {
  AsyncCombobox,
  Button,
  Checkbox,
  Combobox,
  ComboboxMulti,
  DatePicker,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Textarea,
} from "@components/ui";
import apiRoutes from "@constants/api-routes";
import { valuesLanguageOptions } from "@constants/form-values";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePostRequest } from "@hooks/use-post-request";
import { IconCoins, IconDeviceFloppy, IconX } from "@tabler/icons-react";
import { IUser } from "@types";
import { getApiUrl } from "@utils/api";
import {
  parseCityOptionAsync,
  parseCityOptionsAsync,
  parseCountryOptionsAsync,
  parseCountrySelectedOption,
  parseRelativeDate,
} from "@utils/parse";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useModal } from "./modal-provider";

const formSchema = z.object({
  email: z.string(),
  role: z.string().optional().default("user"),
  firstName: z.string(),
  lastName: z.string().optional(),
  birthday: z.date(),
  about: z.string().optional(),
  nationality: z.string(),
  bornIn: z.string().optional(),
  livingInId: z.string().optional(),
  languages: z.string().array().optional(),
  image: z.string().optional(),
  afterFtu: z.boolean(),
  balance: z.number().optional().default(0),
  lastActivity: z.string().optional(),
  createdAt: z.string().optional(),
});

export const EditUserModal = () => {
  const { t } = useTranslation();
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const data = options?.data as IUser;
  const update = usePostRequest<IUser>(getApiUrl(apiRoutes.userId, data?._id));
  const form = useForm<z.infer<typeof formSchema>>({
    mode: "all",
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...data,
      birthday: data?.birthday ? new Date(data.birthday) : undefined,
    },
  });

  const isNextBtnDisabled = useMemo(() => {
    return !form.getValues("firstName");
  }, [form]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const body = {
        ...values,
        birthday: values.birthday.toISOString(),
      } as IUser;
      const name = values?.firstName;
      await update.trigger(body, {
        successMessage: t("pages.admin.notifications.updated", { name }),
        errorMessage: t("pages.admin.notifications.notUpdated", { name }),
      });

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className="max-w-xl py-10 overflow-y-auto">
        <Form {...form}>
          <form className="grid gap-4" onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>{t("pages.admin.editUser")}</DialogTitle>
            </DialogHeader>
            <Tabs defaultValue="information">
              <TabsList className="grid w-full grid-cols-2 mb-4">
                <TabsTrigger value="information">Information</TabsTrigger>
                <TabsTrigger value="details">Details</TabsTrigger>
              </TabsList>

              <TabsContent value="information">
                <div className="flex flex-col gap-2">
                  <div className="grid grid-cols-2 gap-2">
                    {/* First name field */}
                    <FormField
                      control={form.control}
                      name="firstName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("pages.profile.firstName")}</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {/* Last name field */}
                    <FormField
                      control={form.control}
                      name="lastName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("pages.profile.lastName")}</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  {/* Birthday field */}
                  <FormField
                    control={form.control}
                    name="birthday"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel required>
                          {t("pages.profile.birthday")}
                        </FormLabel>
                        <FormControl>
                          <DatePicker {...field} />
                        </FormControl>
                        <FormDescription>
                          {t("pages.profile.birthdayHelp")}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="grid grid-cols-2 gap-2">
                    {/* Born in field */}
                    <FormField
                      control={form.control}
                      name="bornIn"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("pages.profile.bornIn")}</FormLabel>
                          <FormControl>
                            <AsyncCombobox
                              placeholder={t("pages.country.typeToSearch")}
                              loadOptions={parseCountryOptionsAsync}
                              selectedItem={parseCountrySelectedOption(
                                field.value
                              )}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                          <FormDescription>
                            {t("pages.profile.bornInHelp")}
                          </FormDescription>
                        </FormItem>
                      )}
                    />

                    {/* Nationality field */}
                    <FormField
                      control={form.control}
                      name="nationality"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t("pages.profile.nationality")}
                          </FormLabel>
                          <FormControl>
                            <AsyncCombobox
                              placeholder={t("pages.country.typeToSearch")}
                              loadOptions={parseCountryOptionsAsync}
                              selectedItem={parseCountrySelectedOption(
                                field.value
                              )}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  {/* Languages field */}
                  <FormField
                    control={form.control}
                    name="languages"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{t("pages.profile.languages")}</FormLabel>
                        <ComboboxMulti
                          options={valuesLanguageOptions}
                          {...field}
                        />
                      </FormItem>
                    )}
                  />

                  {/* About field */}
                  <FormField
                    control={form.control}
                    name="about"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{t("pages.profile.about")}</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                      </FormItem>
                    )}
                  />

                  {/* Living in field */}
                  <FormField
                    control={form.control}
                    name="livingInId"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{t("pages.profile.livingIn")}</FormLabel>
                        <FormControl>
                          <AsyncCombobox
                            placeholder={t("pages.country.typeToSearch")}
                            loadOptions={parseCityOptionsAsync}
                            selectedItemAsync={parseCityOptionAsync}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                        <FormDescription>
                          {t("pages.profile.livingInHelp")}
                        </FormDescription>
                      </FormItem>
                    )}
                  />

                  {/* Image field */}
                  <FormField
                    control={form.control}
                    name="image"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("pages.profile.image")}</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormDescription>
                          {t("pages.profile.imageHelp")}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* {(modified?.image ?? data?.image) && (
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-1">
                    <AccordionTrigger>
                      <span className="flex flex-1">Click to see image</span>
                    </AccordionTrigger>
                    <AccordionContent className="mb-4">
                      <Image
                        alt="User image"
                        src={modified?.image || data?.image!}
                        height={500}
                        width={500}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              )} */}
                </div>
              </TabsContent>

              <TabsContent value="details">
                <div className="flex flex-col gap-2">
                  <div className="flex w-full gap-2">
                    {/* Email field */}
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem className="w-3/5">
                          <FormLabel required>
                            {t("pages.profile.email")}
                          </FormLabel>
                          <FormControl>
                            <Input type="email" {...field} />
                          </FormControl>
                          <FormDescription>
                            {t("pages.profile.emailHelp")}
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {/* Role field */}
                    <FormField
                      control={form.control}
                      name="role"
                      render={({ field }) => (
                        <FormItem className="w-2/5">
                          <FormLabel>{t("pages.profile.role")}</FormLabel>
                          <Combobox
                            disableSearch
                            disableSorting
                            options={[
                              { label: "Admin", value: "admin" },
                              { label: "User", value: "user" },
                            ]}
                            {...field}
                          />
                        </FormItem>
                      )}
                    />
                  </div>

                  {/* Created at field */}
                  <FormField
                    control={form.control}
                    name="createdAt"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("pages.profile.createdAt")}</FormLabel>
                        <FormControl>
                          <Input type="datetime-local" {...field} />
                        </FormControl>
                        <FormDescription>
                          {parseRelativeDate(form.getValues("createdAt"))}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Last activity field */}
                  <FormField
                    control={form.control}
                    name="lastActivity"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("pages.profile.lastActivity")}</FormLabel>
                        <FormControl>
                          <Input type="datetime-local" {...field} />
                        </FormControl>
                        <FormDescription>
                          {parseRelativeDate(form.getValues("lastActivity"))}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Balance field */}
                  <FormField
                    control={form.control}
                    name="balance"
                    render={({ field }) => (
                      <FormItem className="w-1/4">
                        <FormLabel>{t("pages.profile.balance")}</FormLabel>
                        <FormControl>
                          <div className="relative flex">
                            <Input type="number" {...field} />
                            <IconCoins
                              size={18}
                              className="absolute flex h-full right-3"
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="flex my-2">
                    {/* After ftu field */}
                    <FormField
                      control={form.control}
                      name="afterFtu"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <FormLabel>{t("ftu.checkbox")}</FormLabel>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </TabsContent>
            </Tabs>
            <DialogFooter>
              <div className="flex justify-end gap-2">
                <Button onClick={closeModal} size="sm" variant="outline">
                  <IconX size={20} />
                  {t("actions.cancel")}
                </Button>
                <Button
                  type="submit"
                  isLoading={update.isLoading}
                  size="sm"
                  disabled={isNextBtnDisabled}
                >
                  <IconDeviceFloppy size={20} />
                  {t("actions.saveChanges")}
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
