import { cn } from "@/lib/utils";
import Image from "next/image";
import Link from "next/link";

interface Props {
  link?: string;
  size?: number;
  isExtended?: boolean;
  className?: string;
}

export const AppName = ({ link, size = 30, isExtended, className }: Props) => {
  const component = (
    <div
      className={cn(
        "flex items-center gap-4",
        isExtended ? "px-2" : "",
        className
      )}
    >
      <Image
        alt="home"
        className="hidden dark:flex"
        src="/images/logo_white.svg"
        height={size}
        width={size}
        style={{ minHeight: size, minWidth: size }}
      />
      <Image
        alt="home"
        className="flex dark:hidden"
        src="/images/logo_brand.svg"
        height={size}
        width={size}
        style={{ minHeight: size, minWidth: size }}
      />
      {isExtended && <span className="text-xl font-bold">FindCity</span>}
    </div>
  );

  return link ? <Link href={link}>{component}</Link> : component;
};
