import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, IUser } from "@types";
import { useApiSWR } from "@utils/api";
import { Key } from "swr";

export const useMeUser = (
  config?: ApiSwrConfig<IUser>,
  overrideRoute?: Key
) => {
  const route = overrideRoute ?? apiRoutes.authMe;
  return useApiSWR<IUser>(route, config);
};
