"use client";

import { useModal } from "@components/modals";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@components/ui";
import { UserAvatar } from "@components/user";
import lclStor from "@constants/local-storage";
import routes from "@constants/routes";
import { useAuth } from "@hooks";
import { useAppStore } from "@store";
import {
  IconCoins,
  IconLogout,
  IconPlaneDeparture,
  IconSettings,
  IconTools,
  IconUser,
} from "@tabler/icons-react";
import { IUser } from "@types";
import { values } from "lodash";
import { signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useCallback } from "react";

export const AuthSwitcher = () => {
  const { t } = useTranslation();
  const { user } = useAppStore();
  const { isAdmin, isLoading, isLogged } = useAuth();
  const { openModal } = useModal();

  const onSignOut = useCallback(() => {
    signOut();

    // Empty localStorage
    values(lclStor).forEach((item) => window.localStorage.removeItem(item));
  }, []);

  return isLogged ? (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="button-header">
          {user?.firstName && <p className="font-semibold">{user.firstName}</p>}
          {user && <UserAvatar data={user as IUser} className="w-8 h-8" />}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[13rem]" side="bottom" align="end">
        <Link href={routes.userBalance}>
          <DropdownMenuItem className="flex items-center text-sm cursor-pointer focus:bg-transparent">
            <IconCoins className="text-muted-foreground" size={18} />
            <p className="text-muted-foreground">
              {t("pages.profile.balance")}
              {": "}
              <strong className="text-primary">
                {user?.balance ?? 0}
              </strong>{" "}
              tokens
            </p>
          </DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />
        {isAdmin && (
          <Link href={routes.admin}>
            <DropdownMenuItem>
              <IconTools size={20} />
              {t("pages.admin.title")}
            </DropdownMenuItem>
          </Link>
        )}
        <Link href={routes.meTrips}>
          <DropdownMenuItem>
            <IconPlaneDeparture size={20} />
            {t("pages.trips.myTrips")}
          </DropdownMenuItem>
        </Link>
        <Link href={routes.meInfo}>
          <DropdownMenuItem>
            <IconUser size={20} />
            {t("pages.profile.title")}
          </DropdownMenuItem>
        </Link>
        <Link href={routes.meSettings}>
          <DropdownMenuItem>
            <IconSettings size={20} />
            {t("pages.profile.settings")}
          </DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={onSignOut}>
          <IconLogout size={20} />
          {t("auth.logOut")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Button
      className="h-10 gap-2 px-6"
      aria-label={t("auth.signIn")}
      isLoading={isLoading}
      onClick={() => openModal("auth")}
    >
      {t("auth.signIn")}
    </Button>
  );
};
