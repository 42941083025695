import axios, { AxiosError } from "axios";
import { useState } from "react";
import { toast } from "sonner";

interface ToastOptions {
  errorMessage: string;
  successMessage: string;
}

interface IOptionHooks {
  onBeforeFinish?: () => Promise<any>;
}

export const usePostRequest = <T, R = T>(url: string) => {
  const [isLoading, setIsLoading] = useState(false);

  const trigger = async (
    body?: Partial<T>,
    toastOptions?: ToastOptions,
    overrideUrl?: string,
    options?: IOptionHooks
  ): Promise<R> => {
    try {
      setIsLoading(true);
      const res = await axios.post(overrideUrl || url, body);

      toastOptions && toast.success(toastOptions.successMessage);
      return res.data;
    } catch (error) {
      toastOptions && toast.error(toastOptions.errorMessage);
      throw error as AxiosError;
    } finally {
      options?.onBeforeFinish && (await options.onBeforeFinish());
      setIsLoading(false);
    }
  };

  return { trigger, isLoading };
};
