import { cn } from "@/lib/utils";
import { Button } from "@components/ui";
import menu from "@constants/menu";
import { useAuth } from "@hooks";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { usePathname } from "next/navigation";

export const Navbar = () => {
  const { t } = useTranslation();
  const { isLogged } = useAuth();
  const pathname = usePathname();

  return (
    <nav className="flex flex-col items-center gap-3 lg:flex-row">
      {menu.map((item, index) => {
        if (item.isAuth && !isLogged) return null;
        const isActive = pathname?.includes(item.path);

        return (
          <Link key={index} href={item.path}>
            <Button
              variant={isActive ? "link" : "ghost"}
              className={cn("button-header min-w-32", isActive && "active")}
            >
              {item.Icon && <item.Icon size={20} />}
              <p
                className={cn(
                  "truncate font-medium tracking-tight text-base leading-none",
                  isActive && "font-semibold"
                )}
              >
                {t(item.name)}
              </p>
            </Button>
          </Link>
        );
      })}
    </nav>
  );
};
