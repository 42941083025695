import { CountryIcon } from "@components/country";
import { LanguageSwitcher } from "@components/layout";
import { useModal } from "@components/modals";
import { Button } from "@components/ui";
import { useSubfooter } from "@hooks";
import {
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandTwitter,
  IconCoffee,
  IconHeart,
  IconLifebuoy,
  IconMail,
  type Icon as TablerIcon,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { Url } from "next/dist/shared/lib/router/router";
import Image from "next/image";
import Link from "next/link";

export const Footer = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { footerContent } = useSubfooter();

  return (
    <footer className="w-full bg-neutral-200 dark:bg-neutral-950/10">
      {/* Subfooter pSEO */}
      {footerContent && <div>{footerContent}</div>}

      <div className="container">
        <div className="relative flex flex-col items-center justify-center gap-2 py-10">
          <div className="absolute items-end self-end justify-end hidden h-full py-8 lg:flex">
            <LanguageSwitcher
              variant="default"
              isExtended
              size="sm"
              className="gap-3 text-sm hover:bg-inverted/90 dark:hover:bg-inverted/90 hover:text-inverted-foreground"
            />
          </div>

          <ProductHuntLink />

          {/* Social links */}
          <div className="flex gap-2 mb-2">
            <FooterButton
              href="https://x.com/findcityio"
              label="Twitter"
              Icon={IconBrandTwitter}
            />
            <FooterButton
              href="https://www.instagram.com/findcityio"
              label="Instagram"
              Icon={IconBrandInstagram}
            />
            <FooterButton
              href="https://www.tiktok.com/@findcity"
              label="TikTok"
              Icon={IconBrandTiktok}
            />
            <FooterButton
              href="https://www.linkedin.com/company/findcityio"
              label="LinkedIn"
              Icon={IconBrandLinkedin}
            />
          </div>

          <div className="flex gap-2">
            <Button
              className="gap-2"
              onClick={() => openModal("feedback")}
              variant="ghost"
            >
              <IconLifebuoy size={18} />
              {t("pages.feedback.title")}
            </Button>
            <FooterIconButton
              ariaLabel="Contact"
              href="mailto:socialfindcity@gmail.com"
              Icon={IconMail}
            />
          </div>

          <p className="inline-flex items-center mt-2 text-sm text-muted-foreground">
            Made with <IconHeart className="mx-1" size={15} /> and{" "}
            <IconCoffee className="mx-1" size={15} /> from{" "}
            <CountryIcon code="no" className="w-3 mx-1" label="Norway" />
            <CountryIcon code="es" className="w-3 mx-1" label="Spain" />
          </p>
          <p className="text-xs text-muted-foreground">
            by Hackio © {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
  );
};

interface FooterButtonProps {
  href: Url;
  Icon: TablerIcon;
  label: string;
}
const FooterButton = ({ href, Icon, label }: FooterButtonProps) => (
  <div>
    <Button variant="ghost" asChild>
      <Link className="hidden gap-2 md:flex" href={href} target="_blank">
        <Icon size={18} />
        {label}
      </Link>
    </Button>

    <div className="block md:hidden">
      <FooterIconButton href={href} Icon={Icon} ariaLabel={label} />
    </div>
  </div>
);

interface FooterIconButtonProps {
  href: Url;
  Icon: TablerIcon;
  ariaLabel: string;
}
const FooterIconButton = ({ href, Icon, ariaLabel }: FooterIconButtonProps) => (
  <Button aria-label={ariaLabel} size="icon" variant="ghost" asChild>
    <Link href={href} target="_blank">
      <Icon size={18} />
    </Link>
  </Button>
);

const ProductHuntLink = () => (
  <Link
    href="https://www.producthunt.com/products/findcity?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-findcity"
    target="_blank"
  >
    <Image
      alt="FindCity - Find&#0032;and&#0032;deeply&#0032;compare&#0032;your&#0032;perfect&#0032;destination | Product Hunt"
      className="dark:hidden"
      src={`https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=549074&theme=neutral&size=small`}
      height="32"
      width="86"
      priority
    />
    <Image
      alt="FindCity - Find&#0032;and&#0032;deeply&#0032;compare&#0032;your&#0032;perfect&#0032;destination | Product Hunt"
      className="hidden dark:flex"
      src={`https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=549074&theme=dark&size=small`}
      height="32"
      width="86"
      priority
    />
  </Link>
);
