import apiRoutes from "@constants/api-routes";
import { usePostRequest } from "@hooks";
import { IWorkFriendlyPlace } from "@types";
import { getApiUrl } from "@utils/api";
import { useTranslation } from "next-i18next";

export const useWfpEdit = () => {
  const { t } = useTranslation();
  const updateReq = usePostRequest<IWorkFriendlyPlace>("");

  const trigger = async (id: string, body: Partial<IWorkFriendlyPlace>) => {
    try {
      const url = getApiUrl(apiRoutes.wfpId, id);
      const toastOptions = {
        successMessage: t("pages.admin.notifications.itemUpdated"),
        errorMessage: t("pages.admin.notifications.itemNotUpdated"),
      };

      await updateReq.trigger(body, toastOptions, url);
    } catch (error) {
      console.error(error);
    }
  };

  return { trigger, isLoading: updateReq.isLoading };
};
