import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Combobox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
} from "@components/ui";
import { UserTag } from "@components/user";
import apiRoutes from "@constants/api-routes";
import { valuesSuggestionCategories } from "@constants/form-values";
import { usePostRequest } from "@hooks/use-post-request";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";
import { ISuggestionItem } from "@types";
import { getApiUrl } from "@utils/api";
import { isEmpty } from "lodash";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useModal } from "./modal-provider";

export const EditSuggestionModal = () => {
  const { t } = useTranslation();
  const [modified, setModified] = useState<Partial<ISuggestionItem>>({});
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const data = options?.data as ISuggestionItem;
  const update = usePostRequest(
    getApiUrl(apiRoutes.adminSuggestionsId, data?._id)
  );

  const onUpdate = async () => {
    try {
      if (!modified) return;
      await update.trigger(modified, {
        successMessage: t("pages.admin.notifications.itemUpdated"),
        errorMessage: t("pages.admin.notifications.itemNotUpdated"),
      });

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const onFieldChange = async (value: Partial<ISuggestionItem>) => {
    setModified((prev) => ({ ...prev, ...value }));
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("pages.admin.editSuggestion")}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          {/* Name field */}
          <div className="flex flex-col gap-2">
            <Label>{t("pages.suggestions.category")}</Label>
            <Combobox
              disableSearch
              disableSorting
              options={valuesSuggestionCategories}
              value={modified?.category ?? data?.category}
              onChange={(value) => {
                onFieldChange({ category: value as any });
              }}
            />
          </div>

          <div className="flex items-end gap-2">
            {/* User ID field */}
            <div className="flex flex-col w-full gap-1">
              <Label>{t("common.user")}</Label>
              <Input name="userId" disabled value={String(data?.userId)} />
            </div>

            {/* User tag */}
            {data?.user && <UserTag data={data.user} />}
          </div>

          {/* Data field */}
          <Accordion type="single" collapsible defaultValue="json">
            <AccordionItem value="json">
              <AccordionTrigger>
                <p>See data in JSON format</p>
              </AccordionTrigger>
              <AccordionContent>
                <div>
                  <pre className="text-xs">
                    {JSON.stringify(data?.data, null, 2)}
                  </pre>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
        <DialogFooter>
          <div className="flex justify-end gap-2">
            <Button onClick={closeModal} size="sm" variant="outline">
              <IconX size={20} />
              {t("actions.cancel")}
            </Button>
            <Button
              onClick={onUpdate}
              isLoading={update.isLoading}
              size="sm"
              disabled={isEmpty(modified)}
            >
              <IconDeviceFloppy size={20} />
              {t("actions.saveChanges")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
