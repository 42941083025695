import { zodSchema } from "modelfusion";
import { z } from "zod";
import { ISearchResult } from "./ui";
import { IUser } from "./user";

export const tripPlanSchema = zodSchema(
  z.object({
    itinerary: z
      .object({
        day: z.number(),
        location: z.string(),
        locationId: z.string(),
        countryCode: z.string().length(2),
        timeline: z.array(
          z.object({
            type: z.enum(["meal", "activity"]),
            name: z.string(),
            placeName: z.string(),
            description: z.string(),
            startTime: z.string().optional(),
            endTime: z.string().optional(),
            location: z.object({ lat: z.string(), lon: z.string() }).optional(),
            priceRange: z.string().optional(),
          })
        ),
        accommodation: z
          .object({
            name: z.string(),
            description: z.string(),
            type: z.enum([
              "hotel",
              "hostel",
              "apartment",
              "resort",
              "villa",
              "cabin",
            ]),
            priceRange: z.string().optional(),
          })
          .optional(),
      })
      .array(),
    generalInformation: z.object({
      priceRange: z.string(),
      tips: z.string(),
      considerations: z.string(),
      packingList: z.array(z.string()),
    }),
  })
);

export type TripPlanSchema = typeof tripPlanSchema._partialType;

export type ITripPlan = typeof tripPlanSchema._type & {
  userId?: string;
};

export type ITrip = ITripPlan & {
  _id: string;
  name: string;
  user?: IUser;
  destinations: ISearchResult[];
  budget: ITripPlannerBudgetType;
  accommodation: ITripPlannerAccommodationType[];
  activities: ITripPlannerActivityType[];
};

export type ITripItinerary = {
  day: number;
  location: string;
  locationId: string;
  countryCode: string;
  timeline: {
    type: "meal" | "activity";
    name: string;
    description: string;
    startTime?: string;
    endTime?: string;
    location?: { lat: string; lon: string };
    priceRange?: string;
  }[];
  accommodation?: {
    name: string;
    description: string;
    type: ITripPlannerAccommodationType;
    priceRange?: string;
  };
}[];

export type ITripPlannerActivityType =
  | "cultural"
  | "gastronomy"
  | "outdoor"
  | "nightlife"
  | "shopping"
  | "relaxation"
  | "adventure"
  | "history"
  | "art"
  | "science";

export type ITripPlannerTransportType =
  | "train"
  | "plane"
  | "car"
  | "bus"
  | "bike"
  | "boat"
  | "walk";

export type ITripPlannerAccommodationType =
  | "hotel"
  | "hostel"
  | "apartment"
  | "resort"
  | "villa"
  | "cabin"
  | "none";

export type ITripPlannerBudgetType = "economy" | "comfort" | "luxury";
