import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, IDashboardResponse } from "@types";
import { useApiSWR } from "@utils/api";

export const useDashboard = (
  config?: ApiSwrConfig<IDashboardResponse>,
  overrideRoute?: string
) => {
  const route = overrideRoute ?? apiRoutes.dashboard;
  return useApiSWR<IDashboardResponse>(route, config);
};
