const proItems = {
  cityStayLength: { cost: 2 },
  tripPlanner: {
    defaultCost: 4,
    smCost: 5,
    mdCost: 6,
    lgCost: 7,
  },
};

export default proItems;
