import { zodSchema } from "modelfusion";
import { z } from "zod";

export const cityAdviceSchema = zodSchema(
  z.object({
    score: z.number(),
    description: z.string(),
    concerns: z.string(),
  })
);

export type CityAdvice = typeof cityAdviceSchema._partialType;

export type AdvisorStayLength = "shortLength" | "mediumLength" | "longLength";

export interface ICityAdviceParams {
  stayLength: string;
  cityId: string;
}
