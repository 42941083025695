import filterOptions from "@constants/filter-options";
import { valuesFiltersSortBy } from "@constants/form-values";
import lclStor from "@constants/local-storage";
import { useAppStore } from "@store";
import {
  ICityFilter,
  ICityFilterCategory,
  IFilter,
  ISelectOption,
} from "@types";
import { filter, find, reject, some } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDidMount } from "rooks";
import { useLocalStorage } from "./use-local-storage";

export const useCityFilters = () => {
  const { getItem, setItem } = useLocalStorage();
  const storedFilters = useMemo(
    () => getItem(lclStor.smartFilters) as IFilter[],
    [getItem]
  );
  const { filters, setFilters, sort, sortOrder, setSort, setSortOrder } =
    useAppStore();

  // Load stored filters from local storage
  useDidMount(() => {
    if (!storedFilters.length) return;
    setFilters(storedFilters);
  });

  // Persist filters on local storage
  useEffect(() => {
    if (filters) setItem(lclStor.smartFilters, filters);
  }, [filters, setItem]);

  const getIsSelected = useCallback(
    (filter: IFilter) => some(filters, filter),
    [filters]
  );

  const onFindCityFilter = useCallback(
    ({ key, value }: IFilter): ICityFilter => {
      const category = find(filterOptions, { key }) as ICityFilterCategory;
      return find(category.values, { value }) as ICityFilter;
    },
    []
  );

  const onFindSortByFilter = useCallback((value: string): ISelectOption => {
    return find(valuesFiltersSortBy, { value }) as ISelectOption;
  }, []);

  const onRemove = useCallback(
    (filter: IFilter) => {
      const newFilters = reject(filters, filter);
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  const onReset = () => {
    setFilters([]);
    setSort("");
    setSortOrder("asc");
  };

  const onToggle = useCallback(
    ({ key, value }: IFilter, isExclusive = false) => {
      const isSelected = getIsSelected({ key, value });

      if (isSelected) {
        onRemove({ key, value });
      } else {
        let updatedFilters;

        updatedFilters = isExclusive
          ? filter(filters, (i) => i.key !== key)
          : filter(filters, (i) => i.key !== key || i.value !== value);

        updatedFilters.push({ key, value });
        setFilters(updatedFilters);
      }
    },
    [filters, getIsSelected, onRemove, setFilters]
  );

  return {
    filters,
    setFilters,
    sort,
    setSort,
    sortOrder,
    setSortOrder,
    getIsSelected,
    onFindCityFilter,
    onFindSortByFilter,
    onRemove,
    onReset,
    onToggle,
  };
};
