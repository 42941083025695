/* eslint-disable react-hooks/exhaustive-deps */
import { ICoordinates } from "@types";
import { useEffect, useState } from "react";
import { useGeolocation as useHook } from "rooks";
import { useLocalStorage } from "./use-local-storage";

export const useGeolocation = () => {
  const geolocation = useHook();
  const { getItem, setItem } = useLocalStorage();
  const [savedLocation, setSavedLocation] = useState<ICoordinates>(() => {
    return getItem("geolocation");
  });

  useEffect(() => {
    try {
      if (!geolocation || geolocation?.isError) return;

      const { lat, lng } = geolocation;
      const newValue: ICoordinates = { lat, lon: lng };

      // Only update if the location has changed
      if (savedLocation.lat !== lat || savedLocation.lon !== lng) {
        setItem("geolocation", newValue);
        setSavedLocation(newValue);
      }
    } catch (err) {
      const error = err as Error;
      console.error(error.message);
    }
  }, [geolocation?.lat, geolocation?.lng]);

  return savedLocation;
};
