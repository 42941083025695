export const useLocalStorage = () => {
  const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();

  const getItem = (key: string): object => {
    return isBrowser
      ? window.localStorage[key]
        ? JSON.parse(window.localStorage[key])
        : {}
      : {};
  };

  const setItem = (key: string, value: object): boolean => {
    if (isBrowser) {
      window.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }

    return false;
  };

  return { getItem, setItem };
};
