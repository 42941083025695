import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, IAdminLog } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";
import { Key } from "swr";

export const useAdminLogs = (
  params?: Record<string, any>,
  config?: ApiSwrConfig<IAdminLog[]>,
  overrideRoute?: Key
) => {
  const route = overrideRoute ?? getApiUrl(apiRoutes.adminLogs, null, params);
  return useApiSWR<IAdminLog[]>(route, config);
};
