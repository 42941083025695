import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, ITrip } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";

export const useTrip = (
  tripId: string,
  config?: ApiSwrConfig<ITrip>,
  overrideRoute?: string
) => {
  const route = overrideRoute ?? getApiUrl(apiRoutes.tripId, tripId);
  return useApiSWR<ITrip>(route, config);
};
