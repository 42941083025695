"use client";

import {
  AsyncCombobox,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@components/ui";
import apiRoutes from "@constants/api-routes";
import { usePostRequest } from "@hooks";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";
import { IUser } from "@types";
import { getApiUrl } from "@utils/api";
import { parseCityOptionAsync, parseCityOptionsAsync } from "@utils/parse";
import { isEmpty } from "lodash";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useModal } from "./modal-provider";

export const CurrentCityModal = () => {
  const { t } = useTranslation();
  const [modified, setModified] = useState<Partial<IUser>>();
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const data = options?.data as IUser;
  const update = usePostRequest<IUser>(getApiUrl(apiRoutes.userId, data?._id));

  const onUpdate = async () => {
    try {
      if (!modified) return;
      await update.trigger(modified, {
        successMessage: "Current city updated successfully",
        errorMessage: "Could not update current city",
      });

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (value: string) => {
    setModified((prev) => ({ ...prev, livingInId: value }));
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent
        className="py-10 w-[26rem]"
        nonDismissable={options?.nonDismissable}
      >
        <DialogHeader>
          <DialogTitle>{t("pages.home.changeCurrentCity")}</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2">
          <AsyncCombobox
            placeholder={t("pages.country.typeToSearch")}
            loadOptions={parseCityOptionsAsync}
            selectedItemAsync={parseCityOptionAsync}
            value={modified?.livingInId ?? data?.livingInId ?? ""}
            onChange={handleChange}
          />
          <p className="text-sm text-muted-foreground">
            {t("pages.profile.livingInHelp")}
          </p>
        </div>

        <DialogFooter>
          <div className="flex justify-end gap-2">
            <Button onClick={closeModal} size="sm" variant="outline">
              <IconX size={20} />
              {t("actions.cancel")}
            </Button>
            <Button
              onClick={onUpdate}
              isLoading={update.isLoading}
              size="sm"
              disabled={isEmpty(modified)}
            >
              <IconDeviceFloppy size={20} />
              {t("actions.saveChanges")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
