import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@components/ui";
import { IconX } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { useModal } from "./modal-provider";

export const DisplayInfoModal = () => {
  const { t } = useTranslation();
  const {
    currentModal: { options },
    closeModal,
  } = useModal();

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className="max-h-[40rem] overflow-y-scroll">
        <DialogHeader>{options?.title}</DialogHeader>
        <div className="overflow-hidden">
          <pre>{JSON.stringify(options?.data, null, 2)}</pre>
        </div>
        <DialogFooter>
          <div className="flex gap-2">
            <Button size="sm" variant="ghost" onClick={closeModal}>
              <IconX />
              {t("actions.close")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
