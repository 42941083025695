import debounce from "lodash/debounce";
import { RefObject, useEffect, useMemo } from "react";

export const useInfiniteScroll = (
  containerRef: RefObject<HTMLDivElement>,
  loadMore: () => void
) => {
  const handleDebouncedScroll = useMemo(() => {
    const fn = () => {
      if (!containerRef.current || typeof window === "undefined") return;
      const container = containerRef.current;
      const { bottom } = container.getBoundingClientRect();

      if (bottom <= window.innerHeight) loadMore();
    };

    return debounce(fn, 200);
  }, [containerRef, loadMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleDebouncedScroll);
    return () => {
      window.removeEventListener("scroll", handleDebouncedScroll);
    };
  }, [handleDebouncedScroll]);
};
