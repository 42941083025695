import { TailwindIndicator } from "@components/common";
import { AdminHeader, MainHeader } from "@components/layout";
import appConfig from "@constants/app-config";
import { useRouter } from "next/router";
import { ReactNode, memo, useMemo } from "react";
import { Footer } from "./footer";
import { FtuFlow } from "./ftu-flow";

interface Props {
  children: ReactNode;
}

export const Wrapper = memo(({ children }: Props) => {
  const pathname = useRouter().pathname;
  const noLayout = useMemo(() => {
    if (!pathname) return false;
    return appConfig.routesWithoutLayout.includes(pathname);
  }, [pathname]);

  if (noLayout) return <>{children}</>;

  return (
    <div className="flex flex-col flex-1 min-h-screen overflow-hidden">
      <MainHeader />
      <AdminHeader />

      <div className="container flex-1">
        <div className="h-full px-1 py-8 lg:px-2">{children}</div>
      </div>

      <FtuFlow />
      <Footer />
      <TailwindIndicator />
    </div>
  );
});

Wrapper.displayName = "Wrapper";
