import "@/app/globals.css";
import { cn } from "@/lib/utils";
import { AppRoot } from "@components/app-root";
import { Providers } from "@components/layout";
import appConfig from "@constants/app-config";
import type { Session } from "next-auth";
import { appWithTranslation, useTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { Nunito_Sans } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import NextNprogress from "nextjs-progressbar";

const font = Nunito_Sans({
  subsets: ["latin"],
  variable: "--display-font",
});

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <>
      <Head>
        <title>{t("pages.title")}</title>
        <meta name="twitter:site" content="@findcityio" />
        <meta name="twitter:title" content={t("pages.title")} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={t("pages.description")} />
        <meta
          name="twitter:image"
          content={`${appConfig.domain}/images/og/image-sm.jpg`}
        />
        <meta name="og:title" content={t("pages.title")} />
        <meta name="og:description" content={t("pages.description")} />
        <meta property="og:site_name" content="FindCity" />
        <meta
          property="og:image"
          content={`${appConfig.domain}/images/og/image.jpg`}
        />
        <meta
          property="og:url"
          content={`${appConfig.domain}${router.asPath}`}
        />
        <meta name="description" content={t("pages.description")} />
        <meta name="keywords" content={t("pages.keywords")} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#F97316"
        />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta
          name="theme-color"
          content="#e8e9e9"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#11141c"
          media="(prefers-color-scheme: dark)"
        />
      </Head>

      {/* App backgrounds */}
      <div className="visible dark:invisible absolute top-0 -z-20 h-screen w-screen bg-neutral-100 bg-[radial-gradient(ellipse_60%_60%_at_50%_-20%,rgba(249,116,21,0.3),rgba(255,255,255,0))]"></div>
      <div className="invisible dark:visible absolute top-0 -z-20 h-screen w-screen bg-neutral-950 bg-[radial-gradient(ellipse_60%_60%_at_50%_-20%,rgba(249,116,21,0.3),rgb(24,24,24))]"></div>

      <style jsx global>{`
        html {
          font-family: ${font.style.fontFamily};
        }
      `}</style>
      <main className={cn(font.variable, "overflow-x-hidden")}>
        {/* Loading progress bar */}
        <NextNprogress
          color="#F97316"
          options={{ easing: "ease", speed: 500, showSpinner: false }}
        />

        {/* Google analytics */}
        <Script
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=G-BFVEQ07QRB"
        />
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-BFVEQ07QRB', { page_path: window.location.pathname });
              `,
          }}
        />

        <Providers session={session}>
          <Component {...pageProps} />
          <AppRoot />
        </Providers>
      </main>
    </>
  );
};

export default appWithTranslation(App);
