"use client";

import { useDebounce } from "@/hooks";
import { CountryIcon } from "@components/country";
import {
  Command,
  CommandEmpty,
  CommandItem,
  CommandList,
  CommandLoading,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui";
import { fetchSearchData } from "@services";
import { IconBackspace, IconLoader2, IconSearch } from "@tabler/icons-react";
import { ISearchResult } from "@types";
import { parseHighlightSearchTerm } from "@utils/parse";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

export const SearchBar = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchString = useDebounce(searchString, 500);

  // Listen to keyboard triggering focus
  useEffect(() => {
    const keyDownHandler = (e: any) => {
      if (e.ctrlKey && e.key === "k") {
        e.preventDefault();
        inputRef.current && inputRef.current.focus();
      }
    };
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  // Search on type
  useEffect(() => {
    if (!debouncedSearchString) return;

    (async () => {
      setIsLoading(true);
      const result = await fetchSearchData(debouncedSearchString);
      setSearchResults(result);
      setIsLoading(false);
    })();
  }, [debouncedSearchString]);

  const onInputClear = () => {
    setSearchString("");
    setSearchResults([]);
  };

  const onLoseBlur = () => {
    inputRef.current?.blur();
    onInputClear();
  };

  return (
    <Popover open={Boolean(searchString)}>
      <PopoverTrigger>
        <div className="relative w-full md:w-[14rem] flex items-center">
          <Input
            type="text"
            ref={inputRef}
            className="h-10 px-4 text-base border-none shadow-none bg-white/40 dark:bg-black/20 pe-10"
            placeholder={t("actions.searchPlaceholder")}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            onBlur={(e) => {
              e.relatedTarget?.role?.includes("dialog")
                ? inputRef.current?.focus()
                : onLoseBlur();
            }}
          />
          <div className="absolute flex items-center h-full right-3 text-foreground">
            {isLoading ? (
              <IconLoader2 className="animate-spin" size={18} />
            ) : searchString ? (
              <IconBackspace
                className="cursor-pointer"
                onClick={onInputClear}
                size={18}
              />
            ) : (
              <IconSearch onClick={() => inputRef.current?.focus()} size={18} />
            )}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[14rem] p-2">
        <Command>
          <CommandList>
            {searchResults.length ? (
              searchResults.map((item, index) => (
                <CommandItem
                  key={index}
                  className="px-4 cursor-pointer"
                  onSelect={() => {
                    item?.href && router.push(item.href);
                    onLoseBlur();
                  }}
                >
                  <CountryIcon code={item.countryCode} />
                  <div className="flex flex-col">
                    <p
                      className="w-full text-sm line-clamp-1"
                      dangerouslySetInnerHTML={{
                        __html: parseHighlightSearchTerm(
                          item.label,
                          debouncedSearchString
                        ),
                      }}
                    />
                    {item.subtitle && (
                      <p className="text-xs text-muted-foreground">
                        {item.subtitle}
                      </p>
                    )}
                  </div>
                </CommandItem>
              ))
            ) : isLoading ? (
              <CommandLoading />
            ) : (
              <CommandEmpty>{t("common.noOptionsFound")}</CommandEmpty>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
