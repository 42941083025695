import { axiosInstance } from "@/lib/axios";
import apiRoutes from "@constants/api-routes";
import { ICity } from "@types";
import { getApiUrl } from "@utils/api";

export async function fetchCityData(id: string): Promise<ICity> {
  try {
    const url = getApiUrl(apiRoutes.cityId, id);
    const response = await axiosInstance.get<ICity>(url);
    return response.data;
  } catch (error) {
    console.error("Unexpected error:", error);
    throw error;
  }
}

export async function fetchCompareData(ids: string[]): Promise<ICity[]> {
  try {
    const params = { cities: ids };
    const url = getApiUrl(apiRoutes.cityCompare, null, params);
    const response = await axiosInstance.get<ICity[]>(url);
    return response.data;
  } catch (error) {
    console.error("Unexpected error:", error);
    throw error;
  }
}
