const apiRoutes = {
  admin: "/api/admin",
  adminCostsFeedback: "/api/admin/costs-feedback",
  adminCostsFeedbackId: "/api/admin/costs-feedback/:id",
  adminDataCompletion: "/api/admin/data-completion",
  adminLogs: "/api/admin/logs",
  adminSuggestions: "/api/admin/suggestions",
  adminSuggestionsId: "/api/admin/suggestions/:id",
  adminSuggestionsRemoveId: "/api/admin/suggestions/:id/remove",
  authMe: "/api/auth/me",
  authMeSettings: "/api/auth/me/settings",
  authSignUp: "/api/auth/signup",
  city: "/api/city",
  cityAdvisor: "/api/pro/city-advisor",
  cityCompare: "/api/city/compare",
  cityId: "/api/city/:id",
  cityIdAdvancedStats: "/api/city/:id/advanced-stats",
  cityCosts: "/api/city/costs",
  cityFav: "/api/city/fav/:id",
  citySearch: "/api/city/search",
  country: "/api/country",
  countryId: "/api/country/:id",
  countrySearch: "/api/country/search",
  dashboard: "/api/dashboard",
  dashboardClosestCities: "/api/dashboard/closest-cities",
  exchangeRates: "/api/exchange-rates",
  explore: "/api/explore",
  feedback: "/api/feedback",
  feedbackSuggestWfps: "/api/feedback/suggest/wfps",
  landing: "/api/landing",
  search: "/api/search",
  stripeSession: "/api/stripe/session",
  toursId: "/api/tours/:id",
  tripPlanner: "/api/pro/trip-planner",
  trips: "/api/trips",
  tripId: "/api/trips/:id",
  user: "/api/user",
  userId: "/api/user/:id",
  userIdBalance: "/api/user/:id/balance",
  userIdPay: "/api/user/:id/pay",
  userFavs: "/api/user/favs",
  userFollow: "/api/user/follow/:id",
  userRemoveId: "/api/user/:id/remove",
  userSocial: "/api/user/social",
  userTrips: "/api/user/trips",
  userUnfollow: "/api/user/unfollow/:id",
  weather: "/api/weather",
  wfp: "/api/wfp",
  wfpId: "/api/wfp/:id",
  wfpRemoveId: "/api/wfp/:id/remove",
  review: "/api/review",
  reviewIdRemove: "/api/review/:id/remove",
  external: {
    numbeoCities: "/api/external/numbeo/cities",
    numbeoCountries: "/api/external/numbeo/countries",
    restCountries: "/api/external/rest-countries",
    worldbank: "/api/external/worldbank",
    advancedStats: "/api/external/advanced-stats",
    property: "/api/external/property",
  },
};

export default apiRoutes;
