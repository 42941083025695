import { create } from "zustand";

interface State {
  favCities: string[];
}

interface Actions {
  toggleFavCity: (cityId: string) => void;
  setFavoriteCities: (cityIds: string[]) => void;
}

type Store = State & Actions;

const useFavsSlice = create<Store>((set) => ({
  favCities: [],
  setFavoriteCities: (payload) => {
    return set((state) => ({ ...state, favCities: payload }));
  },
  toggleFavCity: (cityId) => {
    return set((state) => ({
      ...state,
      favCities: state.favCities.includes(cityId)
        ? state.favCities.filter((item) => item !== cityId)
        : [...state.favCities, cityId],
    }));
  },
}));

export default useFavsSlice;
