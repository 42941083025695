import appConfig from "@constants/app-config";
import { useAuth, useInitialLoad } from "@hooks";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useModal } from "./modals";

export const AppRoot = () => {
  useInitialLoad();
  const { isLoading, isLogged } = useAuth();
  const { openModal } = useModal();
  const pathname = useRouter().pathname;

  useEffect(() => {
    if (!pathname || isLoading || isLogged) return;
    const shouldOpen = !appConfig.routesWithoutAuth.includes(pathname);
    shouldOpen && openModal("auth", { nonDismissable: true });
  }, [isLogged, isLoading, pathname]);

  return null;
};
