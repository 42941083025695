import { axiosInstance } from "@/lib/axios";
import apiRoutes from "@constants/api-routes";
import { ILandingResponse } from "@types";
import { getApiUrl } from "@utils/api";

export async function fetchLandingData(): Promise<ILandingResponse> {
  try {
    const url = getApiUrl(apiRoutes.landing);
    const response = await axiosInstance.get<ILandingResponse>(url);
    return response.data;
  } catch (error) {
    console.error("Unexpected error:", error);
    throw error;
  }
}
