import { AppName } from "@components/common";
import {
  AuthSwitcher,
  LanguageSwitcher,
  SearchBar,
  ThemeSwitcher,
} from "@components/layout";
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from "@components/ui";
import routes from "@constants/routes";
import { IconMenu2 } from "@tabler/icons-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Navbar } from "./navbar";

export const MobileDrawer = () => {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  // Close drawer on navigation change
  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setOpen(false);
    });
  }, [router.events]);

  return (
    <div className="flex items-center justify-between w-full h-16 gap-2 px-6">
      <AppName size={28} link={routes.home} isExtended />

      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>
          <Button className="button-header" variant="ghost">
            <IconMenu2 />
          </Button>
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <AppName className="flex flex-col gap-1" size={36} isExtended />
          </DrawerHeader>

          <div className="flex flex-col items-center w-full gap-4">
            <AuthSwitcher />
            <Navbar />
            <SearchBar />
          </div>

          <DrawerFooter>
            <LanguageSwitcher isExtended />
            <ThemeSwitcher isExtended />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};
