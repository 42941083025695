import { cn } from "@/lib/utils";
import { PageLoader } from "@components/layout";
import { Button, ButtonProps } from "@components/ui";
import { type Icon as TablerIcon } from "@tabler/icons-react";
import { ISectionProps } from "@types";
import Image from "next/image";
import { OptionalLink } from "./optional-link";

export const Section = ({
  actions,
  children,
  illustration,
  isEmpty = false,
  isLoading = false,
  skeleton,
  subtitle,
  SubtitleIcon,
  title,
  className,
  ...rest
}: ISectionProps) => {
  return (
    <section
      className={cn("flex flex-col w-full gap-2 mb-12", className)}
      {...rest}
    >
      {/* Heading  */}
      <div className="flex flex-col items-center justify-between gap-6 mb-5 md:flex-row md:gap-4">
        <div className="flex flex-col w-full gap-1">
          {title && (
            <h1 className="text-2xl font-semibold tracking-tight truncate md:text-4xl">
              {title}
            </h1>
          )}
          {subtitle && (
            <div className="flex gap-2 leading-none text-muted-foreground">
              {SubtitleIcon && <SubtitleIcon size={16} />}
              <p>{subtitle}</p>
            </div>
          )}
        </div>

        {actions && (
          <div className="flex flex-col justify-end w-full gap-2 md:w-auto md:flex-row">
            {actions}
          </div>
        )}
      </div>

      {/* Body */}
      <SectionBody {...{ isLoading, skeleton, isEmpty, illustration }}>
        {children}
      </SectionBody>
    </section>
  );
};

const SectionBody = ({
  isLoading,
  skeleton,
  illustration = "document.svg",
  isEmpty,
  children,
}: Partial<ISectionProps>) => {
  if (isLoading) return skeleton || <PageLoader />;
  if (isEmpty) {
    return (
      <div className="flex items-center justify-center">
        <Image
          alt="Empty"
          className="opacity-90 dark:opacity-50"
          src={`/illustrations/${illustration}`}
          height={320}
          width={320}
        />
      </div>
    );
  }

  return <div className="h-full">{children}</div>;
};

interface SectionButtonProps extends ButtonProps {
  Icon?: TablerIcon;
  link?: string;
}
export const SectionButton = ({
  Icon,
  link,
  className,
  children,
  ...rest
}: SectionButtonProps) => (
  <OptionalLink href={link}>
    <Button
      className={cn("flex gap-2 w-full px-5 md:min-w-24 md:w-auto", className)}
      size="sm"
      {...rest}
    >
      {Icon && <Icon size={18} />}
      {children}
    </Button>
  </OptionalLink>
);
