import { IUser } from "@types";
import { create } from "zustand";

interface State {
  user?: Partial<IUser>;
}

interface Actions {
  editUser: (user: Partial<IUser>) => void;
}

type Store = State & Actions;

const useUserStore = create<Store>((set) => ({
  user: undefined,
  editUser: (payload) => {
    return set((state) => ({ ...state, user: { ...state.user, ...payload } }));
  },
}));

export default useUserStore;
