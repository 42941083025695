import { AppName } from "@components/common";
import { MobileDrawer } from "@components/layout/header/mobile-drawer";
import { Navbar } from "@components/layout/header/navbar";
import routes from "@constants/routes";
import { AuthSwitcher } from "./auth-switcher";
import { LanguageSwitcher } from "./language-switcher";
import { SearchBar } from "./search-bar";
import { ThemeSwitcher } from "./theme-switcher";

export const MainHeader = () => (
  <header className="w-full">
    <div className="flex lg:hidden">
      <MobileDrawer />
    </div>

    <div className="container hidden lg:flex">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex items-center gap-6 mr-2">
          <AppName link={routes.home} />
          <Navbar />
        </div>

        <div className="flex gap-2">
          <SearchBar />
          <LanguageSwitcher />
          <ThemeSwitcher />
          <AuthSwitcher />
        </div>
      </div>
    </div>
  </header>
);
