import { cn } from "@/lib/utils";
import {
  Button,
  ButtonProps,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from "@components/ui";
import {
  IconArrowDown,
  IconArrowUp,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
  Icon as TablerIcon,
} from "@tabler/icons-react";
import {
  Column,
  ColumnDef,
  SortingState,
  Table as TableType,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "next-i18next";
import { useState } from "react";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  pageSize?: number;
  columnVisibility?: VisibilityState;
  allowToggleColumns?: boolean;
};

export const DataTable = <Data extends object>({
  data,
  columns,
  pageSize = 10,
  columnVisibility,
  allowToggleColumns,
}: DataTableProps<Data>) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { sorting },
    initialState: { pagination: { pageSize }, columnVisibility },
    autoResetPageIndex: false,
  });

  return (
    <>
      {allowToggleColumns && (
        <div className="flex justify-end">
          <ColumnsToggler table={table} />
        </div>
      )}

      <div className="block max-w-full mt-2 overflow-x-auto overflow-y-hidden border rounded-md border-border whitespace-nowrap">
        <div className="relative w-full overflow-auto">
          <Table className="w-full overflow-x-scroll">
            <TableHeader className="h-20">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="hover:bg-transparent">
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        className={cn(
                          "flex font-bold truncate w-full uppercase text-sm items-center justify-between h-8",
                          header.column.getCanFilter() && "cursor-pointer"
                        )}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                        <span className="inline pl-4">
                          {header.column.getIsSorted() &&
                            (header.column.getIsSorted() === "desc" ? (
                              <IconArrowDown
                                size={15}
                                aria-label="sorted descending"
                              />
                            ) : (
                              <IconArrowUp
                                size={15}
                                aria-label="sorted descending"
                              />
                            ))}
                        </span>
                      </div>

                      <div className="h-4">
                        {header.column.getCanFilter() && (
                          <Filter column={header.column} table={table} />
                        )}
                      </div>
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className="min-w-32" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex justify-center w-full gap-2 my-4">
        <Button
          onClick={() => table.setPageIndex(0)}
          variant="outline"
          disabled={!table.getCanPreviousPage()}
        >
          <IconChevronsLeft size={16} />
          {t("common.first")}
        </Button>
        <Button
          onClick={() => table.previousPage()}
          variant="outline"
          disabled={!table.getCanPreviousPage()}
        >
          {<IconChevronLeft size={16} />}
          {t("actions.previous")}
        </Button>
        <Button
          onClick={() => table.nextPage()}
          variant="outline"
          disabled={!table.getCanNextPage()}
        >
          {<IconChevronRight size={16} />}
          {t("actions.next")}
        </Button>
        <Button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          variant="outline"
          disabled={!table.getCanNextPage()}
        >
          {<IconChevronsRight size={16} />}
          {t("common.last")}
        </Button>
      </div>

      {/* Items per page */}
      <div className="flex items-center justify-center w-full gap-8 py-1">
        <p
          dangerouslySetInnerHTML={{
            __html: t("common.pageNumberOfTotal", {
              number: table.getState().pagination.pageIndex + 1,
              total: table.getPageCount(),
            }),
          }}
        />

        <Select
          value={String(table.getState().pagination.pageSize)}
          onValueChange={(e) => {
            table.setPageSize(Number(e));
          }}
        >
          <SelectTrigger className="w-40">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <SelectItem key={pageSize} value={String(pageSize)}>
                {t("common.showCount", { count: pageSize })}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
};

interface FilterProps {
  column: Column<any, any>;
  table: TableType<any>;
}

const Filter = ({ column, table }: FilterProps) => {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  return typeof firstValue === "number" ? (
    <div className="flex gap-1">
      <Input
        type="number"
        className="h-5 p-0 text-sm bg-transparent border-none rounded-none shadow-none focus-visible:ring-0"
        value={((column.getFilterValue() as any)?.[0] ?? "") as string}
        placeholder="Min"
        onChange={(e) =>
          column.setFilterValue((old: any) => [e.target.value, old?.[1]])
        }
      />
      <Input
        type="number"
        className="h-5 p-0 text-sm bg-transparent border-none rounded-none shadow-none focus-visible:ring-0"
        value={((column.getFilterValue() as any)?.[1] ?? "") as string}
        placeholder="Max"
        onChange={(e) =>
          column.setFilterValue((old: any) => [old?.[0], e.target.value])
        }
      />
    </div>
  ) : (
    <Input
      type="text"
      className="h-5 p-0 text-sm bg-transparent border-none rounded-none shadow-none focus-visible:ring-0"
      value={(column.getFilterValue() ?? "") as string}
      placeholder="Search..."
      onChange={(e) => column.setFilterValue(e.target.value)}
    />
  );
};

const ColumnsToggler = ({ table }: { table: TableType<any> }) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          {t("common.columns")}
          <IconChevronDown size={18} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {table.getAllLeafColumns().map((column) => (
          <DropdownMenuCheckboxItem
            key={column.id}
            onClick={column.getToggleVisibilityHandler()}
            checked={column.getIsVisible()}
          >
            {column.columnDef.header?.toString()}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

type DataTableActionButtonProps = {
  Icon: TablerIcon;
  tooltip: string;
} & ButtonProps;

export const DataTableActionButton = ({
  Icon,
  tooltip,
  ...rest
}: DataTableActionButtonProps) => (
  <Tooltip tooltip={tooltip} asChild>
    <Button size="icon" variant="outline" {...rest}>
      <Icon size={18} />
    </Button>
  </Tooltip>
);

interface DataTableColumnWithSubtitleProps {
  value: string;
  subtitle?: string;
}

export const DataTableColumnWithSubtitle = ({
  value,
  subtitle,
}: DataTableColumnWithSubtitleProps) => (
  <div className="flex items-baseline gap-2">
    <p>{value}</p>
    {subtitle && <p className="text-sm text-muted-foreground">({subtitle})</p>}
  </div>
);
