"use client";

import { cn } from "@/lib/utils";
import { ISelectOption } from "@/types";
import { IconChevronDown, IconX } from "@tabler/icons-react";
import { getSelectedValuesByValue } from "@utils/parse";
import { useTranslation } from "next-i18next";
import * as React from "react";
import { Badge } from "./badge";
import { Button } from "./button";
import { ComboboxItem } from "./combobox";
import { Command, CommandEmpty, CommandGroup, CommandInput } from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

interface Props {
  className?: string;
  options: ISelectOption[];
  placeholder?: string;
  value?: string[];
  onChange: (value: string[]) => void;
  noResultMessage?: string;
  disableSearch?: boolean;
  disableSorting?: boolean;
  disableTranslate?: boolean;
}

const ComboboxMulti = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, options, placeholder, value, ...props }: Props, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [_value, _setValue] = React.useState<string[]>([]);

    // Sort options on translated label
    const sortedOptions = React.useMemo(() => {
      return props.disableSorting
        ? options.map((item) => ({ ...item, label: t(item.label) }))
        : options.sort((a, b) => t(a.label).localeCompare(t(b.label)));
    }, [props.disableSorting, options, t]);

    React.useEffect(() => {
      _setValue(value ?? []);
    }, [value]);

    const onRemove = (item: ISelectOption) => {
      const newValue = _value.filter((x) => x !== item.value);
      props.onChange(newValue);
    };

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "justify-between border border-border h-fit w-full",
              className
            )}
          >
            {_value.length ? (
              <div className="flex flex-wrap gap-2">
                {getSelectedValuesByValue(options, _value)?.map((item) => (
                  <ComboboxMultiBadge
                    key={item.value}
                    item={item}
                    onRemove={onRemove}
                    disableTranslate={props.disableTranslate}
                  />
                ))}
              </div>
            ) : (
              <span className="font-normal text-muted-foreground">
                {placeholder ?? t("actions.selectOrSearch")}
              </span>
            )}
            <IconChevronDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-auto p-0 min-w-40">
          <Command className="max-h-[13.5rem]">
            {!props.disableSearch && <CommandInput placeholder="Search..." />}
            <CommandEmpty>{props.noResultMessage}</CommandEmpty>
            <CommandGroup className="overflow-y-scroll">
              {sortedOptions.map((item, index) => (
                <ComboboxItem
                  key={index}
                  value={item.label}
                  item={item}
                  checked={_value.includes(item.value)}
                  onSelect={() => {
                    const newValue = _value.includes(item.value)
                      ? _value.filter((x) => x !== item.value)
                      : [..._value, item.value];

                    props.onChange(newValue);
                    setOpen(false);
                  }}
                >
                  {t(item.label)}
                </ComboboxItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
ComboboxMulti.displayName = "ComboboxMulti";

interface ComboboxMultiBadgeProps {
  item: ISelectOption;
  onRemove: (item: ISelectOption) => void;
  disableTranslate?: boolean;
}

const ComboboxMultiBadge = ({
  item,
  onRemove,
  disableTranslate,
}: ComboboxMultiBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Badge
      className="h-6 gap-1 bg-accent text-accent-foreground hover:bg-accent/70 w-fit"
      variant="secondary"
      onClick={(e) => {
        e.preventDefault();
        onRemove(item);
      }}
    >
      {disableTranslate ? item.label : t(item.label)}
      <IconX size={14} />
    </Badge>
  );
};

export { ComboboxMulti };
