import { cn } from "@/lib/utils";
import { IconStar, IconStarHalf } from "@tabler/icons-react";

const getStarState = (index: number, value: number) => {
  const isHalf = Number(value) % 1 !== 0;
  if (index < Math.floor(value)) {
    return "full";
  } else if (index === Math.floor(value) && isHalf) {
    return "half";
  }
};

interface StarRatingProps {
  value: number;
  size?: number;
  className?: string;
}

export const StarRating = ({
  value = 0,
  size = 16,
  className,
}: StarRatingProps) => (
  <div className="flex gap-1">
    {[...Array(5)].map((_, index) => {
      const state = getStarState(index, value);
      const props = { size, className: cn("fill-foreground", className) };

      if (state === "full") return <IconStar {...props} />;
      if (state === "half") return <IconStarHalf {...props} />;
    })}
  </div>
);
