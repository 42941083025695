import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, ICountry } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";

export const useCountry = (
  countryId: string,
  config?: ApiSwrConfig<ICountry>,
  overrideRoute?: string
) => {
  const route = overrideRoute ?? getApiUrl(apiRoutes.countryId, countryId);
  return useApiSWR<ICountry>(route, config);
};
