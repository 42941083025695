import { cn } from "@/lib/utils";
import { CountryIcon } from "@components/country";
import { parseCountryName } from "@utils/parse";
import { getCountryLink } from "@utils/routes";
import Link from "next/link";

type Props = {
  code?: string;
  name?: string;
  isLinkDisabled?: boolean;
  overrideLink?: string;
} & React.HTMLProps<HTMLParagraphElement>;

export const CountryIconName = ({
  code,
  name,
  overrideLink,
  isLinkDisabled,
  className,
  ...rest
}: Props) => {
  if (!code) return null;

  const children = (
    <div className="flex items-center gap-2">
      <CountryIcon code={code} className={cn("h-4 w-4", className)} />
      <p className={cn(isLinkDisabled ? "" : "hover:underline")} {...rest}>
        {name ?? parseCountryName(code)}
      </p>
    </div>
  );

  return isLinkDisabled ? (
    children
  ) : (
    <Link href={overrideLink || getCountryLink(code)}>{children}</Link>
  );
};
