import { axiosInstance } from "@/lib/axios";
import apiRoutes from "@constants/api-routes";
import { ISearchResult } from "@types";
import { getApiUrl } from "@utils/api";

export async function fetchSearchData(
  query: string,
  params?: Record<string, string | boolean>
): Promise<ISearchResult[]> {
  try {
    const url = getApiUrl(apiRoutes.search, null, { query, ...params });
    const response = await axiosInstance.get<ISearchResult[]>(url);
    return response.data;
  } catch (error) {
    console.error("Unexpected error:", error);
    throw error;
  }
}
