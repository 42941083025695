import localStorage from "@constants/local-storage";
import proItems from "@constants/pro-items";
import { ISearchResult, ITripPlan } from "@types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface Store {
  destinations: ISearchResult[];
  length?: number;
  activities: string[];
  accommodation: string[];
  transport: string[];
  budget: string;
  cost: number;
  generatedTripPlan?: ITripPlan;
  setDestinations: (value: ISearchResult[]) => void;
  addDestination: (value: ISearchResult) => void;
  removeDestination: (value: string) => void;
  toggleActivity: (value: string) => void;
  toggleAccommodation: (value: string) => void;
  toggleTransport: (value: string) => void;
  setLength: (value: number) => void;
  setBudget: (value: string) => void;
  setCost: (value: number) => void;
  setGeneratedTripPlan: (value?: ITripPlan) => void;
  clearTripPlan: () => void;
}

const useTripPlannerSlice = create<Store>()(
  persist(
    (set) => ({
      destinations: [],
      // length: "",
      activities: [],
      transport: [],
      accommodation: [],
      budget: "",
      cost: proItems.tripPlanner.defaultCost,
      generatedTripPlan: undefined,
      setDestinations: (item) => {
        return set((state) => ({
          ...state,
          destinations: item,
        }));
      },
      addDestination: (item) => {
        return set((state) => {
          if (state.destinations.some((x) => x._id === item._id)) return state;
          return {
            ...state,
            destinations: [...state.destinations, item],
          };
        });
      },
      removeDestination: (value) => {
        return set((state) => ({
          ...state,
          destinations: state.destinations.filter((x) => x._id !== value),
        }));
      },
      toggleActivity: (item) => {
        return set((state) => {
          const isEnabled = state.activities.includes(item);
          const activities = isEnabled
            ? state.activities.filter((x) => x !== item)
            : state.activities.length < 5
            ? [...state.activities, item]
            : state.activities;

          return { ...state, activities };
        });
      },
      toggleAccommodation: (item) => {
        return set((state) => {
          const isEnabled = state.accommodation.includes(item);
          const accommodation = isEnabled
            ? state.accommodation.filter((x) => x !== item)
            : state.accommodation.length < 3
            ? [...state.accommodation, item]
            : state.accommodation;

          return { ...state, accommodation };
        });
      },
      toggleTransport: (item) => {
        return set((state) => {
          const isEnabled = state.transport.includes(item);
          const transport = isEnabled
            ? state.transport.filter((x) => x !== item)
            : [...state.transport, item];

          return { ...state, transport };
        });
      },
      setLength: (item) => set((state) => ({ ...state, length: item })),
      setBudget: (item) => set((state) => ({ ...state, budget: item })),
      setCost: (item) => set((state) => ({ ...state, cost: item })),
      setGeneratedTripPlan: (item) =>
        set((state) => ({ ...state, generatedTripPlan: item })),
      clearTripPlan: () =>
        set((state) => ({
          ...state,
          destinations: [],
          activities: [],
          transport: [],
          accommodation: [],
          budget: "",
          cost: proItems.tripPlanner.defaultCost,
          generatedTripPlan: undefined,
        })),
    }),
    { name: localStorage.tripPlanner }
  )
);

export default useTripPlannerSlice;
