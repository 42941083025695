import { cn } from "@/lib/utils";
import { parseGradeToClass, parseGradeToText } from "@utils/parse";

interface Props {
  value: number;
  order?: "asc" | "desc";
  className?: string;
}

export const GradeTextColored = ({
  value,
  order = "asc",
  className,
}: Props) => (
  <p className={cn(parseGradeToClass(Number(value), order), className)}>
    {parseGradeToText(value)}
  </p>
);
