import { ICityFilterCategory } from "@types";

const filterOptions: ICityFilterCategory[] = [
  {
    key: "life",
    values: [
      {
        image: "/images/color/quality-of-life.webp",
        label: "filters.goodQualityOfLife",
        value: "goodQualityOfLife",
      },
      {
        image: "/images/color/crime.webp",
        label: "filters.safe",
        value: "safe",
      },
      {
        image: "/images/color/healthcare.webp",
        label: "filters.goodHealthcare",
        value: "goodHealthcare",
      },
      {
        image: "/images/color/pollution.webp",
        label: "filters.lowPollution",
        value: "lowPollution",
      },
      {
        image: "/images/color/herb.webp",
        label: "filters.veganFriendly",
        value: "veganFriendly",
      },
      {
        image: "/images/color/music.webp",
        label: "filters.greatNightlife",
        value: "greatNightlife",
      },
      {
        image: "/images/color/tourist.webp",
        label: "filters.touristFriendly",
        value: "touristFriendly",
      },
      {
        image: "/images/color/diet.webp",
        label: "filters.cuisineDiversity",
        value: "cuisineDiversity",
      },
      {
        image: "/images/color/ticket.webp",
        label: "filters.travelAccessible",
        value: "travelAccessible",
      },
      {
        image: "/images/color/landing.webp",
        label: "filters.accessible",
        value: "accessible",
      },
      {
        image: "/images/color/sport.webp",
        label: "filters.sportsAndActivities",
        value: "sportsAndActivities",
      },
    ],
  },
  {
    key: "community",
    values: [
      {
        image: "/images/color/languages.webp",
        label: "filters.languageAndFluency",
        value: "languageAndFluency",
      },
      {
        image: "/images/color/world.webp",
        label: "filters.culturallyOpen",
        value: "culturallyOpen",
      },
      {
        image: "/images/color/friends.webp",
        label: "filters.friendly",
        value: "friendly",
      },
      {
        image: "/images/color/reviews.webp",
        label: "filters.goodFeedback",
        value: "goodFeedback",
      },
      {
        image: "/images/color/traveler.webp",
        label: "filters.touristic",
        value: "touristic",
      },
      {
        image: "/images/color/job-seeker.webp",
        label: "filters.accessToOpportunities",
        value: "accessToOpportunities",
      },
    ],
  },
  {
    key: "economy",
    values: [
      {
        image: "/images/color/budget.webp",
        label: "pages.city.smAvgSalary",
        value: "smSalary",
        tooltip: "pages.city.smAvgSalaryDescription",
      },
      {
        image: "/images/color/budget.webp",
        label: "pages.city.mdAvgSalary",
        value: "mdSalary",
        tooltip: "pages.city.mdAvgSalaryDescription",
      },
      {
        image: "/images/color/budget.webp",
        label: "pages.city.lgAvgSalary",
        value: "lgSalary",
        tooltip: "pages.city.lgAvgSalaryDescription",
      },
      {
        image: "/images/color/cooperation.webp",
        label: "filters.goodForBusiness",
        value: "goodForBusiness",
      },
      {
        image: "/images/color/house.webp",
        label: "filters.accessibleHousing",
        value: "accessibleHousing",
      },
      {
        image: "/images/color/investment.webp",
        label: "filters.goodForInvesting",
        value: "goodForInvesting",
      },
      {
        image: "/images/color/stamped.webp",
        label: "filters.efficientBureaucracy",
        value: "efficientBureaucracy",
      },
    ],
  },
  {
    key: "continent",
    values: [
      {
        image: "/images/continents/af.webp",
        label: "continents.AF",
        value: "af",
        isExclusive: true,
      },
      {
        image: "/images/continents/as.webp",
        label: "continents.AS",
        value: "as",
        isExclusive: true,
      },
      {
        image: "/images/continents/eu.webp",
        label: "continents.EU",
        value: "eu",
        isExclusive: true,
      },
      {
        image: "/images/continents/na.webp",
        label: "continents.NA",
        value: "na",
        isExclusive: true,
      },
      {
        image: "/images/continents/sa.webp",
        label: "continents.SA",
        value: "sa",
        isExclusive: true,
      },
      {
        image: "/images/continents/oc.webp",
        label: "continents.OC",
        value: "oc",
        isExclusive: true,
      },
    ],
  },
  {
    key: "location",
    values: [
      {
        image: "/images/color/eu.webp",
        label: "pages.city.locations.eu",
        value: "eu",
        isExclusive: true,
      },
      {
        image: "/images/color/island.webp",
        label: "pages.city.locations.caribbean",
        value: "caribbean",
        isExclusive: true,
      },
      {
        image: "/images/color/balkan.webp",
        label: "pages.city.locations.balkan",
        value: "balkan",
        isExclusive: true,
      },
      {
        image: "/images/color/mosque.webp",
        label: "pages.city.locations.middleEast",
        value: "middleEast",
        isExclusive: true,
      },
      {
        image: "/images/color/vikings.webp",
        label: "pages.city.locations.nordics",
        value: "nordics",
        isExclusive: true,
      },
      {
        image: "/images/color/southeast-asia.webp",
        label: "pages.city.locations.southeastAsia",
        value: "southeastAsia",
        isExclusive: true,
      },
      {
        image: "/images/color/torii.webp",
        label: "pages.city.locations.eastAsia",
        value: "eastAsia",
        isExclusive: true,
      },
      {
        image: "/images/color/pyramids.webp",
        label: "pages.city.locations.northAfrica",
        value: "northAfrica",
        isExclusive: true,
      },
      {
        image: "/images/color/mediterranean.webp",
        label: "pages.city.locations.mediterranean",
        value: "mediterranean",
        isExclusive: true,
      },
    ],
  },
  {
    key: "environment",
    values: [
      {
        image: "/images/color/sea.webp",
        label: "pages.city.coastal",
        value: "coastal",
      },
      {
        image: "/images/color/cloudy.webp",
        label: "filters.greatWeather",
        value: "greatWeather",
      },
      {
        image: "/images/color/sun.webp",
        label: "filters.highSunlight",
        value: "highSunlight",
      },
      {
        image: "/images/color/moon.webp",
        label: "filters.lowSunlight",
        value: "lowSunlight",
      },
      {
        image: "/images/color/vacations.webp",
        label: "filters.beach",
        value: "beach",
      },
      {
        image: "/images/color/forest.webp",
        label: "filters.greatNature",
        value: "greatNature",
      },
      {
        image: "/images/color/ecology.webp",
        label: "filters.ecoFriendly",
        value: "ecoFriendly",
      },
    ],
  },
  {
    key: "size",
    values: [
      {
        image: "/images/color/city-size.webp",
        label: "pages.city.smSize",
        value: "small",
        tooltip: "pages.city.smSizeDescription",
        isExclusive: true,
      },
      {
        image: "/images/color/city-size.webp",
        label: "pages.city.mdSize",
        value: "medium",
        tooltip: "pages.city.mdSizeDescription",
        isExclusive: true,
      },
      {
        image: "/images/color/city-size.webp",
        label: "pages.city.lgSize",
        value: "large",
        tooltip: "pages.city.lgSizeDescription",
        isExclusive: true,
      },
    ],
  },
  {
    key: "prices",
    values: [
      {
        image: "/images/color/calculate.webp",
        label: "filters.lowCostOfLife",
        value: "lowCostOfLife",
      },
      {
        image: "/images/color/food-price.webp",
        label: "filters.cheapFood",
        value: "cheapFood",
      },
      {
        image: "/images/color/cost-housing.webp",
        label: "filters.cheapHousing",
        value: "cheapHousing",
      },
      {
        image: "/images/color/transport-cost.webp",
        label: "filters.cheapTransport",
        value: "cheapTransport",
      },
    ],
  },
];

export default filterOptions;
