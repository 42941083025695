"use client";

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@components/ui";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { useModal } from "./modal-provider";

export const ScriptsModal = () => {
  const { t } = useTranslation();
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const propsToUpdate = options?.data as string[];

  const handleSubmit = () => {
    options?.onSubmit && options.onSubmit();
    closeModal();
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {options?.title || "Update database information"}
          </DialogTitle>
          <DialogDescription>
            {`You're updating database data with the following properties`}
          </DialogDescription>
        </DialogHeader>
        <div>
          <pre className="text-sm">
            {propsToUpdate.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </pre>
        </div>
        <DialogFooter>
          <div className="flex justify-end gap-2">
            <Button size="sm" variant="ghost" onClick={closeModal}>
              <IconX />
              {t("actions.cancel")}
            </Button>
            <Button size="sm" onClick={handleSubmit}>
              <IconDeviceFloppy />
              {t("actions.saveChanges")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
