import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, ITrip } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";

export const useUserTrips = (
  config?: ApiSwrConfig<ITrip[]>,
  overrideRoute?: string
) => {
  const route = overrideRoute ?? getApiUrl(apiRoutes.userTrips);
  return useApiSWR<ITrip[]>(route, config);
};
