import {
  IRadioCardItem,
  ISelectOption,
  ITripPlannerAccommodationType,
  ITripPlannerActivityType,
  ITripPlannerBudgetType,
  ITripPlannerTransportType,
  IUserSettingTemperature,
} from "@types";
import {
  getCurrencyIcon,
  getFeedbackIcon,
  getTemperatureIcon,
} from "@utils/icons";
import { parseWfpIconColor } from "@utils/parse";
import countries from "../data/countries.json";
import languages from "../data/languages.json";
import currencies from "./currencies";

export const valuesWifiWfp: IRadioCardItem[] = [
  {
    color: parseWfpIconColor("good"),
    description: "pages.wfp.goodWifi",
    value: "good",
  },
  {
    color: parseWfpIconColor("medium"),
    description: "pages.wfp.mediumWifi",
    value: "medium",
  },
  {
    color: parseWfpIconColor("bad"),
    description: "pages.wfp.badWifi",
    value: "bad",
  },
  {
    color: parseWfpIconColor("none"),
    description: "pages.wfp.noneWifi",
    value: "none",
  },
];

export const valuesLoudnessWfp: IRadioCardItem[] = [
  {
    color: parseWfpIconColor("good"),
    description: "pages.wfp.goodLoudness",
    value: "good",
  },
  {
    color: parseWfpIconColor("medium"),
    description: "pages.wfp.mediumLoudness",
    value: "medium",
  },
  {
    color: parseWfpIconColor("bad"),
    description: "pages.wfp.badLoudness",
    value: "bad",
  },
];

export const valuesComfortableWfp: IRadioCardItem[] = [
  {
    color: parseWfpIconColor("good"),
    description: "pages.wfp.goodComfortable",
    value: "good",
  },
  {
    color: parseWfpIconColor("medium"),
    description: "pages.wfp.mediumComfortable",
    value: "medium",
  },
  {
    color: parseWfpIconColor("bad"),
    description: "pages.wfp.badComfortable",
    value: "bad",
  },
];

export const valuesPlaceTypeWfp: ISelectOption[] = [
  { label: "pages.wfp.cafeType", value: "cafe" },
  { label: "pages.wfp.barType", value: "bar" },
  { label: "pages.wfp.restaurantType", value: "restaurant" },
  { label: "pages.wfp.libraryType", value: "library" },
  { label: "pages.wfp.hotelType", value: "hotel" },
  { label: "pages.wfp.otherType", value: "other" },
];

export const valuesSuggestionCategories: ISelectOption[] = [
  { label: "entities.wfp", value: "wfp" },
];

export const valuesAdjectiveTagsWfp: ISelectOption[] = [
  { value: "airConditioning", label: "tags.airConditioning" },
  { value: "cashOnly", label: "tags.cashOnly" },
  { value: "cheap", label: "tags.cheap" },
  { value: "ethernetCables", label: "tags.ethernetCables" },
  { value: "expensive", label: "tags.expensive" },
  { value: "goodForCalls", label: "tags.goodForCalls" },
  { value: "groupTables", label: "tags.groupTables" },
  { value: "modern", label: "tags.modern" },
  { value: "parking", label: "tags.parking" },
  { value: "petFriendly", label: "tags.petFriendly" },
  { value: "smokingArea", label: "tags.smokingArea" },
  { value: "spacious", label: "tags.spacious" },
  { value: "terrace", label: "tags.terrace" },
];

export const valuesCityTags: ISelectOption[] = [
  {
    value: "coastal",
    label: "pages.city.coastal",
    image: "/images/color/sea.webp",
  },
  {
    value: "beach",
    label: "filters.beach",
    image: "/images/color/vacations.webp",
  },
  {
    value: "eu",
    label: "pages.city.locations.eu",
    image: "/images/color/eu.webp",
  },
  {
    value: "caribbean",
    label: "pages.city.locations.caribbean",
    image: "/images/color/island.webp",
  },
  {
    value: "balkan",
    label: "pages.city.locations.balkan",
    image: "/images/color/balkan.webp",
  },
  {
    value: "middleEast",
    label: "pages.city.locations.middleEast",
    image: "/images/color/mosque.webp",
  },
  {
    value: "southeastAsia",
    label: "pages.city.locations.southeastAsia",
    image: "/images/color/southeast-asia.webp",
  },
  {
    value: "nordics",
    label: "pages.city.locations.nordics",
    image: "/images/color/vikings.webp",
  },
  {
    value: "mediterranean",
    label: "pages.city.locations.mediterranean",
    image: "/images/color/mediterranean.webp",
  },
  {
    value: "northAfrica",
    label: "pages.city.locations.northAfrica",
    image: "/images/color/pyramids.webp",
  },
  {
    value: "eastAsia",
    label: "pages.city.locations.eastAsia",
    image: "/images/color/torii.webp",
  },
  {
    value: "touristic",
    label: "filters.touristic",
    image: "/images/color/traveler.webp",
  },
];

export const valuesFiltersSortBy: ISelectOption[] = [
  { value: "name", label: "pages.city.name" },
  { value: "continent", label: "pages.city.continent" },
  { value: "country", label: "pages.city.country" },
  { value: "population", label: "pages.city.population" },
];

export const valuesCountryOptions: ISelectOption[] = countries.map((item) => ({
  label: item.name,
  value: item.code,
}));

export const valuesLanguageOptions: ISelectOption[] = languages
  .sort((a, b) => a.name.localeCompare(b.name))
  .map((item) => ({
    label: item.name,
    value: item.code,
  }));

export const valuesCurrencyOptions: ISelectOption[] = currencies.map(
  ({ label, code }) => {
    const Icon = getCurrencyIcon(code);
    return { label, value: code, icon: Icon && <Icon size={20} /> };
  }
);

interface ITemperature {
  code: IUserSettingTemperature;
  label: string;
}

const temperatures: ITemperature[] = [
  { code: "c", label: "Celsius" },
  { code: "f", label: "Fahrenheit" },
];

export const valuesTemperatureOptions: ISelectOption[] = temperatures.map(
  ({ label, code }) => {
    const Icon = getTemperatureIcon(code);
    return { label, value: code, icon: Icon && <Icon size={20} /> };
  }
);

export const valuesContinentOptions: ISelectOption[] = [
  { label: "continents.AF", value: "AF" },
  { label: "continents.AN", value: "AN" },
  { label: "continents.AS", value: "AS" },
  { label: "continents.EU", value: "EU" },
  { label: "continents.NA", value: "NA" },
  { label: "continents.OC", value: "OC" },
  { label: "continents.SA", value: "SA" },
];

export const valuesCityAdvisorMovingOptions: (ISelectOption & {
  description: string;
})[] = [
  {
    description: "pages.city.advisor.shortDescription",
    label: "pages.city.advisor.shortLabel",
    value: "shortLength",
  },
  {
    description: "pages.city.advisor.mediumDescription",
    label: "pages.city.advisor.mediumLabel",
    value: "mediumLength",
  },
  {
    description: "pages.city.advisor.longDescription",
    label: "pages.city.advisor.longLabel",
    value: "longLength",
  },
];

export const valuesHelpOutCategoryOptions: ISelectOption[] = [
  {
    label: "pages.feedback.categorySuggestion",
    value: "suggestion",
    icon: getFeedbackIcon("suggestion"),
  },
  {
    label: "pages.feedback.categoryBug",
    value: "bug",
    icon: getFeedbackIcon("bug"),
  },
  {
    label: "pages.feedback.categoryError",
    value: "error",
    icon: getFeedbackIcon("error"),
  },
  {
    label: "pages.feedback.categoryOther",
    value: "other",
    icon: getFeedbackIcon("other"),
  },
].map((item) => {
  const Icon = item.icon;
  return { ...item, icon: Icon && <Icon size={20} /> };
});

export const valuesAdvancedStatsPromptOptions: ISelectOption[] = [
  { label: "pages.city.stats.foodPrices", value: "foodPrices" },
  { label: "pages.city.stats.weather", value: "weather" },
  { label: "pages.city.stats.nature", value: "nature" },
  { label: "pages.city.stats.language", value: "language" },
  { label: "pages.city.stats.openness", value: "openness" },
  { label: "pages.city.stats.safety", value: "safety" },
  { label: "pages.city.stats.accessibility", value: "accessibility" },
  { label: "pages.city.stats.housingAccess", value: "housingAccess" },
  { label: "pages.city.stats.sports", value: "sports" },
  { label: "pages.city.stats.nightlife", value: "nightlife" },
  { label: "pages.city.stats.housingCost", value: "housingCost" },
  { label: "pages.city.stats.investmentEase", value: "investmentEase" },
  { label: "pages.city.stats.bureaucracy", value: "bureaucracy" },
  { label: "pages.city.stats.business", value: "business" },
  { label: "pages.city.stats.salaries", value: "salaries" },
  { label: "pages.city.stats.friendliness", value: "friendliness" },
  { label: "pages.city.stats.feedback", value: "feedback" },
  { label: "pages.city.stats.foodQuality", value: "foodQuality" },
  { label: "pages.city.stats.ecoFriendliness", value: "ecoFriendliness" },
  { label: "pages.city.stats.veganFriendliness", value: "veganFriendliness" },
  { label: "pages.city.stats.transportAccess", value: "transportAccess" },
  { label: "pages.city.stats.transportCost", value: "transportCost" },
  { label: "pages.city.stats.opportunities", value: "opportunities" },
  {
    label: "pages.city.stats.touristFriendliness",
    value: "touristFriendliness",
  },
  {
    label: "pages.city.stats.travelAccessibility",
    value: "travelAccessibility",
  },
];

export const valuesTripPlannerLength: ISelectOption[] = [
  { value: "1", label: "pages.tripPlanner.daysNr" },
  { value: "2", label: "pages.tripPlanner.daysNr" },
  { value: "3", label: "pages.tripPlanner.daysNr" },
  { value: "4", label: "pages.tripPlanner.daysNr" },
  { value: "5", label: "pages.tripPlanner.daysNr" },
  { value: "6", label: "pages.tripPlanner.daysNr" },
  { value: "7", label: "pages.tripPlanner.daysNr" },
  { value: "8", label: "pages.tripPlanner.daysNr" },
  { value: "9", label: "pages.tripPlanner.daysNr" },
  { value: "10", label: "pages.tripPlanner.daysNr" },
  { value: "14", label: "pages.tripPlanner.daysNr" },
];

export const valuesTripPlannerActivities: ISelectOption<ITripPlannerActivityType>[] =
  [
    {
      label: "pages.tripPlanner.activityCultural",
      description: "pages.tripPlanner.activityCulturalDescription",
      value: "cultural",
      image: "/images/color/traveler.webp",
    },
    {
      label: "pages.tripPlanner.activityGastronomy",
      description: "pages.tripPlanner.activityGastronomyDescription",
      value: "gastronomy",
      image: "/images/color/diet.webp",
    },
    {
      label: "pages.tripPlanner.activityOutdoor",
      description: "pages.tripPlanner.activityOutdoorDescription",
      value: "outdoor",
      image: "/images/color/outdoors.webp",
    },
    {
      label: "pages.tripPlanner.activityNightlife",
      description: "pages.tripPlanner.activityNightlifeDescription",
      value: "nightlife",
      image: "/images/color/music.webp",
    },
    {
      label: "pages.tripPlanner.activityShopping",
      description: "pages.tripPlanner.activityShoppingDescription",
      value: "shopping",
      image: "/images/color/shopping-bag.webp",
    },
    {
      label: "pages.tripPlanner.activityRelaxation",
      description: "pages.tripPlanner.activityRelaxationDescription",
      value: "relaxation",
      image: "/images/color/massage.webp",
    },
    {
      label: "pages.tripPlanner.activityAdventure",
      description: "pages.tripPlanner.activityAdventureDescription",
      value: "adventure",
      image: "/images/color/backpack.webp",
    },
    {
      label: "pages.tripPlanner.activityHistory",
      description: "pages.tripPlanner.activityHistoryDescription",
      value: "history",
      image: "/images/color/pillar.webp",
    },
    {
      label: "pages.tripPlanner.activityArt",
      description: "pages.tripPlanner.activityArtDescription",
      value: "art",
      image: "/images/color/palette.webp",
    },
    {
      label: "pages.tripPlanner.activityScience",
      description: "pages.tripPlanner.activityScienceDescription",
      value: "science",
      image: "/images/color/science.webp",
    },
  ];

export const valuesTripPlannerTransport: ISelectOption<ITripPlannerTransportType>[] =
  [
    {
      label: "pages.tripPlanner.transportTrain",
      value: "train",
      image: "/images/color/train.webp",
    },
    {
      label: "pages.tripPlanner.transportPlane",
      value: "plane",
      image: "/images/color/airplane.webp",
    },
    {
      label: "pages.tripPlanner.transportCar",
      value: "car",
      image: "/images/color/car.webp",
    },
    {
      label: "pages.tripPlanner.transportBus",
      value: "bus",
      image: "/images/color/bus.webp",
    },
    {
      label: "pages.tripPlanner.transportBike",
      value: "bike",
      image: "/images/color/bicycle.webp",
    },
    {
      label: "pages.tripPlanner.transportBoat",
      value: "boat",
      image: "/images/color/ship.webp",
    },
    {
      label: "pages.tripPlanner.transportWalk",
      value: "walk",
      image: "/images/color/walk.webp",
    },
  ];

export const valuesTripPlannerAccomodationOptions: ISelectOption<ITripPlannerAccommodationType>[] =
  [
    {
      label: "pages.tripPlanner.hotel",
      description: "pages.tripPlanner.hotelDescription",
      value: "hotel",
      image: "/images/color/hotel.webp",
    },
    {
      label: "pages.tripPlanner.hostel",
      description: "pages.tripPlanner.hostelDescription",
      value: "hostel",
      image: "/images/color/hostel.webp",
    },
    {
      label: "pages.tripPlanner.apartment",
      description: "pages.tripPlanner.apartmentDescription",
      value: "apartment",
      image: "/images/color/apartment.webp",
    },
    {
      label: "pages.tripPlanner.resort",
      description: "pages.tripPlanner.resortDescription",
      value: "resort",
      image: "/images/color/resort.webp",
    },
    {
      label: "pages.tripPlanner.villa",
      description: "pages.tripPlanner.villaDescription",
      value: "villa",
      image: "/images/color/villa.webp",
    },
    {
      label: "pages.tripPlanner.cabin",
      description: "pages.tripPlanner.cabinDescription",
      value: "cabin",
      image: "/images/color/cabin.webp",
    },
    {
      label: "pages.tripPlanner.none",
      description: "pages.tripPlanner.noneDescription",
      value: "none",
    },
  ];

export const valuesTripPlannerBudgetOptions: ISelectOption<ITripPlannerBudgetType>[] =
  [
    {
      label: "pages.tripPlanner.budgetEconomy",
      description: "pages.tripPlanner.budgetEconomyDescription",
      value: "economy",
      image: "/images/color/chair.webp",
    },
    {
      label: "pages.tripPlanner.budgetComfort",
      description: "pages.tripPlanner.budgetComfortDescription",
      value: "comfort",
      image: "/images/color/armchair.webp",
    },
    {
      label: "pages.tripPlanner.budgetLuxury",
      description: "pages.tripPlanner.budgetLuxuryDescription",
      value: "luxury",
      image: "/images/color/sofa.webp",
    },
  ];

export const getFormValue = (
  value: string,
  options: ISelectOption<any>[]
): ISelectOption | undefined => {
  return options.find((item) => item.value === value);
};
