import apiRoutes from "@constants/api-routes";
import { CityAdvice, cityAdviceSchema } from "@types";
import { ObjectStreamFromResponse } from "modelfusion";
import { useCallback, useState } from "react";

interface GenerateParams {
  destination: string;
  lengthOfStay?: string;
  customIntent?: string;
}

export const useCityAdvice = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [advice, setAdvice] = useState<CityAdvice>();

  const generate = useCallback(
    async ({ destination, lengthOfStay, customIntent }: GenerateParams) => {
      setAdvice(undefined);
      setIsGenerating(true);

      try {
        const response = await fetch(apiRoutes.cityAdvisor, {
          method: "POST",
          body: JSON.stringify({ destination, lengthOfStay, customIntent }),
        });

        const stream = ObjectStreamFromResponse({
          schema: cityAdviceSchema,
          response,
        });

        for await (const { partialObject } of stream) {
          setAdvice(partialObject);
        }
      } finally {
        setIsGenerating(false);
      }
    },
    []
  );

  const clear = () => setAdvice(undefined);

  return { advice, generate, clear, isGenerating };
};
