"use client";

import { cn } from "@/lib/utils";
import {
  Button,
  ButtonProps,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@components/ui";
import languages from "@constants/languages";
import { IconChevronDown, IconLanguage } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import router, { useRouter } from "next/router";

interface Props extends ButtonProps {
  isExtended?: boolean;
}

// TODO: Dropdown doesn't work on mobile
export const LanguageSwitcher = ({ isExtended, className, ...rest }: Props) => {
  const { t, i18n } = useTranslation();
  const { locales, asPath, pathname, query } = useRouter();
  const onChangeLanguage = (locale: string) => {
    i18n.changeLanguage(locale);
    router.push({ pathname, query }, asPath, {
      locale,
      scroll: false,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          aria-label="Switch language"
          variant="ghost"
          className={cn(
            "button-header text-base",
            isExtended ? "w-full" : "p-0 min-w-10",
            className
          )}
          {...rest}
        >
          {i18n.language && !isExtended ? (
            <Image
              src={`/images/languages/${i18n.language}.svg`}
              alt={i18n.language}
              height={20}
              width={20}
            />
          ) : (
            <IconLanguage size={24} />
          )}
          {isExtended
            ? i18n.language
              ? languages[i18n.language]
              : t("actions.switchLanguage")
            : null}

          {isExtended && <IconChevronDown size={18} />}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[10rem]" side="bottom" align="end">
        {[...(locales ?? [])].map((lang) => (
          <DropdownMenuCheckboxItem
            key={lang}
            onClick={() => onChangeLanguage(lang)}
            checked={lang === i18n.language}
          >
            <div className="flex gap-2">
              <Image
                src={`/images/languages/${lang}.svg`}
                alt={lang}
                height={16}
                width={16}
              />
              <p className={cn(lang === i18n.language ? "font-bold" : "")}>
                {languages[lang]}
              </p>
            </div>
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
