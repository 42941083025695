"use client";

import {
  Button,
  Dialog,
  DialogContent,
  DialogOverlay,
  Input,
} from "@components/ui";
import routes from "@constants/routes";
import { useAuth } from "@hooks/use-auth";
import { useMagicLink } from "@services/use-magic-link";
import { IconMail } from "@tabler/icons-react";
import { getLink } from "@utils/routes";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useModal } from "./modal-provider";

export const AuthModal = () => {
  const { t } = useTranslation();
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const router = useRouter();
  const { isLogged } = useAuth();
  const { isLoading, onSubmit } = useMagicLink();
  const [email, setEmail] = useState("");

  const callbackUrl = useMemo(() => {
    if (router.pathname !== routes.landing) return undefined;
    return getLink("home", router.locale);
  }, [router]);

  const isValidEmail = useMemo(() => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }, [email]);

  const goBack = () => {
    router.back();
    setTimeout(() => {
      closeModal();
    }, 1000);
  };

  if (isLogged) return null;

  return (
    <Dialog
      open
      onOpenChange={options?.nonDismissable ? undefined : closeModal}
      modal
    >
      <DialogOverlay className="backdrop-blur-sm" />
      <DialogContent
        className="py-10 w-[26rem]"
        nonDismissable={options?.nonDismissable}
      >
        <div className="flex flex-col items-center gap-4">
          <Image
            className="opacity-80"
            src="/illustrations/sign-in.svg"
            alt="Sign in"
            height={200}
            width={200}
          />

          <div className="flex flex-col items-center">
            <h2 className="text-xl font-bold text-center">
              {t("auth.welcomeBack")}
            </h2>
            <p className="text-muted-foreground">
              {t("auth.welcomeBackSubtitle")}
            </p>
          </div>

          {/* Sign in 3rd party options */}
          <Button
            className="flex w-full px-8 bg-neutral-100 text-neutral-900 hover:bg-neutral-100/90 hover:text-neutral-900"
            variant="outline"
            onClick={() => {
              signIn("google", { callbackUrl });
            }}
          >
            <div className="flex gap-4 leading-none">
              <Image
                alt="Google logo"
                src="/images/brands/google.png"
                className="flex w-full"
                height={12}
                width={12}
              />
              <p>{t("auth.signInGoogle")}</p>
            </div>
          </Button>

          {/* Divider */}
          <div className="relative flex items-center w-full py-2">
            <div className="flex-grow border-t border-neutral-400 dark:border-neutral-500"></div>
            <span className="flex-shrink mx-4 text-xs font-bold uppercase text-neutral-400 dark:text-neutral-500">
              {t("common.or")} {t("auth.signInWithEmail")}
            </span>
            <div className="flex-grow border-t border-neutral-400 dark:border-neutral-500"></div>
          </div>

          {/* Sign in with email */}
          <div className="flex flex-col w-full gap-4">
            <div className="relative w-full">
              <IconMail className="absolute flex items-center h-full text-muted-foreground left-3" />
              <Input
                name="email"
                value={email}
                placeholder={t("auth.emailPlaceholder")}
                onChange={(e) => setEmail(e.target.value)}
                className="pl-12"
              />
            </div>

            <Button
              disabled={!isValidEmail}
              isLoading={isLoading}
              onClick={() => onSubmit(email)}
            >
              {t("auth.signIn")}
            </Button>
          </div>

          {options?.nonDismissable && (
            <div>
              <Button variant="link" onClick={goBack}>
                Go back
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
