"use client";

import { cn } from "@/lib/utils";
import { Switch, Tooltip } from "@components/ui";
import { IconLayoutGrid, IconMap2 } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";

interface Props {
  isMapView: boolean;
  onToggle: () => void;
}

export const MapViewSwitcher = ({ isMapView, onToggle }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-2">
      <p className="md:hidden text-muted-foreground">{t("common.viewGrid")}</p>
      <Tooltip tooltip={t("common.viewGrid")} asChild>
        <IconLayoutGrid className={cn(!isMapView && "text-primary")} />
      </Tooltip>

      <Switch checked={isMapView} onCheckedChange={onToggle} />

      <Tooltip tooltip={t("common.viewMap")} asChild>
        <IconMap2 className={cn(isMapView && "text-primary")} />
      </Tooltip>
      <p className="md:hidden text-muted-foreground">{t("common.viewMap")}</p>
    </div>
  );
};
