import { ApiSwrConfig } from "@types";
import { AnyNode, Cheerio } from "cheerio";
import { identity, isNil, omitBy, pickBy } from "lodash";
import { ObjectId } from "mongodb";
import useSWR, { Fetcher, Key } from "swr";

export const fetcher: Fetcher<any, string> = async (...args) => {
  const res = await fetch(...args);
  if (!res.ok) throw new Error("An error occurred while fetching the data.");

  return res.json();
};

export const useApiSWR = <T>(route: Key, config?: ApiSwrConfig<T>) => {
  "use client";

  return useSWR<T>(route, fetcher, config);
};

export const getUrl = (
  url: string,
  id?: string | string[] | null,
  params?: Record<string, any>
) => {
  let result = url;
  if (id) {
    const ids = Array.isArray(id) ? id : [id];
    result += `/${ids.join("/")}`;
  }
  if (params) {
    const cleanParams = omitBy(params, isNil);
    const stringParams = new URLSearchParams(cleanParams).toString();
    result += `?${stringParams}`;
  }
  return result;
};

export const getApiUrl = (
  url: string,
  id?: string | null,
  params?: Record<string, any>
) => {
  let result = id ? url.replace(":id", id) : url;

  if (params) {
    const cleanParams = omitBy(params, isNil);
    const stringParams = new URLSearchParams(cleanParams).toString();
    result += `?${stringParams}`;
  }

  return result;
};

export const getFindFilter = (
  obj: Record<string, string | number | Record<string, any>>
) => pickBy(obj, identity);

export const getPaginationParams = (
  params: Record<string, string | string[] | undefined>
) => {
  const limit = Number(params.limit || "") || 0;
  const page = Number(params.page || "") || 1;

  return [limit, page];
};

export const getSortingParams = (
  params: Record<string, string | string[] | undefined>,
  defaultValue: [string, string] = ["", "asc"]
) => {
  const [defaultSort, defaultOrder] = defaultValue;
  const sort = String(params.sort || defaultSort);
  const sortOrder = sort ? String(params.sortOrder || defaultOrder) : "";

  return [sort, sortOrder];
};

interface Item {
  name: string;
  code?: string;
  countryCode: string;
}

export const toggleObjectIdArray = (value: ObjectId, array: ObjectId[]) => {
  return isObjectIdInArray(value, array)
    ? array?.filter((item) => !compareObjectIds(item, value))
    : array?.concat(...[value]);
};

const compareObjectIds = (val1: ObjectId, val2: ObjectId) =>
  val1.toString() === val2.toString();

const isObjectIdInArray = (value: ObjectId, array?: ObjectId[]) =>
  array?.some((item) => compareObjectIds(item, value));

/**
 * Scraping
 */

export const mergeNumbeoArrays = (
  arr1: Partial<Item>[],
  arr2: Partial<Item>[]
) => {
  return [...arr1, ...arr2].reduce((prev, next) => {
    const findIndex = prev.findIndex(
      (item) => item.name === next.name && item.countryCode === next.countryCode
    );

    if (findIndex >= 0) {
      const filteredArr = prev.filter((_, i) => i !== findIndex);
      return [...filteredArr, { ...prev[findIndex], ...next }];
    }

    return [...prev, next];
  }, [] as Partial<Item>[]);
};

export const getCheerioText = (element: Cheerio<AnyNode>, selector: string) => {
  return element.find(selector).text().trim();
};
