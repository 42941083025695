import * as Icons from "@tabler/icons-react";
import { type Icon as TablerIcon } from "@tabler/icons-react";
import {
  ICostFeedbackItem,
  IFeedbackItem,
  ISuggestionItem,
  IWorkFriendlyPlace,
} from "@types";

export const getFeedbackIcon = (
  value: IFeedbackItem["category"]
): TablerIcon => {
  switch (value) {
    case "suggestion":
      return Icons.IconMessageCircle;
    case "bug":
      return Icons.IconBug;
    case "error":
      return Icons.IconExclamationCircle;
    case "other":
      return Icons.IconMessageCircle;
    default:
      return Icons.IconQuestionMark;
  }
};

export const getSuggestionIcon = (
  value: ISuggestionItem["category"]
): TablerIcon => {
  switch (value) {
    case "wfp":
      return Icons.IconDeviceLaptop;
    default:
      return Icons.IconMessageCircle;
  }
};

export const getWfpTypeIcon = (
  value: IWorkFriendlyPlace["type"]
): TablerIcon | undefined => {
  switch (value) {
    case "bar":
      return Icons.IconBeer;
    case "cafe":
      return Icons.IconCoffee;
    case "hotel":
      return Icons.IconBuildingSkyscraper;
    case "library":
      return Icons.IconBooks;
    case "restaurant":
      return Icons.IconChefHat;
    default:
      return undefined;
  }
};

export const getCurrencyIcon = (code?: string): TablerIcon | undefined => {
  if (!code) return undefined;
  switch (code.toLowerCase()) {
    case "eur":
      return Icons.IconCurrencyEuro;
    case "usd":
      return Icons.IconCurrencyDollar;
    case "cad":
      return Icons.IconCurrencyDollarCanadian;
    case "chf":
      return Icons.IconCurrencyFrank;
    case "nok":
      return Icons.IconCurrencyKroneSwedish;
    case "sek":
      return Icons.IconCurrencyKroneSwedish;
    case "pln":
      return Icons.IconCurrencyZloty;
    default:
      return undefined;
  }
};

export const getTemperatureIcon = (code: string): TablerIcon | undefined => {
  switch (code.toLowerCase()) {
    case "c":
      return Icons.IconTemperatureCelsius;
    case "f":
      return Icons.IconTemperatureFahrenheit;
    default:
      return undefined;
  }
};

export const getCostFeedbackIcon = (
  value: keyof ICostFeedbackItem
): TablerIcon => {
  switch (value) {
    case "beer":
      return Icons.IconBeer;
    case "coffee":
      return Icons.IconCoffee;
    case "dinner":
      return Icons.IconToolsKitchen;
    case "fastFood":
      return Icons.IconBrandMcdonalds;
    case "transportation":
      return Icons.IconBus;
    case "movieTicket":
      return Icons.IconTicket;
    case "gym":
      return Icons.IconBarbell;
    case "hotel":
      return Icons.IconHotelService;
    case "airbnb":
      return Icons.IconBrandAirbnb;
    case "hostel":
      return Icons.IconBed;
    case "rentSmallCityCenter":
    case "rentSmallOutsideCenter":
    case "rentMediumCityCenter":
    case "rentMediumOutsideCenter":
      return Icons.IconHomeDollar;
    case "utilitiesSmall":
    case "utilitiesMedium":
      return Icons.IconHomeBolt;
    default:
      return Icons.IconMessageCircle;
  }
};
