import countryList from "@/data/countries.json";
import languageList from "@/data/languages.json";
import { CountryIcon } from "@components/country";
import apiRoutes from "@constants/api-routes";
import routes from "@constants/routes";
import { IconCheck, IconX } from "@tabler/icons-react";
import {
  IAdminLog,
  ICity,
  ICountry,
  IExchangeRate,
  IFilter,
  ISelectOption,
  IUserSettingTemperature,
  WfpGrade,
} from "@types";
import axios from "axios";
import { i18n } from "next-i18next";
import { getApiUrl, getUrl } from "./api";
import { getCityLink, getCountryLink } from "./routes";

export const getLabelByValue = (
  list: ISelectOption[],
  selectedValue?: string
): string | undefined => {
  // Find the single object in the list that matches the selected value
  const match = list.find((item) => item.value === selectedValue);

  // Return the label of the matching item, or undefined if not found
  return match ? match.label : undefined;
};

export const getSelectedValuesByValue = (
  list: ISelectOption[],
  selectedValues: string[] = []
) => {
  const match = list.filter((item) => selectedValues.includes(item.value));

  // Return the labels of the matching items joined by commas, or undefined if no matches
  return match.length > 0 ? match : undefined;
};

export const parseCountrySelectOptions = (
  list: ISelectOption[],
  selectedValues?: string[]
): ISelectOption[] => {
  return list.map((item) => ({
    ...item,
    icon: item?.value ? <CountryIcon code={String(item.value)} /> : undefined,
  }));
};

export const parseCountryName = (code?: string) =>
  countryList.find((item) => item.code === code)?.name || "";

export const parseLanguageName = (code?: string) =>
  languageList.find((item) => item.code === code)?.name || "";

export const parseRemoveParenthesis = (value: string) =>
  value.replace(/ *\([^)]*\) */g, "");

export const parseBooleanToText = (value?: boolean) => {
  if (!value) return "";
  return value ? i18n?.t("actions.yes") : i18n?.t("actions.no");
};

export const parsePopulationNumber = (
  value?: number,
  isLongFormat?: boolean
) => {
  if (!value) return "";
  if (isLongFormat) return value.toLocaleString();

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => value >= item.value);

  return item
    ? (value / item.value).toFixed(value > 1e6 ? 1 : 0).replace(rx, "$1") +
        item.symbol
    : "0";
};

export const parseCurrency = (value?: number, currency?: string) => {
  const code = currency?.toUpperCase() || "";
  return parseMoneyAmount(value, { code, value: 1 });
};

export const parseMoneyAmount = (
  value?: number,
  exchangeRate?: IExchangeRate
) => {
  if (!value) return "";

  const factor = exchangeRate?.value || 1;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: exchangeRate?.code || "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(value * factor);
};

export const parseMonthlyMoneyAmount = (
  value?: number,
  exchangeRate?: IExchangeRate
) => {
  if (!value) return "";

  const formatted = parseMoneyAmount(value, exchangeRate);
  const suffix = ` / ${i18n?.t("common.month")}`;
  return `${formatted} ${suffix.toLowerCase()}`;
};

export const parseTemperature = (
  value?: number,
  setting?: IUserSettingTemperature
) => {
  if (!value) return "";
  const formattedValue = setting === "f" ? (value * 9) / 5 + 32 : value;
  const format = `°${setting?.toUpperCase() || "C"}`;
  return `${Math.round(formattedValue)} ${format}`;
};

export const parseSpeed = (value?: number) => {
  if (!value) return "";
  return `${value.toLocaleString()} km/h`;
};

export const parseAreaNumber = (value?: number) => {
  if (!value) return "";
  return `${value.toLocaleString()} km²`;
};

export const parseDensityNumber = (
  populationValue?: number,
  areaValue?: number
) => {
  if (!populationValue || !areaValue) return "";
  const value = Math.round(populationValue / areaValue);
  return `${value.toLocaleString()} / km²`;
};

export const parseDistance = (value?: number) => {
  if (!value) return "";
  return `${value.toLocaleString()}km`;
};

export const parsePercentNumber = (value: number | null = 0, decimals = 2) => {
  if (value === null) return "N/A";
  return `${value.toFixed(decimals)}%`;
};

export const parseLifeExpectancy = (
  value?: number,
  isUnit: boolean = false
) => {
  if (!value) return "";
  const formatted = value.toFixed(2);
  return isUnit ? `${formatted} ${i18n?.t("common.years")}` : formatted;
};

export const parseHoursPerYear = (value?: number) => {
  if (!value) return "";
  return `${value}${i18n?.t("common.hoursPerYear")}`;
};

export const parseMmPerYear = (value?: number) => {
  if (!value) return "";
  return `${value}${i18n?.t("common.mmPerYear")}`;
};

export const parseSunlightHoursToGrade = (value?: number) => {
  if (!value) return;
  const thresholds = [1200, 1500, 1800, 2100, 2400, 2700, 3000, 3250, 3500];
  const index = thresholds.findIndex((item) => value < item);
  return index >= 0 ? index + 1 : 10;
};

export const parseRainfallToGrade = (value?: number) => {
  if (!value) return;
  const thresholds = [125, 250, 375, 500, 750, 1000, 1500, 2000, 2500];
  const index = thresholds.findIndex((item) => value < item);
  return index >= 0 ? index + 1 : 10;
};

export const parseAirQualityToGrade = (value?: number) => {
  if (!value) return;
  const thresholds = [0, 16, 32, 48, 64, 80, 96, 116, 146, 170];
  const index = thresholds.findIndex(
    (item, i, arr) =>
      value >= item && (i === arr.length - 1 || value < arr[i + 1])
  );

  return 10 - index;
};

export const parseFullName = (
  firstName?: string | null,
  lastName?: string | null
) => {
  const first = firstName || "";
  const last = lastName || "";

  return first || last ? `${first} ${last}` : "";
};

export const parseAgeFromBirthday = (value?: string) => {
  if (!value) return "";
  const currentDate = new Date();
  const birthDate = new Date(value);

  // Calculate the difference in years
  let age: number = currentDate.getFullYear() - birthDate.getFullYear();

  // Adjust age based on month and day
  const currentMonth = currentDate.getMonth() + 1;
  const birthMonth = birthDate.getMonth() + 1;

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return `${age} years old`;
};

export const parseTableValueBoolean = (value: boolean) => {
  return value === true ? <IconCheck /> : value === false ? <IconX /> : null;
};

enum GradeText {
  "veryLow" = 1,
  "low" = 3,
  "moderate" = 5,
  "high" = 7,
  "veryHigh" = 9,
}

// Helper function to map the input value to the corresponding grade
const mapValueToGrade = (value: number) => {
  if (value < GradeText.low) return GradeText.veryLow;
  if (value < GradeText.moderate) return GradeText.low;
  if (value < GradeText.high) return GradeText.moderate;
  if (value < GradeText.veryHigh) return GradeText.high;
  return GradeText.veryHigh;
};

export const parseGradeToText = (value: number) => {
  if (!value) return "";
  const grade = mapValueToGrade(value);
  return i18n?.t(`grades.${GradeText[grade]}`);
};

// Returns the corresponding color for a given grade 1 to 10
export const parseGradeToColor = (
  value?: number,
  order: "asc" | "desc" = "asc"
) => {
  if (!value) return undefined;

  const floor = Math.floor((value - 1) / 2);
  const index = order === "desc" ? 4 - floor : floor;

  return colorMappingClasses[index];
};

const colorMappingClasses = [
  "text-rose-700 dark:text-rose-500", // red
  "text-orange-600 dark:text-orange-500",
  "text-amber-600 dark:text-amber-500",
  "text-lime-600 dark:text-lime-500",
  "text-green-700 dark:text-green-500", // green
];

// Returns the corresponding className for a given grade 1 to 10
export const parseGradeToClass = (
  value?: number,
  order: "asc" | "desc" = "asc"
) => {
  if (!value) return undefined;

  const floor = Math.floor((value - 1) / 2);
  const index = order === "desc" ? 4 - floor : floor;

  return colorMappingClasses[index];
};

export const parseRelativeDate = (dateString?: string) => {
  if (!dateString) return "";

  const locale = i18n?.language || "en";
  const date = new Date(dateString);
  const now = new Date();
  const diffMs = now.getTime() - date.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });

  // Format the relative time
  if (diffMinutes <= 1) {
    return "just now";
  } else if (diffMinutes < 60) {
    return formatter.format(-diffMinutes, "minute");
  } else if (diffMinutes < 1440) {
    const diffHours = Math.floor(diffMinutes / 60);
    return formatter.format(-diffHours, "hour");
  } else if (diffMinutes < 43200) {
    const diffDays = Math.floor(diffMinutes / 1440);
    return formatter.format(-diffDays, "day");
  } else if (diffMinutes < 525600) {
    const diffMonths = Math.floor(diffMinutes / 43200);
    return formatter.format(-diffMonths, "month");
  } else {
    const diffYears = Math.floor(diffMinutes / 525600);
    return formatter.format(-diffYears, "year");
  }
};

export const parseWfpIconColor = (grade?: WfpGrade): string => {
  switch (grade) {
    case "bad":
      return "red.500";
    case "medium":
      return "yellow.500";
    case "good":
      return "green.400";
    default:
      return "gray";
  }
};

export const parseAdminLogItemLink = (item: IAdminLog) => {
  let link = "";

  switch (item.entity) {
    case "cities":
      link = getCityLink(String(item.entityId));
      break;
    case "countries":
      link = getCountryLink(String(item.entityId));
      break;
    case "users":
      link = getUrl(routes.user, String(item.entityId));
      break;
    case "wfps":
      link = getUrl(routes.adminWfp);
      break;
    case "advancedStats":
      link = getCityLink(String(item.entityId));
      break;
    default:
      break;
  }

  return link;
};

export const parseHighlightSearchTerm = (haystack: string, needle: string) => {
  const regex = new RegExp(needle, "i");
  return haystack.replace(regex, (match) => `<strong>${match}</strong>`);
};

export const parseCityOptionsAsync = async (
  name: string,
  params?: any
): Promise<ISelectOption[]> => {
  const url = getApiUrl(apiRoutes.citySearch, null, { name, ...params });
  const request = await axios.get(url);
  const res = request.data as ICity[];
  return res.map((item) => ({
    value: item._id,
    label: item.name,
    icon: <CountryIcon code={item.countryCode} />,
  }));
};

export const parseCityOptionAsync = async (
  id: string
): Promise<ISelectOption> => {
  const url = getApiUrl(apiRoutes.cityId, id);
  const request = await axios.get(url);
  const res = request.data as ICity;
  return {
    value: res._id,
    label: res.name,
    icon: <CountryIcon code={res.countryCode} />,
  };
};

export const parseCountryOptionsAsync = async (
  name: string,
  params?: any
): Promise<ISelectOption[]> => {
  const url = getApiUrl(apiRoutes.countrySearch, null, { name, ...params });
  const request = await axios.get(url);
  const res = request.data as ICountry[];
  return res.map((item) => ({
    value: item.code,
    label: item.name,
    icon: <CountryIcon code={item.code} />,
  }));
};

export const parseCountrySelectedOption = (
  value?: string
): ISelectOption | undefined => {
  if (!value) return;
  return {
    icon: <CountryIcon code={value} />,
    label: parseCountryName(value),
    value,
  };
};

export const parseCitySelectedOption = (
  label: string,
  value?: string,
  code?: string
): ISelectOption | undefined => {
  if (!value) return;

  return {
    icon: <CountryIcon code={code} />,
    label,
    value,
  };
};

// Parse an array of filters into a key-value record
export const parseFilters = (filters: IFilter[]): Record<string, string> => {
  return filters.reduce((obj, item) => {
    obj[item.key]
      ? (obj[item.key] += "," + item.value)
      : (obj[item.key] = item.value);

    return obj;
  }, {} as Record<string, string>);
};

export const parseSortBy = (
  sort: string,
  sortOrder: "asc" | "desc"
): Record<string, string> => {
  if (!sort) return {};
  return { sort, sortOrder };
};
