import { cn } from "@/lib/utils";
import { Badge, Tooltip } from "@components/ui";
import { IconShieldCheck } from "@tabler/icons-react";
import { IUser } from "@types";
import { getUserLink } from "@utils/routes";
import Link from "next/link";
import { UserAvatar } from "./user-avatar";

interface UserTagProps extends React.HTMLAttributes<HTMLParagraphElement> {
  data: IUser;
  overrideLink?: string;
  size?: "sm";
}

export const UserTag = ({
  data,
  overrideLink,
  size,
  ...rest
}: UserTagProps) => {
  const isAdmin = data.role === "admin";

  return (
    <Link href={overrideLink || getUserLink(data._id)}>
      <Badge
        className={cn(
          "flex items-center w-fit gap-1",
          size === "sm" && "px-2 py-0"
        )}
        variant="secondary"
        size="lg"
      >
        <UserAvatar
          data={data}
          className={cn("mr-1", size === "sm" ? "w-5 h-5" : "w-6 h-6")}
        />
        <p
          {...rest}
          className={cn("truncate max-w-44 font-normal", rest.className)}
        >
          {data.firstName}
        </p>

        {isAdmin && (
          <Tooltip tooltip="Admin">
            <IconShieldCheck size={18} />
          </Tooltip>
        )}
      </Badge>
    </Link>
  );
};
