import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
} from "@components/ui";
import { useTranslation } from "next-i18next";
import { useModal } from "./modal-provider";

export const AlertModal = () => {
  const { t } = useTranslation();
  const {
    currentModal: { options },
    closeModal,
  } = useModal();

  return (
    <AlertDialog open onOpenChange={closeModal}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {options?.title || t("alerts.areYouSureText")}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t("alerts.youCantUndoText")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button variant="outline">{t("actions.cancel")}</Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button onClick={options?.onSubmit}>{t("actions.confirm")}</Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
