import axios, { AxiosError } from "axios";
import { useState } from "react";
import { toast } from "sonner";

interface ToastOptions {
  errorMessage: string;
  successMessage: string;
}

export const useDeleteRequest = <T>() => {
  const [isLoading, setIsLoading] = useState(false);

  const trigger = async (
    url: string,
    toastOptions?: ToastOptions
  ): Promise<T> => {
    try {
      setIsLoading(true);
      const res = await axios.delete(url);

      toastOptions && toast.success(toastOptions.successMessage);
      return res.data;
    } catch (error) {
      toastOptions && toast.error(toastOptions.errorMessage);
      throw error as AxiosError;
    } finally {
      setIsLoading(false);
    }
  };

  return { trigger, isLoading };
};
