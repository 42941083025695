"use client";

import { DataPillWithIcon } from "@components/cards";
import { PercentTextColored } from "@components/common";
import { useUserSettings } from "@hooks";
import {
  IconCar,
  IconCash,
  IconChartArea,
  IconChartHistogram,
  IconHeart,
  IconMap2,
  IconMoneybag,
  IconUsers,
} from "@tabler/icons-react";
import { ICountry } from "@types";
import {
  parseAreaNumber,
  parseDensityNumber,
  parseMoneyAmount,
  parsePopulationNumber,
} from "@utils/parse";
import { useTranslation } from "next-i18next";

export const CountryDataPillsSection = ({ data }: { data: ICountry }) => {
  const { t } = useTranslation();
  const { exchangeRate } = useUserSettings();

  return (
    <div className="grid w-full h-full gap-4 md:grid-cols-4 lg:grid-cols-5">
      {data?.qualityOfLife && (
        <DataPillWithIcon title={t("stats.qualityOfLife")} Icon={IconHeart}>
          <div className="flex items-baseline gap-1">
            <p className="text-3xl font-bold">{data.qualityOfLife}</p>
            <p className="text-muted-foreground">/10</p>
          </div>
        </DataPillWithIcon>
      )}
      {data?.costOfLife && (
        <DataPillWithIcon
          title={t("stats.costOfLife")}
          Icon={IconCash}
          className="min-w-40"
        >
          <div className="flex items-baseline gap-1">
            <p className="text-3xl font-bold">{data.costOfLife}</p>
            <p className="text-muted-foreground">/10</p>
          </div>
        </DataPillWithIcon>
      )}
      {data?.avgSalary && (
        <DataPillWithIcon
          title={t("stats.avgSalary")}
          Icon={IconMoneybag}
          value={parseMoneyAmount(data.avgSalary, exchangeRate)}
        />
      )}
      <DataPillWithIcon
        title={t("pages.country.currency")}
        Icon={IconCash}
        value={data?.currency}
      />
      <DataPillWithIcon
        title={t("pages.country.capital")}
        Icon={IconMap2}
        value={data?.capital}
      />
      <div className="flex w-full col-span-3 gap-4">
        <DataPillWithIcon
          title={t("stats.population")}
          Icon={IconUsers}
          className="w-full"
        >
          <div className="z-10 flex items-baseline gap-6">
            <p className="text-3xl font-bold">
              {parsePopulationNumber(data?.population, true)}
            </p>

            {data?.populationGrowth && (
              <PercentTextColored
                className="text-lg font-semibold"
                value={data.populationGrowth}
              />
            )}
          </div>
        </DataPillWithIcon>
        <DataPillWithIcon
          title={t("pages.country.area")}
          Icon={IconChartArea}
          value={parseAreaNumber(data?.area)}
          className="w-full"
        />
      </div>
      <DataPillWithIcon
        title={t("stats.density")}
        Icon={IconChartHistogram}
        value={parseDensityNumber(data?.population, data?.area)}
      />
      <DataPillWithIcon
        title={t("pages.country.carSide")}
        Icon={IconCar}
        value={data?.carSide}
        className="capitalize"
      />
    </div>
  );
};
