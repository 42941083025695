import { cn } from "@/lib/utils";
import { Card } from "@components/ui";

interface Props {
  className?: string;
}

export const MapWrapper = ({
  children,
  className,
}: React.PropsWithChildren<Props>) => (
  <Card className={cn("box-content h-40 overflow-hidden", className)}>
    {children}
  </Card>
);
