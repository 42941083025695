"use client";

import { Button } from "@components/ui";
import routes from "@constants/routes";
import { IconHome } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";

export const PageError = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="flex flex-col gap-2 px-6 py-10 text-center">
        <h2 className="mt-6 text-4xl font-bold">
          {t("pages.error.404.title", "Page Not Found")}
        </h2>
        <p className="text-muted-foreground">{t("pages.error.404.subtitle")}</p>

        <Link href={routes.home}>
          <Button variant="outline" className="mt-2">
            <IconHome />
            {t("actions.goHome")}
          </Button>
        </Link>
      </div>
    </div>
  );
};
