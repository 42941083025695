import React, { createContext, useContext, useState } from "react";

interface SubfooterContextType {
  footerContent: React.ReactNode;
  setFooterContent: (content: React.ReactNode) => void;
}

const SubfooterContext = createContext<SubfooterContextType | undefined>(
  undefined
);

interface FooterProviderProps {
  children: React.ReactNode;
}

export const SubfooterProvider: React.FC<FooterProviderProps> = ({
  children,
}) => {
  const [content, setContent] = useState<React.ReactNode>(null);

  return (
    <SubfooterContext.Provider
      value={{ footerContent: content, setFooterContent: setContent }}
    >
      {children}
    </SubfooterContext.Provider>
  );
};

export const useSubfooter = (): SubfooterContextType => {
  const context = useContext(SubfooterContext);
  if (!context) {
    throw new Error("useSubfooter must be used within a SubfooterProvider");
  }
  return context;
};
