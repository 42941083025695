import apiRoutes from "@constants/api-routes";
import { IWeatherResponse } from "@types";
import { fetcher, getApiUrl } from "@utils/api";
import useSWR from "swr";

export const useWeatherFetch = (lat?: number, lon?: number) => {
  return useSWR<IWeatherResponse>(
    lat && lon ? getApiUrl(apiRoutes.weather, null, { lat, lon }) : null,
    fetcher
  );
};
