import Link from "next/link";

type Props = Omit<React.ComponentProps<typeof Link>, "href"> &
  React.PropsWithChildren & { href?: string };

export const OptionalLink = ({ href, children, ...rest }: Props) => {
  return href ? (
    <Link href={href} {...rest}>
      <div>{children}</div>
    </Link>
  ) : (
    <div>{children}</div>
  );
};
