import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "@components/ui";
import apiRoutes from "@constants/api-routes";
import { valuesHelpOutCategoryOptions } from "@constants/form-values";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePostRequest } from "@hooks/use-post-request";
import { IconSend, IconX } from "@tabler/icons-react";
import { IFeedbackItem } from "@types";
import { getApiUrl } from "@utils/api";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useModal } from "./modal-provider";

const formSchema = z.object({
  category: z.string(),
  description: z.string(),
});

export const FeedbackModal = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const insert = usePostRequest<IFeedbackItem>(getApiUrl(apiRoutes.feedback));
  const form = useForm<z.infer<typeof formSchema>>({
    mode: "all",
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await insert.trigger(values as IFeedbackItem, {
        successMessage: t("pages.feedback.notifications.created"),
        errorMessage: t("pages.feedback.notifications.notCreated"),
      });
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent>
        <Form {...form}>
          <form
            className="flex flex-col gap-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <DialogHeader>
              <DialogTitle>{t("pages.feedback.modalTitle")}</DialogTitle>
            </DialogHeader>
            <div className="flex flex-col gap-4">
              {/* Category field */}
              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t(
                              "pages.feedback.categoryPlaceholder"
                            )}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {valuesHelpOutCategoryOptions.map((item) => (
                          <SelectItem key={item.value} value={item.value}>
                            <div className="flex items-center gap-2">
                              {item.icon}
                              {t(item.label)}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      {t("pages.feedback.categoryDescription")}
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Description field */}
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Textarea
                        className="resize-none"
                        placeholder={t("pages.feedback.feedbackPlaceholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      {t("pages.feedback.feedbackDescription")}
                    </FormDescription>
                    <FormMessage translate="yes" />
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter>
              <div className="flex justify-end gap-2">
                <Button variant="ghost" size="sm" onClick={closeModal}>
                  <IconX size={20} />
                  {t("actions.cancel")}
                </Button>
                <Button
                  type="submit"
                  isLoading={insert.isLoading}
                  size="sm"
                  disabled={!form.formState.isValid}
                >
                  <IconSend size={20} />
                  {t("actions.send")}
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
