import { useSession } from "next-auth/react";

export const useAuth = () => {
  const { status, data, update } = useSession();

  return {
    data,
    update,
    userId: data?.user?.id,
    isLoading: status === "loading",
    isLogged: status === "authenticated",
    isAdmin: data?.user?.role === "admin",
  };
};
