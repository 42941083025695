"use client";

import { OptionalLink } from "@components/common";
import {
  Button,
  Combobox,
  ComboboxMulti,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Textarea,
  Tooltip,
} from "@components/ui";
import apiRoutes from "@constants/api-routes";
import { valuesCityTags, valuesContinentOptions } from "@constants/form-values";
import { usePostRequest } from "@hooks/index";
import { IconDeviceFloppy, IconPhoto, IconX } from "@tabler/icons-react";
import { ICity } from "@types";
import { getApiUrl } from "@utils/api";
import { isEmpty } from "lodash";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useState } from "react";
import { useModal } from "./modal-provider";

export const EditCityModal = () => {
  const { t } = useTranslation();
  const [modified, setModified] = useState<Partial<ICity>>({});
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const data = options?.data as ICity;
  const isCreate = options?.mode === "create";
  const update = usePostRequest<ICity>(
    getApiUrl(isCreate ? apiRoutes.city : apiRoutes.cityId, data?._id)
  );

  const onUpdate = async () => {
    try {
      if (!modified) return;
      const name = modified?.name ?? data?.name;
      const okKey = isCreate ? "created" : "updated";
      const errorKey = isCreate ? "notCreated" : "notUpdated";

      await update.trigger(modified, {
        successMessage: t(`pages.admin.notifications.${okKey}`, { name }),
        errorMessage: t(`pages.admin.notifications.${errorKey}`, { name }),
      });

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const onFieldChange = async (value: Partial<ICity>) => {
    setModified((prev) => ({ ...prev, ...value }));
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className="max-w-xl max-h-screen py-10 overflow-y-auto">
        <DialogHeader>
          <DialogTitle>
            {t(isCreate ? "pages.admin.createCity" : "pages.admin.editCity")}
          </DialogTitle>
        </DialogHeader>
        <Tabs defaultValue="information">
          <TabsList className="grid w-full grid-cols-2 mb-4">
            <TabsTrigger value="information">Information</TabsTrigger>
            <TabsTrigger value="details">Details</TabsTrigger>
          </TabsList>
          <TabsContent value="information">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-4 md:flex-row">
                {/* Name field */}
                <div className="flex flex-col w-full gap-1 md:w-1/2">
                  <Label>{t("pages.city.name")}</Label>
                  <Input
                    name="name"
                    value={modified?.name ?? data?.name}
                    onChange={(e) => onFieldChange({ name: e.target.value })}
                  />
                </div>
                {/* Population field */}
                <div className="flex flex-col w-full gap-1 md:w-1/4">
                  <Label>{t("pages.city.population")}</Label>
                  <Input
                    name="population"
                    type="number"
                    value={modified?.population ?? data?.population}
                    onChange={(e) =>
                      onFieldChange({ population: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Popularity field */}
                <div className="flex flex-col w-full gap-1 md:w-1/4">
                  <Label>{t("pages.city.popularity")}</Label>
                  <Input
                    name="popularity"
                    value={modified?.popularity ?? data?.popularity}
                    onChange={(e) =>
                      onFieldChange({ popularity: Number(e.target.value) })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 md:flex-row">
                {/* Continent field */}
                <div className="flex flex-col gap-1 w-full md:w-[30%]">
                  <Label>{t("pages.city.continent")}</Label>
                  <Combobox
                    disableSearch
                    disableSorting
                    options={valuesContinentOptions}
                    value={modified?.continent ?? data?.continent}
                    onChange={(continent) => onFieldChange({ continent })}
                  />
                </div>
                {/* Country field */}
                <div className="flex flex-col w-full gap-1 md:w-2/5">
                  <Label>{t("pages.city.country")}</Label>
                  <Input
                    name="country"
                    value={modified?.country ?? data?.country}
                    onChange={(e) => onFieldChange({ country: e.target.value })}
                  />
                </div>
                {/* Country code field */}
                <div className="flex flex-col gap-1 w-full md:w-[30%]">
                  <Label>{t("pages.city.countryCode")}</Label>
                  <Input
                    name="countryCode"
                    value={modified?.countryCode ?? data?.countryCode}
                    onChange={(e) =>
                      onFieldChange({ countryCode: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="grid-dynamic-tighter">
                {/* Cost of living field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.costOfLife")}</Label>
                  <Input
                    name="costOfLife"
                    type="number"
                    value={modified?.costOfLife ?? data?.costOfLife}
                    onChange={(e) =>
                      onFieldChange({ costOfLife: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Quality of life field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.qualityOfLife")}</Label>
                  <Input
                    name="qualityOfLife"
                    type="number"
                    value={modified?.qualityOfLife ?? data?.qualityOfLife}
                    onChange={(e) =>
                      onFieldChange({ qualityOfLife: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Cost of restaurants field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.costOfRestaurants")}</Label>
                  <Input
                    name="costOfRestaurants"
                    type="number"
                    value={
                      modified?.costOfRestaurants ?? data?.costOfRestaurants
                    }
                    onChange={(e) =>
                      onFieldChange({
                        costOfRestaurants: Number(e.target.value),
                      })
                    }
                  />
                </div>
                {/* Cost of groceries field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.costOfGroceries")}</Label>
                  <Input
                    name="costOfGroceries"
                    type="number"
                    value={modified?.costOfGroceries ?? data?.costOfGroceries}
                    onChange={(e) =>
                      onFieldChange({ costOfGroceries: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Purchasing power field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.purchasingPower")}</Label>
                  <Input
                    name="purchasingPower"
                    type="number"
                    value={modified?.purchasingPower ?? data?.purchasingPower}
                    onChange={(e) =>
                      onFieldChange({ purchasingPower: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Avg salary field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.avgSalary")}</Label>
                  <Input
                    name="avgSalary"
                    type="number"
                    value={modified?.avgSalary ?? data?.avgSalary}
                    onChange={(e) =>
                      onFieldChange({ avgSalary: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Crime rate field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.crimeRate")}</Label>
                  <Input
                    name="crimeRate"
                    type="number"
                    value={modified?.crimeRate ?? data?.crimeRate}
                    onChange={(e) =>
                      onFieldChange({ crimeRate: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Pollution rate field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.pollutionRate")}</Label>
                  <Input
                    name="pollutionRate"
                    type="number"
                    value={modified?.pollutionRate ?? data?.pollutionRate}
                    onChange={(e) =>
                      onFieldChange({ pollutionRate: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Healthcare rate field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.healthcareRate")}</Label>
                  <Input
                    name="healthcareRate"
                    type="number"
                    value={modified?.healthcareRate ?? data?.healthcareRate}
                    onChange={(e) =>
                      onFieldChange({ healthcareRate: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Sunlight hours field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.sunlightHours")}</Label>
                  <Input
                    name="sunlightHours"
                    type="number"
                    value={modified?.sunlightHours ?? data?.sunlightHours}
                    onChange={(e) =>
                      onFieldChange({ sunlightHours: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Rainfall field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.rainfall")}</Label>
                  <Input
                    name="rainfall"
                    type="number"
                    value={modified?.rainfall ?? data?.rainfall}
                    onChange={(e) =>
                      onFieldChange({ rainfall: Number(e.target.value) })
                    }
                  />
                </div>
                {/* Air quality field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.airQuality")}</Label>
                  <Input
                    name="airQuality"
                    type="number"
                    value={modified?.airQuality ?? data?.airQuality}
                    onChange={(e) =>
                      onFieldChange({ airQuality: Number(e.target.value) })
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4">
                {/* Coordinates field */}
                <div className="flex flex-col w-full gap-1">
                  <Label>{t("stats.coordinates")}</Label>
                  <Input
                    name="coordinates"
                    value={
                      modified?.location?.coordinates.join() ??
                      data?.location?.coordinates.join()
                    }
                    onChange={(e) => {
                      const coordinates = e.target.value.split(",").map(Number);
                      onFieldChange({
                        location: { type: "Point", coordinates },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="details">
            <div className="flex flex-col gap-4">
              {/* Image field */}
              <div className="flex flex-col w-full gap-1">
                <Label>{t("pages.city.image")}</Label>
                <div className="flex gap-2">
                  <Input
                    name="image"
                    value={modified?.image ?? data?.image}
                    onChange={(e) => onFieldChange({ image: e.target.value })}
                  />

                  <Tooltip
                    side="bottom"
                    tooltip={
                      <Image
                        alt="City image"
                        src={modified?.image || data?.image || ""}
                        height={250}
                        width={250}
                      />
                    }
                  >
                    <OptionalLink
                      href={modified?.image ?? data?.image}
                      target="_blank"
                      passHref
                    >
                      <Button variant="outline" size="icon">
                        <IconPhoto size={22} />
                      </Button>
                    </OptionalLink>
                  </Tooltip>
                </div>
              </div>

              {/* Tags field */}
              <div className="flex flex-col w-full gap-1">
                <Label>Tags</Label>
                <ComboboxMulti
                  options={valuesCityTags}
                  value={modified?.tags ?? data?.tags}
                  onChange={(tags) => {
                    onFieldChange({ tags });
                  }}
                />
              </div>

              {/* Description field */}
              <div className="flex flex-col w-full gap-1">
                <Label>{t("pages.city.description")}</Label>
                <Textarea
                  name="description"
                  rows={5}
                  value={modified?.description ?? data?.description}
                  onChange={(e) =>
                    onFieldChange({ description: e.target.value })
                  }
                />
              </div>
            </div>
          </TabsContent>
        </Tabs>
        <DialogFooter>
          <div className="flex justify-end gap-2">
            <Button onClick={closeModal} size="sm" variant="outline">
              <IconX size={20} />
              {t("actions.cancel")}
            </Button>
            <Button
              onClick={onUpdate}
              isLoading={update.isLoading}
              size="sm"
              disabled={isEmpty(modified)}
            >
              <IconDeviceFloppy size={20} />
              {t("actions.saveChanges")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
