"use client";

import { cn } from "@/lib/utils";
import { IconCheck } from "@tabler/icons-react";
import * as React from "react";

type StepperContextValue = {
  index: number;
};

const StepperContext = React.createContext<StepperContextValue>(
  {} as StepperContextValue
);

const Stepper = React.forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement> & {
    index: number;
  }
>(({ className, index, ...props }, ref) => (
  <StepperContext.Provider value={{ index }}>
    <ul
      ref={ref}
      className={cn("flex flex-row w-full gap-x-2", className)}
      {...props}
    />
  </StepperContext.Provider>
));
Stepper.displayName = "Stepper";

const Step = React.forwardRef<
  HTMLLIElement,
  React.HTMLAttributes<HTMLLIElement> & {
    index: number;
  }
>(({ className, index, children, ...props }, ref) => {
  const stepperContext = React.useContext(StepperContext);

  return (
    <li
      ref={ref}
      className={cn(
        "flex items-center justify-center gap-x-2 shrink basis-0 group [&:not(:last-child)]:flex-1",
        className
      )}
      {...props}
    >
      <div className="inline-flex items-center justify-center min-w-7 min-h-7">
        <span
          className={cn(
            "flex items-center justify-center flex-shrink-0 text-base font-medium border-2 border-border rounded-full size-8",
            stepperContext.index === index && "border-foreground"
          )}
        >
          {stepperContext.index > index ? <IconCheck size={18} /> : children}
        </span>
      </div>

      <StepDivider />
    </li>
  );
});
Step.displayName = "Step";

const StepDivider = () => (
  <div className="flex-1 w-full h-[2px] mx-2 group-last:hidden bg-muted-foreground/40" />
);

interface UseStepsProps {
  index: number;
  count: number;
}

const useSteps = ({ index, count }: UseStepsProps) => {
  const [activeStep, setActiveStep] = React.useState(index);

  const goToNext = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeStep < count - 1) setActiveStep(activeStep + 1);
  };

  const goToPrevious = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  return { activeStep, goToNext, goToPrevious };
};

export { Step, Stepper, useSteps };
