import { useModal } from "@components/modals";
import apiRoutes from "@constants/api-routes";
import { useMeUser } from "@services";
import { getApiUrl } from "@utils/api";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useAuth } from "./use-auth";
import { usePostRequest } from "./use-post-request";

export const usePayment = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { isLogged, userId } = useAuth();
  const { mutate } = useMeUser();
  const { openModal } = useModal();
  const processPayment = usePostRequest(getApiUrl(apiRoutes.userIdPay, userId));

  const makePayment = async (cost: number) => {
    try {
      if (!isLogged) {
        openModal("auth");
        throw new Error("Not logged in");
      }
      setIsLoading(true);
      const successMessage = t("pages.profile.notifications.balanceUpdated");
      const errorMessage = t("pages.profile.notifications.balanceNotUpdated");
      await processPayment.trigger(
        { amount: cost },
        { successMessage, errorMessage }
      );

      return Promise.resolve();
    } catch (error) {
      throw new Error("Couldn't process payment");
    } finally {
      await mutate();
      setIsLoading(false);
    }
  };

  return { isLoading, makePayment };
};
