import { SubfooterProvider } from "@/hooks";
import { ModalProvider } from "@components/modals";
import { Toaster, TooltipProvider } from "@components/ui";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { ThemeProvider } from "next-themes";
import { ThemeProviderProps } from "next-themes/dist/types";
import { ReactNode } from "react";
import { Wrapper } from "./wrapper";

interface Props {
  session: Session;
  children: ReactNode;
}

export const Providers = ({ session, children }: Props) => {
  const Theme = ThemeProvider as (props: ThemeProviderProps) => JSX.Element;

  return (
    <SessionProvider session={session}>
      <Theme attribute="class">
        <TooltipProvider delayDuration={0}>
          <ModalProvider>
            <SubfooterProvider>
              <Wrapper>{children}</Wrapper>
              <Toaster />
            </SubfooterProvider>
          </ModalProvider>
        </TooltipProvider>
      </Theme>
    </SessionProvider>
  );
};
