import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, ICity } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";

export const useCity = (
  cityId?: string,
  config?: ApiSwrConfig<ICity>,
  overrideRoute?: string
) => {
  const route =
    overrideRoute ?? cityId ? getApiUrl(apiRoutes.cityId, cityId) : null;
  return useApiSWR<ICity>(route, config);
};
