import { CountryIcon } from "@components/country";
import { Button } from "@components/ui";
import { useMap } from "@hooks/use-map";
import { IconMapPin, IconNavigation } from "@tabler/icons-react";
import { ICity, IMap } from "@types";
import { getCoordsFromCity } from "@utils/city";
import { getCityLink } from "@utils/routes";
import type { LngLatBoundsLike } from "mapbox-gl";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useCallback, useEffect } from "react";
import { Marker } from "react-map-gl";
import { Mapbox } from "./mapbox";

interface Props extends IMap {
  cities?: ICity[];
  hasRecenterBtn?: boolean;
}

export const CitiesMap = ({
  cities,
  lat,
  lon,
  hasRecenterBtn = true,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const [map] = useMap();

  // Set bounding box
  const onFitBoundingBox = useCallback(() => {
    if (!cities?.length) return;

    let minLat = Infinity;
    let minLon = Infinity;
    let maxLat = -Infinity;
    let maxLon = -Infinity;

    cities.forEach((city) => {
      const { lon, lat } = getCoordsFromCity(city);
      if (lon) {
        minLon = Math.min(minLon, Number(lon));
        maxLon = Math.max(maxLon, Number(lon));
      }
      if (lat) {
        minLat = Math.min(minLat, Number(lat));
        maxLat = Math.max(maxLat, Number(lat));
      }
    });

    const bounds = [
      [minLon, minLat],
      [maxLon, maxLat],
    ] as LngLatBoundsLike;

    map.fitBounds(bounds, { animate: true, padding: 40 });
  }, [cities, map]);

  useEffect(() => {
    if (!map) return;
    onFitBoundingBox();
  }, [map, onFitBoundingBox]);

  return (
    <Mapbox
      lat={lat ?? 0}
      lon={lon ?? 0}
      zoom={2}
      maxZoom={8}
      dragPan
      scrollZoom
      {...rest}
    >
      {hasRecenterBtn && (
        <Button
          className="absolute flex items-center bg-background/40 left-5 top-5"
          variant="ghost"
          size="sm"
          onClick={onFitBoundingBox}
        >
          <IconNavigation size={16} />
          <p>{t("actions.recenter")}</p>
        </Button>
      )}

      {lat && lon && (
        <Marker
          latitude={lat}
          longitude={lon}
          anchor="bottom"
          style={{ cursor: "pointer" }}
        >
          <IconMapPin />
        </Marker>
      )}

      {cities?.map((item) => {
        const { lon, lat } = getCoordsFromCity(item);
        if (!lon || !lat) return null;

        return (
          <Marker
            key={item._id}
            longitude={lon}
            latitude={lat}
            anchor="bottom"
            style={{ cursor: "pointer" }}
          >
            <Link href={getCityLink(item._id)}>
              <div className="flex items-center gap-2 px-2 py-0.5 rounded-xl bg-background/40 hover:bg-background">
                <CountryIcon className="w-3 h-3" code={item.countryCode} />
                <p className="text-sm font-medium tracking-tight">
                  {item.name}
                </p>
              </div>
            </Link>
          </Marker>
        );
      })}
    </Mapbox>
  );
};
