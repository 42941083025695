import { AppName } from "@components/common";
import {
  AuthSwitcher,
  LanguageSwitcher,
  ThemeSwitcher,
} from "@components/layout";
import { Button } from "@components/ui";
import routes from "@constants/routes";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { LandingMobileDrawer } from "./landing-mobile-drawer";

export const LandingHeader = () => {
  const { t } = useTranslation();

  return (
    <header className="w-full bg-background/10">
      <div className="flex lg:hidden">
        <LandingMobileDrawer />
      </div>
      <div className="container hidden lg:flex">
        <nav className="flex flex-col items-center justify-between w-full p-4 lg:flex-row">
          <AppName link={routes.home} isExtended />

          <div className="flex gap-2">
            <Link href={routes.blog}>
              <Button className="px-8 font-bold button-header" variant="ghost">
                {t("menu.blog")}
              </Button>
            </Link>
            <ThemeSwitcher />
            <LanguageSwitcher />
            <AuthSwitcher />
          </div>
        </nav>
      </div>
    </header>
  );
};
