import { SectionButton } from "@components/common";
import apiRoutes from "@constants/api-routes";
import { usePostRequest } from "@hooks";
import { useMeUser } from "@services";
import { useAppStore } from "@store";
import { IconMapPinCheck } from "@tabler/icons-react";
import { ICountry, IUser } from "@types";
import { concat, get, without } from "lodash";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";

export const VisitedButton = ({ data }: { data: ICountry }) => {
  const { t } = useTranslation();
  const { user } = useAppStore();
  const { mutate } = useMeUser();
  const { trigger, isLoading } = usePostRequest<IUser>(apiRoutes.authMe);
  const isVisited = Boolean(
    user?.visitedCountries?.includes(data.code.toLowerCase())
  );

  const onToggleVisited = useCallback(async () => {
    const transKey = isVisited ? "countryNotVisited" : "countryVisited";
    const successMessage = t(`pages.country.notifications.${transKey}`, {
      country: data.name,
    });
    const errorMessage = t("pages.country.notifications.countryNotMarked");
    const currentVisited = get(user, "visitedCountries", []);
    const code = data.code.toLowerCase();
    const modified = {
      visitedCountries: isVisited
        ? without(currentVisited, code)
        : concat(currentVisited, code),
    };

    await trigger(modified, { successMessage, errorMessage }, undefined, {
      onBeforeFinish: async () => await mutate(),
    });
  }, [data, t, mutate, isVisited, trigger, user]);

  return (
    <SectionButton
      onClick={onToggleVisited}
      variant={isVisited ? "primary" : "default"}
      isLoading={isLoading}
      Icon={IconMapPinCheck}
    >
      {isVisited ? t("pages.country.visited") : t("pages.country.markVisited")}
    </SectionButton>
  );
};
