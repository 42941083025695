import { AppName } from "@components/common";
import {
  AuthSwitcher,
  LanguageSwitcher,
  ThemeSwitcher,
} from "@components/layout";
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger,
} from "@components/ui";
import routes from "@constants/routes";
import { IconMenu2 } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// TODO: Combine with mobile-drawer
export const LandingMobileDrawer = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const router = useRouter();

  // Close drawer on navigation change
  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setOpen(false);
    });
  }, [router.events]);

  return (
    <nav className="flex items-center justify-between w-full h-16 gap-2 px-4">
      <AppName link={routes.home} size={28} isExtended />

      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>
          <Button className="button-header" variant="ghost">
            <IconMenu2 />
          </Button>
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <AppName className="flex flex-col gap-1" size={36} isExtended />
          </DrawerHeader>

          <div className="flex flex-col items-center w-full gap-4">
            <AuthSwitcher />
            <Link href={routes.blog}>
              <Button variant="ghost" className="w-full">
                {t("menu.blog")}
              </Button>
            </Link>
          </div>

          <DrawerFooter>
            <LanguageSwitcher isExtended />
            <ThemeSwitcher isExtended />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </nav>
  );
};
