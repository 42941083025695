"use client";

import { GradeTextColored } from "@components/common";
import { Card, CardContent, Progress } from "@components/ui";
import {
  IconBuildingFactory,
  IconCash,
  IconFirstAidKit,
  IconHeart,
  IconShoppingCart,
  type Icon as TablerIcon,
} from "@tabler/icons-react";
import { ICountry } from "@types";
import { useTranslation } from "next-i18next";

interface Props {
  data: ICountry;
}

export const CountryScoresSection = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Card className="w-full h-full">
      <CardContent className="py-6">
        <div className="flex flex-col gap-8 lg:gap-4">
          <ScoreStatProgressBar
            label={t("stats.qualityOfLife")}
            value={data?.qualityOfLife}
            Icon={IconHeart}
          />
          <ScoreStatProgressBar
            label={t("stats.costOfLife")}
            value={data?.costOfLife}
            // tooltip={t("stats.costOfLifeDescription")}
            Icon={IconCash}
            order="desc"
          />
          <ScoreStatProgressBar
            label={t("stats.costOfGroceries")}
            value={data?.costOfGroceries}
            Icon={IconShoppingCart}
            order="desc"
          />
          <ScoreStatProgressBar
            label={t("stats.healthcareRate")}
            value={data?.healthcareRate}
            Icon={IconFirstAidKit}
          />
          <ScoreStatProgressBar
            label={t("stats.pollutionRate")}
            value={data?.pollutionRate}
            Icon={IconBuildingFactory}
            order="desc"
          />
        </div>
      </CardContent>
    </Card>
  );
};

interface ScoreStatProgressBarProps {
  label: string;
  value?: number;
  Icon: TablerIcon;
  order?: "asc" | "desc";
}

const ScoreStatProgressBar = ({
  label,
  value,
  order = "asc",
  Icon,
}: ScoreStatProgressBarProps) => {
  if (!value) return null;

  return (
    <div className="flex flex-col items-center gap-2 lg:gap-8 lg:flex-row">
      <div className="flex items-center gap-2 lg:w-48">
        <Icon strokeWidth={1.5} className="h-6 min-w-6 text-muted-foreground" />
        <div className="flex flex-col items-start">
          <p className="text-sm font-bold leading-tight uppercase truncate max-w-40">
            {label}
          </p>
          <GradeTextColored value={Number(value)} order={order} />
        </div>
      </div>

      <div className="flex items-center w-5/6 gap-4 lg:flex-1">
        <p className="text-xl font-black">{value}</p>
        <Progress value={value * 10} />
      </div>
    </div>
  );
};
