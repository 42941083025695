import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
} from "@components/ui";
import apiRoutes from "@constants/api-routes";
import appConfig from "@constants/app-config";
import { usePostRequest } from "@hooks/use-post-request";
import { useAdvancedStats } from "@services/index";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";
import { IAdvancedStat, IAdvancedStats } from "@types";
import { getApiUrl } from "@utils/api";
import { isEmpty } from "lodash";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useModal } from "./modal-provider";

export const EditAdvancedStatsModal = () => {
  const { t } = useTranslation();
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const cityId = options?.data as string;
  if (!cityId) throw new Error("cityId not indicated");

  const { data, isLoading, error } = useAdvancedStats(cityId);
  const [modified, setModified] = useState<Partial<IAdvancedStats>>({});
  const update = usePostRequest<IAdvancedStats>(
    getApiUrl(apiRoutes.cityIdAdvancedStats, cityId)
  );

  if (!data || error) return null;

  const onUpdate = async () => {
    try {
      if (!modified) return;
      const name = t("entities.city");

      await update.trigger(modified, {
        successMessage: t(`pages.admin.notifications.updated`, { name }),
        errorMessage: t(`pages.admin.notifications.notUpdated`, { name }),
      });

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFieldChange = (
    key: keyof IAdvancedStats,
    value: Partial<IAdvancedStat>
  ) => {
    setModified((prev) => {
      const newValue = { [key]: { ...data[key], ...prev[key], ...value } };
      return { ...prev, ...newValue };
    });
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("pages.admin.editCity")}</DialogTitle>
        </DialogHeader>
        <div className="flex px-1 flex-col gap-2 max-h-[40rem] overflow-y-scroll">
          {appConfig.cities.advancedStatsOrder.map((label) => {
            const key = label as keyof IAdvancedStats;

            return (
              <div className="flex gap-2" key={label}>
                <div className="flex flex-col w-1/5 gap-1">
                  <Label>Score</Label>
                  <Input
                    type="number"
                    value={modified[key]?.score ?? data[key]?.score}
                    onChange={(e) =>
                      handleFieldChange(key, { score: Number(e.target.value) })
                    }
                  />
                </div>

                <div className="flex flex-col w-4/5 gap-1">
                  <Label>{t(`pages.city.stats.${label}`)}</Label>
                  <Input
                    value={modified[key]?.description ?? data[key]?.description}
                    onChange={(e) =>
                      handleFieldChange(key, { description: e.target.value })
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
        <DialogFooter>
          <div className="flex justify-end gap-2">
            <Button onClick={closeModal} size="sm" variant="outline">
              <IconX size={20} />
              {t("actions.cancel")}
            </Button>
            <Button
              onClick={onUpdate}
              isLoading={update.isLoading}
              size="sm"
              disabled={isEmpty(modified)}
            >
              <IconDeviceFloppy size={20} />
              {t("actions.saveChanges")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
