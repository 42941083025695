import { cn } from "@/lib/utils";
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  CardTitle,
} from "@components/ui";
import { type Icon as TablerIcon } from "@tabler/icons-react";

interface Props extends CardProps {
  title: string;
  value?: string;
  Icon?: TablerIcon;
}

export const DataPillWithIcon = ({
  title,
  value,
  Icon,
  className,
  children,
  ...rest
}: Props) => (
  <Card className={cn("relative h-full", className)} {...rest}>
    <CardHeader className="pt-4 pb-2">
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent className="relative z-10 pb-4 overflow-hidden">
      {children || <p className="text-3xl font-bold truncate">{value}</p>}
    </CardContent>
    {Icon && (
      <Icon className="absolute bottom-0 right-2 text-muted" size={52} />
    )}
  </Card>
);
