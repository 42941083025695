import { axiosInstance } from "@/lib/axios";
import { IMapboxPlace } from "@types";
import { useEffect, useState } from "react";

const token = process.env.NEXT_PUBLIC_MAPBOX_API_KEY;

export const useMapboxPlace = (query: string) => {
  const [response, setResponse] = useState<IMapboxPlace>();

  useEffect(() => {
    (async () => {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${token}`;
      const response = await axiosInstance.get(url);
      const features = response.data?.features as IMapboxPlace[];
      if (!features) return;
      const firstPoi = features.find((x) => x.place_type.includes("poi"));

      setResponse(firstPoi);
    })();
  }, [query]);

  return response;
};
