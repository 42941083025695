import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Tooltip,
} from "@components/ui";
import { IconInfoCircle, type Icon as TablerIcon } from "@tabler/icons-react";

interface Props {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  value: string | number;
  description?: string;
  tooltip?: string;
  Icon?: TablerIcon;
}

export const DataTileWithIcon = ({
  title,
  subtitle,
  value,
  description,
  tooltip,
  Icon,
}: Props) => (
  <Card className="relative w-full h-full overflow-hidden">
    <CardHeader className="py-4">
      <div className="flex">
        <div className="flex flex-col w-full truncate">
          <CardTitle className="w-full truncate text-start">{title}</CardTitle>
          <CardDescription className="w-full truncate text-start">
            {subtitle}
          </CardDescription>
        </div>
        {tooltip && (
          <Tooltip asChild tooltip={tooltip}>
            <IconInfoCircle size={16} className="text-muted-foreground" />
          </Tooltip>
        )}
      </div>
    </CardHeader>
    <CardContent className="relative z-10 text-center">
      <p className="text-3xl font-bold sm:text-4xl">{value}</p>
      {description && <p className="text-muted-foreground">{description}</p>}
    </CardContent>
    {Icon && (
      <Icon
        className="absolute inset-x-0 z-0 flex justify-center w-full -bottom-4 text-muted"
        size={52}
      />
    )}
  </Card>
);
