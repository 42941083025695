"use client";

import { cn } from "@/lib/utils";
import { Button } from "@components/ui";
import adminMenu from "@constants/admin-menu";
import { useAuth } from "@hooks";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { memo } from "react";

export const AdminHeader = memo(() => {
  const { t } = useTranslation();
  const { isAdmin } = useAuth();
  const pathname = usePathname();

  if (!isAdmin) return null;

  return (
    <header className="w-full">
      <div className="container overflow-x-scroll ">
        <div className="flex items-center h-16 gap-4 p-4">
          {adminMenu.map((item) => {
            const isActive = item.path === pathname;

            return (
              <Link key={item.name} href={item.path}>
                <Button
                  className={cn("button-header min-w-32", isActive && "active")}
                  variant={isActive ? "link" : "ghost"}
                >
                  <p
                    className={cn(
                      "truncate font-medium tracking-tight leading-none",
                      isActive && "font-semibold"
                    )}
                  >
                    {t(item.name)}
                  </p>
                </Button>
              </Link>
            );
          })}
        </div>
      </div>
    </header>
  );
});

AdminHeader.displayName = "AdminHeader";
