import { CountryIcon } from "@components/country";
import {
  Button,
  Combobox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
} from "@components/ui";
import apiRoutes from "@constants/api-routes";
import { valuesContinentOptions } from "@constants/form-values";
import { usePostRequest } from "@hooks/use-post-request";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";
import { ICountry } from "@types";
import { getApiUrl } from "@utils/api";
import { isEmpty } from "lodash";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useModal } from "./modal-provider";

export const EditCountryModal = () => {
  const { t } = useTranslation();
  const [modified, setModified] = useState<Partial<ICountry>>({});
  const {
    currentModal: { options },
    closeModal,
  } = useModal();
  const data = options?.data as ICountry;
  const update = usePostRequest<ICountry>(
    getApiUrl(apiRoutes.countryId, data?._id)
  );

  const onUpdate = async () => {
    try {
      if (!modified) return;
      const name = modified?.name ?? data?.name;
      await update.trigger(modified, {
        successMessage: t("pages.admin.notifications.updated", { name }),
        errorMessage: t("pages.admin.notifications.notUpdated", { name }),
      });

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const onFieldChange = async (value: Partial<ICountry>) => {
    setModified((prev) => ({ ...prev, ...value }));
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className="max-w-xl max-h-[100rem] py-10 overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t("pages.admin.editCountry")}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 md:flex-row">
            {/* Name field */}
            <div className="flex flex-col w-full gap-1 md:w-2/3">
              <Label>{t("pages.country.name")}</Label>
              <Input
                name="name"
                value={modified?.name ?? data?.name}
                onChange={(e) => onFieldChange({ name: e.target.value })}
              />
            </div>

            {/* Population field */}
            <div className="flex flex-col w-full gap-1 md:w-1/3">
              <Label>{t("stats.population")}</Label>
              <Input
                name="population"
                type="number"
                value={modified?.population ?? data?.population}
                onChange={(e) =>
                  onFieldChange({ population: Number(e.target.value) })
                }
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 md:flex-row">
            {/* Continent field */}
            <div className="flex flex-col w-full gap-1 md:w-2/5">
              <Label>{t("pages.country.continent")}</Label>
              <Combobox
                disableSearch
                disableSorting
                options={valuesContinentOptions}
                value={modified?.continent ?? data?.continent}
                onChange={(continent) => onFieldChange({ continent })}
              />
            </div>

            {/* Code field */}
            <div className="flex flex-col gap-1 relative w-full md:w-[30%]">
              <Label>{t("pages.city.countryCode")}</Label>
              <Input
                name="code"
                maxLength={2}
                value={modified?.code ?? data?.code}
                onChange={(e) => onFieldChange({ code: e.target.value })}
              />
              <CountryIcon
                className="absolute w-4 h-4 right-3 bottom-3"
                code={modified?.code ?? data?.code}
              />
            </div>

            {/* Currency field */}
            <div className="flex flex-col gap-1 w-full md:w-[30%]">
              <Label>{t("pages.country.currency")}</Label>
              <Input
                name="currency"
                value={modified?.currency ?? data?.currency}
                onChange={(e) => onFieldChange({ currency: e.target.value })}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 md:flex-row">
            {/* Capital field */}
            <div className="flex flex-col w-full gap-1 md:w-2/3">
              <Label>{t("pages.country.capital")}</Label>
              <Input
                name="capital"
                value={modified?.capital ?? data?.capital}
                onChange={(e) => onFieldChange({ capital: e.target.value })}
              />
            </div>

            {/* Popularity field */}
            <div className="flex flex-col w-full gap-1 md:w-1/3">
              <Label>{t("pages.country.popularity")}</Label>
              <Input
                name="popularity"
                value={modified?.popularity ?? data?.popularity}
                onChange={(e) =>
                  onFieldChange({ popularity: Number(e.target.value) })
                }
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 md:flex-row">
            {/* Area field */}
            <div className="relative flex flex-col w-full gap-1 md:w-1/2">
              <Label>{t("pages.country.area")}</Label>
              <Input
                name="area"
                value={modified?.area ?? data?.area}
                onChange={(e) =>
                  onFieldChange({ area: Number(e.target.value) })
                }
              />
              <p className="absolute text-sm right-3 bottom-3 text-muted-foreground">
                km²
              </p>
            </div>

            {/* GDP per capita field */}
            <div className="flex flex-col w-full gap-1 md:w-1/2">
              <Label>{t("stats.gdpPerCapita")}</Label>
              <Input
                name="gdpPerCapita"
                value={modified?.gdpPerCapita ?? data?.gdpPerCapita}
                onChange={(e) =>
                  onFieldChange({ gdpPerCapita: Number(e.target.value) })
                }
              />
            </div>
          </div>

          <div className="grid-dynamic-tighter">
            {/* Cost of living field */}
            <div className="flex flex-col w-full gap-1">
              <Label>{t("stats.costOfLife")}</Label>
              <Input
                name="costOfLife"
                type="number"
                value={modified?.costOfLife ?? data?.costOfLife}
                onChange={(e) =>
                  onFieldChange({ costOfLife: Number(e.target.value) })
                }
              />
            </div>

            {/* Quality of life field */}
            <div className="flex flex-col w-full gap-1">
              <Label>{t("stats.qualityOfLife")}</Label>
              <Input
                name="qualityOfLife"
                type="number"
                value={modified?.qualityOfLife ?? data?.qualityOfLife}
                onChange={(e) =>
                  onFieldChange({ qualityOfLife: Number(e.target.value) })
                }
              />
            </div>

            {/* Cost of groceries field */}
            <div className="flex flex-col w-full gap-1">
              <Label>{t("stats.costOfGroceries")}</Label>
              <Input
                name="costOfGroceries"
                type="number"
                value={modified?.costOfGroceries ?? data?.costOfGroceries}
                onChange={(e) =>
                  onFieldChange({ costOfGroceries: Number(e.target.value) })
                }
              />
            </div>

            {/* Unemployment field */}
            <div className="flex flex-col w-full gap-1">
              <Label>{t("stats.unemployment")}</Label>
              <Input
                name="unemployment"
                type="number"
                value={modified?.unemployment ?? data?.unemployment}
                onChange={(e) =>
                  onFieldChange({ unemployment: Number(e.target.value) })
                }
              />
            </div>

            {/* Avg salary field */}
            <div className="flex flex-col w-full gap-1">
              <Label>{t("stats.avgSalary")}</Label>
              <Input
                name="avgSalary"
                type="number"
                value={modified?.avgSalary ?? data?.avgSalary}
                onChange={(e) =>
                  onFieldChange({ avgSalary: Number(e.target.value) })
                }
              />
            </div>

            {/* Life expectancy field */}
            <div className="flex flex-col w-full gap-1">
              <Label>{t("stats.lifeExpectancy")}</Label>
              <Input
                name="lifeExpectancy"
                type="number"
                value={modified?.lifeExpectancy ?? data?.lifeExpectancy}
                onChange={(e) =>
                  onFieldChange({ lifeExpectancy: Number(e.target.value) })
                }
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex justify-end gap-2">
            <Button onClick={closeModal} size="sm" variant="outline">
              <IconX size={20} />
              {t("actions.cancel")}
            </Button>
            <Button
              onClick={onUpdate}
              isLoading={update.isLoading}
              size="sm"
              disabled={isEmpty(modified)}
            >
              <IconDeviceFloppy size={20} />
              {t("actions.saveChanges")}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
