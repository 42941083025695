import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, IWorkFriendlyPlace } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";
import { Key } from "swr";

export const useWfps = (
  cityId?: string,
  config?: ApiSwrConfig<IWorkFriendlyPlace[]>,
  overrideRoute?: Key
) => {
  const route = overrideRoute ?? getApiUrl(apiRoutes.wfp, null, { cityId });
  return useApiSWR<IWorkFriendlyPlace[]>(route, config);
};
