import routes from "./routes";

const adminMenu: { name: string; path: string }[] = [
  {
    name: "pages.admin.title",
    path: routes.admin,
  },
  {
    name: "entities.suggestions",
    path: routes.adminSuggestion,
  },
  {
    name: "entities.costsFeedback",
    path: routes.adminCostsFeedback,
  },
  {
    name: "entities.wfps",
    path: routes.adminWfp,
  },
  {
    name: "pages.admin.scripts",
    path: routes.adminScripts,
  },
];

export default adminMenu;
