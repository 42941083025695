import { AlertModal } from "./alert-modal";
import { AuthModal } from "./auth-modal";
import { CurrentCityModal } from "./current-city-modal";
import { DisplayInfoModal } from "./display-info-modal";
import { EditAdvancedStatsModal } from "./edit-advanced-stats-modal";
import { EditCityModal } from "./edit-city-modal";
import { EditCountryModal } from "./edit-country-modal";
import { EditSuggestionModal } from "./edit-suggestion-modal";
import { EditUserModal } from "./edit-user-modal";
import { FeedbackModal } from "./feedback-modal";
import { FtuModal } from "./ftu-modal";
import { ImageModal } from "./image-modal";
import { ModalId, useModal } from "./modal-provider";
import { PromptModal } from "./prompt-modal";
import { ScriptsModal } from "./scripts-modal";

export const ModalManager = () => {
  const { currentModal } = useModal();

  const renderModal = (modalId: ModalId) => {
    switch (modalId) {
      case "alert":
        return <AlertModal />;
      case "auth":
        return <AuthModal />;
      case "currentCity":
        return <CurrentCityModal />;
      case "displayInfo":
        return <DisplayInfoModal />;
      case "editAdvancedStats":
        return <EditAdvancedStatsModal />;
      case "editCity":
        return <EditCityModal />;
      case "editCountry":
        return <EditCountryModal />;
      case "editSuggestion":
        return <EditSuggestionModal />;
      case "editUser":
        return <EditUserModal />;
      case "ftu":
        return <FtuModal />;
      case "feedback":
        return <FeedbackModal />;
      case "image":
        return <ImageModal />;
      case "prompt":
        return <PromptModal />;
      case "scripts":
        return <ScriptsModal />;
      default:
        return null;
    }
  };

  return renderModal(currentModal.id);
};
