import { cn } from "@/lib/utils";
import { Card } from "@components/ui";
import { type Icon as TablerIcon } from "@tabler/icons-react";
import { parseGradeToClass } from "@utils/parse";

interface Props {
  title: React.ReactNode;
  description?: React.ReactNode;
  grade: number;
  Icon?: TablerIcon;
  className?: string;
}

export const StatPillWithIcon = ({
  title,
  description,
  grade,
  Icon,
  className,
}: Props) => (
  <Card
    className={cn(
      "flex relative items-center justify-between w-full overflow-hidden gap-2 px-4 py-1.5",
      className
    )}
  >
    <div className="z-10 flex flex-col w-3/4">
      <div className="flex pt-1 truncate">{title}</div>
      {description}
    </div>
    <div className="z-10 flex items-baseline justify-end w-1/4">
      <span className={cn(parseGradeToClass(grade), "font-bold text-2xl")}>
        {grade}
      </span>
      <span className="text-xs text-muted-foreground">/10</span>
    </div>
    {Icon && (
      <Icon className="absolute -bottom-4 right-2 text-muted" size={52} />
    )}
  </Card>
);
