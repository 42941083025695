import { cn } from "@/lib/utils";
import { IconTrendingDown, IconTrendingUp } from "@tabler/icons-react";
import { parsePercentNumber } from "@utils/parse";

interface Props {
  value: number;
  className?: string;
  hideIcon?: boolean;
}

export const PercentTextColored = ({
  value,
  className,
  hideIcon = false,
}: Props) => {
  const isNegative = value < 0;
  return (
    <p
      className={cn(
        "flex items-center gap-1",
        isNegative ? "text-red-500" : "text-green-500",
        className
      )}
    >
      {!isNegative && "+"}
      {parsePercentNumber(value, 2)}
      {!hideIcon && isNegative ? (
        <IconTrendingDown size={18} />
      ) : (
        <IconTrendingUp size={18} />
      )}
    </p>
  );
};
