"use client";

import { Dialog, DialogContent } from "@components/ui";
import Image from "next/image";
import { useModal } from "./modal-provider";

export const ImageModal = () => {
  const {
    currentModal: { options },
    closeModal,
  } = useModal();

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent hideCloseButton>
        <Image
          src={options?.data as string}
          alt="image"
          height={1024}
          width={1024}
        />
      </DialogContent>
    </Dialog>
  );
};
