import apiRoutes from "@constants/api-routes";
import { ApiSwrConfig, IWorkFriendlyPlace } from "@types";
import { getApiUrl, useApiSWR } from "@utils/api";

export const useWfp = (
  wfpId: string,
  config?: ApiSwrConfig<IWorkFriendlyPlace>,
  overrideRoute?: string
) => {
  const route = overrideRoute ?? getApiUrl(apiRoutes.wfpId, wfpId);
  return useApiSWR<IWorkFriendlyPlace>(route, config);
};
