"use client";

import { useModal } from "@components/modals";
import { Button, Tooltip } from "@components/ui";
import { useAppStore } from "@store/index";
import { IconForms } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";

export const FtuFlow = () => {
  const { t } = useTranslation();
  const { user } = useAppStore();
  const { openModal, currentModal } = useModal();

  if (!user || user.afterFtu) return null;

  return (
    <div className="fixed z-50 bottom-10 right-10">
      {!currentModal.id && (
        <Tooltip tooltip={t("ftu.tooltip")} asChild>
          <Button
            className="transition-all rounded-full w-14 h-14 hover:-translate-y-1"
            onClick={() => openModal("ftu")}
          >
            <IconForms size={24} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
