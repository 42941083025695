"use client";

import { CountryIcon } from "@components/country";
import {
  AsyncCombobox,
  Badge,
  Button,
  ComboboxMulti,
  DatePicker,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Step,
  Stepper,
  Textarea,
  useSteps,
} from "@components/ui";
import apiRoutes from "@constants/api-routes";
import appConfig from "@constants/app-config";
import { valuesLanguageOptions } from "@constants/form-values";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth, usePostRequest } from "@hooks";
import { useMeUser } from "@services";
import { useAppStore } from "@store";
import {
  IconArrowLeft,
  IconArrowRight,
  IconConfetti,
} from "@tabler/icons-react";
import { ISelectOption, IUser } from "@types";
import { getApiUrl } from "@utils/api";
import {
  parseAgeFromBirthday,
  parseCityOptionAsync,
  parseCityOptionsAsync,
  parseCountryName,
  parseCountryOptionsAsync,
  parseCountrySelectedOption,
  parseLanguageName,
} from "@utils/parse";
import { format } from "date-fns";
import { omit } from "lodash";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useModal } from "./modal-provider";

const steps = [{ title: "1" }, { title: "2" }, { title: "3" }, { title: "4" }];

const formSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string().optional(),
  birthday: z.date(),
  about: z.string().optional(),
  nationality: z.string(),
  livingInId: z.string().optional(),
  languages: z.string().array().optional(),
  image: z.string().optional(),
});

export const FtuModal = () => {
  const { t } = useTranslation();
  const { user, editUser } = useAppStore();
  const { closeModal } = useModal();
  const { userId, update: updateSession } = useAuth();
  const [currentCity, setCurrentCity] = useState<ISelectOption>();
  const meReq = useMeUser({
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const update = usePostRequest<IUser>(getApiUrl(apiRoutes.userId, userId));
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });
  const form = useForm<z.infer<typeof formSchema>>({
    mode: "all",
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      birthday: user?.birthday ? new Date(user.birthday) : undefined,
      about: user?.about,
      nationality: user?.nationality,
      livingInId: user?.livingInId,
      languages: user?.languages,
      image: user?.image,
    },
  });

  useEffect(() => {
    const getOption = async () => {
      const cityId = form.getValues("livingInId");
      if (!cityId) return;
      const city = await parseCityOptionAsync(cityId);
      setCurrentCity(city);
    };

    getOption();
  }, [form.getValues("livingInId")]);

  const ageLimits = useMemo(() => {
    const currentDate = new Date();
    const formatDate = (date: Date) => date.toISOString().split("T")[0];
    const maxDate = new Date(currentDate);
    const minDate = new Date(currentDate);
    maxDate.setFullYear(maxDate.getFullYear() - 16);
    minDate.setFullYear(minDate.getFullYear() - 100);

    return { max: formatDate(maxDate), min: formatDate(minDate) };
  }, []);

  const isNextBtnDisabled = () => {
    switch (activeStep) {
      case 1:
        if (!form.getValues("birthday")) return true;
        const birthday = new Date(form.getValues("birthday"));
        const isAgeInvalid =
          birthday > new Date(ageLimits.max) ||
          birthday < new Date(ageLimits.min);

        return !form.getValues("firstName") || isAgeInvalid;
      case 2:
        return !form.getValues("nationality");
      default:
        return false;
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const body = {
        ...omit(values, "email"),
        birthday: format(values.birthday, "yyyy-MM-dd"),
        balance: appConfig.pro.freeTokens,
        afterFtu: true,
      } as Partial<IUser>;
      const successMessage = t("pages.profile.notifications.profileUpdated");
      const errorMessage = t("pages.profile.notifications.profileNotUpdated");

      await update.trigger(body, { successMessage, errorMessage });
      await meReq.mutate();
      closeModal();
      editUser({ ...body });
      updateSession({ ...body });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open defaultOpen onOpenChange={closeModal}>
      <DialogContent className="h-full max-h-[40rem]">
        <Form {...form}>
          <form className="grid" onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader className="mt-2">
              <Stepper index={activeStep}>
                {steps.map((item, index) => (
                  <Step key={index} index={index}>
                    {item.title}
                  </Step>
                ))}
              </Stepper>
            </DialogHeader>
            <div className="flex flex-col flex-1 my-4">
              {activeStep === 0 && (
                <div className="flex flex-col items-center gap-2">
                  <p className="text-3xl font-bold">{t("ftu.welcomeTitle")}</p>
                  <p className="px-10 text-center">{t("ftu.subtitle")}</p>
                  <Image
                    src="/illustrations/start-flag.svg"
                    alt="start"
                    height={420}
                    width={420}
                  />

                  <p>{t("ftu.getStarted")}</p>
                  <div className="flex items-center">
                    <p
                      className="text-primary"
                      dangerouslySetInnerHTML={{
                        __html: t("ftu.freeTokens", {
                          count: appConfig.pro.freeTokens,
                        }),
                      }}
                    />
                  </div>
                </div>
              )}

              {activeStep === 1 && (
                <div className="flex flex-col items-start gap-4">
                  {/* First name field */}
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel required>
                          {t("pages.profile.firstName")}
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Last name field */}
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{t("pages.profile.lastName")}</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Birthday field */}
                  <FormField
                    control={form.control}
                    name="birthday"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel required>
                          {t("pages.profile.birthday")}
                        </FormLabel>
                        <FormControl>
                          <DatePicker
                            className="bg-secondary"
                            calendar={{
                              captionLayout: "dropdown",
                              fromDate: new Date(ageLimits.min),
                              toDate: new Date(ageLimits.max),
                            }}
                            {...field}
                          />
                        </FormControl>
                        <FormDescription>
                          {t("pages.profile.birthdayHelp")}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* About me field */}
                  <FormField
                    control={form.control}
                    name="about"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{t("pages.profile.about")}</FormLabel>
                        <FormControl>
                          <Textarea className="resize-none" {...field} />
                        </FormControl>
                        <FormDescription>
                          {t("pages.profile.aboutMeHelp")}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {activeStep === 2 && (
                <div className="flex flex-col items-start gap-4">
                  {/* Nationality field */}
                  <FormField
                    control={form.control}
                    name="nationality"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel required>
                          {t("pages.profile.nationality")}
                        </FormLabel>
                        <FormControl>
                          <AsyncCombobox
                            placeholder={t("pages.country.typeToSearch")}
                            loadOptions={parseCountryOptionsAsync}
                            selectedItem={parseCountrySelectedOption(
                              field.value
                            )}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* Living in field */}
                  <FormField
                    control={form.control}
                    name="livingInId"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{t("pages.profile.livingIn")}</FormLabel>
                        <FormControl>
                          <AsyncCombobox
                            placeholder={t("common.typeToSearch")}
                            loadOptions={parseCityOptionsAsync}
                            selectedItemAsync={parseCityOptionAsync}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                        <FormDescription>
                          {t("pages.profile.livingInHelp")}
                        </FormDescription>
                      </FormItem>
                    )}
                  />

                  {/* Languages field */}
                  <FormField
                    control={form.control}
                    name="languages"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>{t("pages.profile.languages")}</FormLabel>
                        <ComboboxMulti
                          options={valuesLanguageOptions}
                          {...field}
                        />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {activeStep === 3 && (
                <div className="flex flex-col items-center gap-4 mx-6">
                  <div className="flex flex-col items-center">
                    <Image
                      alt="profile picture"
                      src={
                        form.getValues("image") ||
                        "/images/image-placeholder.png"
                      }
                      className="w-20 h-20 mb-4 border-2 rounded-full shadow border-border"
                      height={80}
                      width={80}
                    />

                    <p className="text-2xl font-bold">
                      {form.getValues("firstName")} {form.getValues("lastName")}
                    </p>
                    <p className="text-muted-foreground">
                      {form.getValues("email")}
                    </p>
                  </div>

                  <SummaryItem
                    label={t("pages.profile.age")}
                    value={parseAgeFromBirthday(
                      String(form.getValues("birthday"))
                    )}
                  />
                  <SummaryItem
                    label={t("pages.profile.nationality")}
                    countryCode={form.getValues("nationality")}
                    value={parseCountryName(form.getValues("nationality"))}
                  />

                  {form.getValues("languages")?.length && (
                    <div className="flex flex-col items-center gap-1">
                      <p className="text-sm font-bold uppercase text-muted-foreground">
                        {t("pages.profile.languages")}
                      </p>
                      <div className="flex items-center gap-2">
                        {form.getValues("languages")!.map((item, index) => (
                          <Badge
                            key={index}
                            className="gap-2"
                            variant="outline"
                          >
                            <p>{parseLanguageName(item)}</p>
                          </Badge>
                        ))}
                      </div>
                    </div>
                  )}

                  {form.getValues("livingInId") && (
                    <div className="flex flex-col items-center gap-1">
                      <p className="text-sm font-bold uppercase text-muted-foreground">
                        {t("pages.profile.livingIn")}
                      </p>
                      <div className="flex items-center gap-2">
                        {currentCity?.icon}
                        {currentCity?.label}
                      </div>
                    </div>
                  )}
                  <SummaryItem
                    label={t("pages.profile.about")}
                    value={form.getValues("about")}
                  />
                </div>
              )}
            </div>
            <DialogFooter>
              <div className="flex justify-between w-full gap-2">
                <div className="flex gap-2">
                  {activeStep > 0 && (
                    <Button variant="ghost" size="sm" onClick={goToPrevious}>
                      <IconArrowLeft />
                      {t("actions.back")}
                    </Button>
                  )}
                </div>
                {activeStep === steps.length - 1 ? (
                  <Button size="sm" type="submit" isLoading={update.isLoading}>
                    <IconConfetti />
                    {t("ftu.submit")}
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    onClick={goToNext}
                    type="button"
                    disabled={isNextBtnDisabled()}
                  >
                    {t("actions.next")}
                    <IconArrowRight />
                  </Button>
                )}
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

interface SummaryItemProps {
  countryCode?: string;
  label: string;
  value?: string;
}

const SummaryItem = ({ countryCode, label, value }: SummaryItemProps) => {
  if (!value) return null;

  return (
    <div className="flex flex-col items-center gap-1">
      <p className="text-sm font-bold uppercase text-muted-foreground">
        {label}
      </p>
      <div className="flex items-center gap-2">
        {countryCode && <CountryIcon code={countryCode} />}
        <p>{value}</p>
      </div>
    </div>
  );
};
