"use client";

import { cn } from "@/lib/utils";
import { ISelectOption } from "@/types";
import { useDebounce } from "@hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import * as React from "react";
import { Button } from "./button";
import { ComboboxItem, ComboboxSelectedItem } from "./combobox";
import { Command, CommandEmpty, CommandGroup, CommandInput } from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

interface Props {
  className?: string;
  loadOptions: (search: string, params?: any) => Promise<ISelectOption[]>;
  placeholder?: string;
  selectedItem?: ISelectOption;
  selectedItemAsync?: (id: string) => Promise<ISelectOption>;
  value?: string;
  onChange: (value: string) => void;
  noResultMessage?: string;
  disableSearch?: boolean;
  disableSorting?: boolean;
}

const AsyncCombobox = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      selectedItem,
      selectedItemAsync,
      loadOptions,
      placeholder,
      value,
      ...props
    }: Props,
    ref
  ) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [valueLoading, setValueLoading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");
    const [currentValue, setCurrentValue] = React.useState<ISelectOption>();
    const [options, setOptions] = React.useState<ISelectOption[]>([]);
    const debouncedSearch = useDebounce(searchValue, 500);

    // Fetch the options after typing
    React.useEffect(() => {
      if (!debouncedSearch) return;
      (async () => {
        try {
          setLoading(true);
          const options = await loadOptions(debouncedSearch);
          setOptions(options);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      })();
    }, [debouncedSearch]);

    // Fetch the selected item data
    React.useEffect(() => {
      if (!selectedItemAsync || !value) return;
      (async () => {
        try {
          setValueLoading(true);
          const currentVal = await selectedItemAsync(value);
          setCurrentValue(currentVal);
        } catch (error) {
        } finally {
          setValueLoading(false);
        }
      })();
    }, [selectedItemAsync, value]);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="secondary"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "justify-between border border-border w-full",
              className
            )}
            isLoading={valueLoading}
          >
            <ComboboxSelectedItem
              value={value}
              selectedItem={currentValue || selectedItem}
              placeholder={placeholder ?? t("actions.selectOrSearch")}
            />
            <IconChevronDown size={18} className="ml-2 opacity-50 shrink-0" />
          </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="p-0 min-w-40">
          <Command className="max-h-[13.5rem]">
            <CommandInput
              placeholder={t("actions.searchPlaceholder")}
              onValueChange={setSearchValue}
              loading={loading}
            />
            <CommandEmpty>
              {props.noResultMessage || t("common.noOptionsFound")}
            </CommandEmpty>
            {options.length ? (
              <CommandGroup className="overflow-y-scroll">
                {options.map((item) => (
                  <ComboboxItem
                    key={item.value}
                    value={item.label}
                    item={item}
                    checked={value === item.value}
                    onSelect={(currentValue) => {
                      props.onChange(currentValue === value ? "" : item.value);
                      setOpen(false);
                    }}
                  >
                    {item.icon}
                    {item.label}
                  </ComboboxItem>
                ))}
              </CommandGroup>
            ) : null}
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
AsyncCombobox.displayName = "AsyncCombobox";

export { AsyncCombobox };
