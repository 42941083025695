import { cn } from "@/lib/utils";
import { Card } from "@components/ui";
import { IconPlus, type Icon as TablerIcon } from "@tabler/icons-react";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  Icon?: TablerIcon;
  title?: string;
};

export const EmptyPlacard = ({
  Icon = IconPlus,
  title,
  className,
  ...rest
}: Props) => (
  <Card
    className={cn(
      "flex duration-200 hover:scale-95 flex-col items-center justify-center h-full gap-4 border-4 border-dashed cursor-pointer text-muted-foreground",
      className
    )}
    {...rest}
  >
    <Icon size={44} />
    <p className="text-lg font-bold tracking-tight text-center uppercase ">
      {title}
    </p>
  </Card>
);
