const appConfig = {
  domain: "https://findcity.io",
  admin: {
    tableItemsToShow: 10,
  },
  ui: {
    headerHeight: "72px",
  },
  pro: {
    freeTokens: 8, // Free tokens for the user after signup
  },
  cities: {
    itemsToShow: 24,
    imageWidth: 640,
    advancedStatsExpanded: 2,
    advancedStatsOrder: [
      "foodPrices",
      "weather",
      "nature",
      "language",
      "openness",
      "safety",
      "accessibility",
      "housingAccess",
      "sports",
      "nightlife",
      "housingCost",
      "investmentEase",
      "bureaucracy",
      "business",
      "salaries",
      "friendliness",
      "feedback",
      "foodQuality",
      "ecoFriendliness",
      "veganFriendliness",
      "transportAccess",
      "transportCost",
      "opportunities",
      "touristFriendliness",
      "travelAccessibility",
    ],
  },
  routesWithoutLayout: ["/"],
  routesWithoutAuth: [
    "/",
    "/blog",
    "/blog/[slug]",
    "/explore",
    "/city/[id]",
    "/city/[id]/tours",
    "/country/[code]",
    "/trip-planner/[[...slug]]",
  ],
};

export default appConfig;
